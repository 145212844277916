/* eslint-disable no-unused-vars */
import * as yup from 'yup'

// Enum for geofence types
const geofenceTypeEnum = ['POLYGON', 'LINE', 'CIRCLE']

// Define the coordinates schema based on the model
const coordinatesSchema = yup.object().shape({
  lat: yup
    .number()
    .required('Latitude is required.')
    .min(-90, 'Latitude must be between -90 and 90.')
    .max(90, 'Latitude must be between -90 and 90.'),
  lng: yup
    .number()
    .required('Longitude is required.')
    .min(-180, 'Longitude must be between -180 and 180.')
    .max(180, 'Longitude must be between -180 and 180.')
})

// Center schema for CIRCLE type
const centerSchema = yup.object().shape({
  lat: yup
    .number()
    .required('Latitude is required for center.')
    .min(-90, 'Latitude for center must be between -90 and 90.')
    .max(90, 'Latitude for center must be between -90 and 90.'),
  lng: yup
    .number()
    .required('Longitude is required for center.')
    .min(-180, 'Longitude for center must be between -180 and 180.')
    .max(180, 'Longitude for center must be between -180 and 180.')
})

// Main Geofence schema
export const geofenceSchema = yup.object().shape({
  accountId: yup.string().required('Account ID is required.'),
  name: yup
    .string()
    .trim()
    .min(3, 'Name should be at least 3 characters.')
    .max(50, 'Name cannot exceed 50 characters.')
    .required('Name is required.'),
  description: yup
    .string()
    .trim()
    .max(200, 'Description cannot exceed 200 characters.')
    .required('Description is required.'),
  type: yup
    .string()
    .oneOf(geofenceTypeEnum, 'Invalid geofence type.')
    .default('POLYGON')
    .required('Geofence type is required.'),
  coordinates: yup
    .array()
    .test(
      'check-type',
      'At least two coordinates are required for POLYGON or LINE type.',
      function (value) {
        const { type } = this.parent // Access the parent object to get the type
        if (type !== 'CIRCLE') {
          // Perform validation only if type is not 'CIRCLE'
          return Array.isArray(value) && value.length >= 2 // Check if value is an array with at least 2 elements
        }
        return true // If type is 'CIRCLE', skip the validation
      }
    )
    .of(coordinatesSchema)
    .required('Coordinates are required.'),
  radius: yup
    .number()
    .nullable() // Allow null or undefined by default
    .test(
      'check-type',
      'Radius is required for CIRCLE type.',
      function (value) {
        const { type } = this.parent // Access the parent object to get type

        if (type === 'CIRCLE') {
          // Perform validation only if type is 'CIRCLE'
          return value !== null && value !== undefined // Check if the value is provided
        }
        return true // If type is not 'CIRCLE', skip validation
      }
    )
    .test(
      'check-positive',
      'Radius must be a positive number.',
      function (value) {
        const { type } = this.parent

        if (type === 'CIRCLE') {
          return value > 0 // Check if the radius is a positive number
        }
        return true
      }
    ),
  center: yup
    .object()
    .nullable()
    .test(
      'check-type',
      'Center is required for CIRCLE type.',
      function (value) {
        const { type } = this.parent // Access the parent object to get type

        if (type === 'CIRCLE') {
          return value !== null && typeof value === 'object' // Ensure the center is an object and not null
        }
        return true // If type is not 'CIRCLE', skip validation
      }
    )
    .test(
      'check-center-schema',
      'Center must have valid latitude and longitude for CIRCLE type.',
      function (value) {
        const { type } = this.parent

        if (type === 'CIRCLE') {
          return centerSchema.isValidSync(value) // Validate the center against the centerSchema
        }
        return true
      }
    ),
  area: yup.number().nullable(),
  perimeter: yup.number().nullable(),
  color: yup
    .string()
    .default('#000000')
    .matches(/^#([0-9A-F]{3}){1,2}$/i, 'Invalid hex color!'),
  createdBy: yup.string().nullable(),
  updatedBy: yup.string().nullable()
})
