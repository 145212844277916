import React from "react";
import AccountsIcon from "../assets/icons/accountIcon.svg";
import AccountsIconBlue from "../assets/icons/accountIconBlue.svg";
import BillingPlanIcon from "../assets/icons/billingPlanIcon.svg";
import BillingPlanIconBlue from "../assets/icons/billingPlanIconBlue.svg";
import UsersIcon from "../assets/icons/usersIcon.svg";
import UsersIconBlue from "../assets/icons/userIconBlue.svg";
import UnitsIcon from "../assets/icons/unitsIcon.svg";
import UnitsIconBlue from "../assets/icons/unitIconBlue.svg";
import UnitGroupsIcon from "../assets/icons/unitGroupsIcon.svg";
import UnitGroupsIconBlue from "../assets/icons/unitGroupsIconBlue.svg";
import ConsignmentIconBlue from "../assets/icons/consignmentIconBlue.svg";
import DriversIcon from "../assets/icons/driversIcon.svg";
import GeofencesIcon from "../assets/icons/geofencesIcon.svg";
import TrailersIcon from "../assets/icons/trailersIcon.svg";
import JobsIcon from "../assets/icons/jobsIcon.svg";
import NotificationsIcon from "../assets/icons/notificationsIcon.svg";
import MobileAppIcon from "../assets/icons/mobileAppIcon.svg";
import WebAppIcon from "../assets/icons/webAppIcon.svg";
import DashboardIcon from "../assets/icons/dashboardIcon.svg";
import DashboardIconBlue from "../assets/icons/dashboardIconBlue.svg";
import ReportsIcon from "../assets/icons/reportsIcon.svg";
import EmailIcon from "../assets/icons/emailIcon.svg";
import SMSIcon from "../assets/icons/smsIcon.svg";
import TagIcon from "../assets/icons/tagIcon.svg";
import ArrowIcon from "../assets/icons/arrowIcon.svg";
import RemoveIcon from "../assets/icons/removeIcon.svg";
import DevicesIcon from "../assets/icons/deviceIcon.svg";
import DevicesIconBlue from "../assets/icons/deviceIconBlue.svg";
import RolesIcon from "../assets/icons/roleIcon.svg";
import RolesIconBlue from "../assets/icons/roleIconBlue.svg";
import ServicesIcon from "../assets/icons/serviceIcon.svg";
import ServicesIconBlue from "../assets/icons/serviceIconBlue.svg";
import SimCardsIcon from "../assets/icons/simCardIcon.svg";
import SimCardsIconBlue from "../assets/icons/simCardIconBlue.svg";
//Error Icon
import AlertIcon from "../assets/icons/alertsIcon.svg";
//Success Icon
import SuccessIcon from "../assets/icons/successIcon.svg";
//Filter Icon
import FilterIcon from "../assets/icons/filterIcon.svg";
//More Icon
import MoreIcon from "../assets/icons/moreIcon.svg";
//Lock Icon
import LockIcon from "../assets/icons/lockIcon.svg";
import LockOpenIcon from "../assets/icons/lockOpenIcon.svg";
//Tanzania flag Icon
import TanzaniaFlagIcon from "../assets/icons/tanzaniaFlagIcon.svg";
//Profile Icon
import ProfileIcon from "../assets/icons/defaultProfileIcon.svg";
//Uplode Icon
import UplodeIcon from "../assets/icons/upload.svg";

//Expand Icons
import ExpandMoreIcon from "../assets/icons/expandMoreIcon.svg";
import ExpandLessIcon from "../assets/icons/expandLessIcon.svg";

//Card View Icons
import ActiveCardViewIcon from "../assets/icons/activeGridIcon.svg";
import InactiveCardViewIcon from "../assets/icons/inActiveGridIcon.svg";

//List View Icons
import ActiveListViewIcon from "../assets/icons/activeListIcon.svg";
import InactiveListViewIcon from "../assets/icons/inActiveListIcon.svg";

import UnitOnlineIcon from "../assets/icons/unitOnlineIcon.svg";
import UnitOfflineIcon from "../assets/icons/unitOfflineIcon.svg";

//Motion status Icons
import StationeryIcon from "../assets/icons/StationeryIcon.svg";
import MovingIcon from "../assets/icons/motionStatusMovingIcon.svg";

//Empty Screen Icon
import DeviceEmptyScreenIcon from "../assets/icons/deviceEmptyscreen.svg";
import ServiceEmptyScreenIcon from "../assets/icons/servicesEpmtyScreen.svg";
import UnitGroupsEmptyScreenIcon from "../assets/icons/unitGroupsEmptyScreen.svg";
import UnitEmptyScreenIcon from "../assets/icons/unitEmptyScreen.svg";
import UserEmptyScreenIcon from "../assets/icons/userEmptyScreen.svg";
import AccountEmptyScreenIcon from "../assets/icons/accountEmptyScreen.svg";
import BillingPlanEmptyScreenIcon from "../assets/icons/billingPlanEmptyScreen.svg";
import RoleEmptyScreenIcon from "../assets/icons/roleEmptyScreen.svg";
import GeofenceEmptyScreenIcon from "../assets/icons/geofenceEmptyScreen.svg";
import SimCardEmptyScreenIcon from "../assets/icons/simCardEmptyScreen.svg";
import ConsignmentEmptyScreenIcon from "../assets/icons/consignmentEmptyScreen.svg";
import DriverEmptyScreenIcon from "../assets/icons/driverEmptyScreen.svg";

import PositionMarkIcon from "../assets/icons/positionMarkIcon.svg";
import checkMarkIcon from "../assets/icons/check.svg";
import settingsIcon from "../assets/icons/settings.svg";
import addIcon from "../assets/icons/add.svg";
import logoutIcon from "../assets/icons/logout .svg";

//Subscription Status Icons
import ActiveIcon from "../assets/icons/activeIcon.svg";
import PendingIcon from "../assets/icons/pendingIcon.svg";
import CancelledIcon from "../assets/icons/canselIcon.svg";
import ExpiredIcon from "../assets/icons/expiredIcon.svg";
// import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from "prop-types";

import deliveryTruckIcon1 from "../assets/icons/delivery-truck1.svg";
import deliveryTruckIcon2 from "../assets/icons/delivery-truck2.svg";
import SearchIcon from "../assets/icons/SearchIcon.svg";
import deleteIcon from "../assets/icons/deleteIcon.svg";
import docUploadIcon from "../assets/icons/docUploadIcon.svg";

//userFlow Icons management
import PlusIcon from "../assets/icons/userFlowPlusIcon.svg";
import EditIcon from "../assets/icons/UserFlowEditIcon.svg";
import EyeIcon from "../assets/icons/userFlowEyeIcon.svg";
import UserflowDeleteIcon from "../assets/icons/UserflowDeleteIcon.svg";

import DownloadArrow from "../assets/icons/download-arrow1.svg";
import CaretDown from "../assets/icons/caret-down.svg";
import MenuIcon from "../assets/icons/menuIcon.svg";
import LeftArrow from "../assets/icons/left-arrow.svg";

import AddMorePlusIcon from "../assets/icons/plusIcon.svg";

import fastforward1 from "../assets/icons/fastforward1.svg";
import fastforward2 from "../assets/icons/fastforward2.svg";

import CheckBox from "../assets/icons/CheckBox.svg";
import CheckBoxChecked from "../assets/icons/CheckBoxChecked.svg";

//google map marker icons;
import UnlockedTruckMarker from "../assets/icons/unLockedTruckMarker.svg";
import CustomMapMarker from "../assets/icons/customMapMarker.svg";

export const iconMap = {
  CheckBoxChecked: CheckBoxChecked,
  CheckBox: CheckBox,

  fastforward1: fastforward1,
  fastforward2: fastforward2,

  AddMorePlusIcon: AddMorePlusIcon,

  MenuIcon: MenuIcon,
  CaretDown: CaretDown,
  DownloadArrow: DownloadArrow,
  LeftArrow: LeftArrow,

  PlusIcon: PlusIcon,
  EditIcon: EditIcon,
  EyeIcon: EyeIcon,
  UserflowDeleteIcon: UserflowDeleteIcon,

  UnlockedTruckMarker: UnlockedTruckMarker,
  CustomMapMarker: CustomMapMarker,

  DeleteIcon: deleteIcon,
  DocUploadIcon: docUploadIcon,
  DeliveryTruck1: deliveryTruckIcon1,
  DeliveryTruck2: deliveryTruckIcon2,
  SearchIcon: SearchIcon,
  "Consignees Blue": UsersIconBlue,
  "Consignments Blue": ConsignmentIconBlue,
  "Reports Blue": ReportsIcon,
  Devices: DevicesIcon,
  "Devices Blue": DevicesIconBlue,
  "Sim Cards": SimCardsIcon,
  "Sim Cards Blue": SimCardsIconBlue,
  Roles: RolesIcon,
  "Roles Blue": RolesIconBlue,
  Services: ServicesIcon,
  "Services Blue": ServicesIconBlue,
  Accounts: AccountsIcon,
  "Accounts Blue": AccountsIconBlue,
  Users: UsersIcon,
  "Users Blue": UsersIconBlue,
  Units: UnitsIcon,
  "Units Blue": UnitsIconBlue,
  "Unit Groups": UnitGroupsIcon,
  "Unit Groups Blue": UnitGroupsIconBlue,
  Drivers: DriversIcon,
  "Drivers Blue": DriversIcon,
  "Route Optimizations Blue": GeofencesIcon,
  "Geofences Blue": GeofencesIcon,
  Trailers: TrailersIcon,
  Jobs: JobsIcon,
  Notifications: NotificationsIcon,
  "Mobile App": MobileAppIcon,
  "Web Portal": WebAppIcon,
  Dashboard: DashboardIcon,
  DashboardBlue: DashboardIconBlue,
  Reports: ReportsIcon,
  EMAIL: EmailIcon,
  SMS: SMSIcon,
  Tag: TagIcon,
  Arrow: ArrowIcon,
  Remove: RemoveIcon,
  "Billing Plans": BillingPlanIcon,
  "Billing Plans Blue": BillingPlanIconBlue,
  PENDING: PendingIcon,
  ACTIVE: ActiveIcon,
  CANCELLED: CancelledIcon,
  EXPIRED: ExpiredIcon,
  ALERTICON: AlertIcon,
  SUCCESSICON: SuccessIcon,
  FILTERICON: FilterIcon,
  MOREICON: MoreIcon,
  EXPANDMOREICON: ExpandMoreIcon,
  EXPANDLESSICON: ExpandLessIcon,
  ACTIVECARDICON: ActiveCardViewIcon,
  INACTIVECARDICON: InactiveCardViewIcon,
  ACTIVELISTICON: ActiveListViewIcon,
  INACTIVELISTICON: InactiveListViewIcon,
  LOCKICON: LockIcon,
  LOCKOPENICON: LockOpenIcon,
  TANZANIAFLAGICON: TanzaniaFlagIcon,
  PROFILEICON: ProfileIcon,
  UPLODEICON: UplodeIcon,
  UNITONLINEICON: UnitOnlineIcon,
  UNITOFFLINEICON: UnitOfflineIcon,
  STATIONERYICON: StationeryIcon,
  MOVINGICON: MovingIcon,
  "ROLES EMPTY STATE": RoleEmptyScreenIcon,
  "CUSTOMERS EMPTY STATE": UserEmptyScreenIcon,
  "SIM CARDS EMPTY STATE": SimCardEmptyScreenIcon,
  "DEVICES EMPTY STATE": DeviceEmptyScreenIcon,
  "SERVICES EMPTY STATE": ServiceEmptyScreenIcon,
  "UNIT GROUPS EMPTY STATE": UnitGroupsEmptyScreenIcon,
  "UNITS EMPTY STATE": UnitEmptyScreenIcon,
  "DRIVERS EMPTY STATE": DriverEmptyScreenIcon,
  "USERS EMPTY STATE": UserEmptyScreenIcon,
  "ACCOUNTS EMPTY STATE": AccountEmptyScreenIcon,
  "BILLING PLANS EMPTY STATE": BillingPlanEmptyScreenIcon,
  "CONSIGNMENTS EMPTY STATE": ConsignmentEmptyScreenIcon,
  "GEOFENCES EMPTY STATE": GeofenceEmptyScreenIcon,
  "ROUTE_OPTIMIZATION EMPTY STATE": GeofenceEmptyScreenIcon,
  POSITIONMARKICON: PositionMarkIcon,
  CHECKMARKICON: checkMarkIcon,
  SETTINGSICON: settingsIcon,
  ADDICON: addIcon,
  LOGOUTICON: logoutIcon,
  "Data Bundle": AccountsIcon,
  "Tracking History": AccountsIcon,
  "Live History Playback": AccountsIcon,
  "Real-time GPS Tracking": AccountsIcon,
  "Speed Monitoring Alerts": AccountsIcon,
  "Geofencing Alerts": AccountsIcon,
  "Service Alert": AccountsIcon,
  "Maintenance Alert": AccountsIcon,
  "Scheduled Trips": ReportsIcon,
  "Start and End Time": ReportsIcon,
  "Idle Time": ReportsIcon,
  "Distance Travelled": ReportsIcon,
  "Detailed Route Report": ReportsIcon,
  "Daily Movement Report": ReportsIcon,
  "Dedicated Support Line": AccountsIcon,
  "Third-party Integration": AccountsIcon,
  "Job Creation and Allocation": JobsIcon,
  "Route Optimization": AccountsIcon,
  "Advanced AI Fleet Manager": AccountsIcon,
  "Fuel Sensors": AccountsIcon,
  "Driver Job Allocation Application": JobsIcon,
  "International Roaming SIM Card": AccountsIcon,
  "cloud storage": AccountsIcon,
  "Event capture": AccountsIcon,
  "real-time video monitoring": AccountsIcon,
  "Live tracking": AccountsIcon,
  "Dash cam": AccountsIcon,
  "GPS Tracker": AccountsIcon,
  "Enhanced ERP integration": AccountsIcon,
  "Control room features": AccountsIcon,
  "RFID unlocking": AccountsIcon,
  "Online/SMS": AccountsIcon,
  "Industrial-level electronic lock with GPS": AccountsIcon,
  "Real-time status report": ReportsIcon,
  "RFID Card": AccountsIcon,
  Geofences: AccountsIcon,
  "Cloud Storage": AccountsIcon,
  Camera: AccountsIcon,
  "Tyre Pressure": AccountsIcon,
};
const IconDisplay = ({ iconName, ...rest }) => {
  const IconComponent = iconMap[iconName] || null;

  if (!IconComponent) {
    console.error(`Icon "${iconName}" not found in iconMap`);
    return null; // Or return a default icon or placeholder
  }

  return <IconComponent {...rest} />;
};

IconDisplay.propTypes = {
  iconName: PropTypes.oneOf(Object.keys(iconMap)).isRequired,
};
export default IconDisplay;
