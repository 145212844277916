import axios from "axios";
import { deviceApi } from "../utils/constant";

const api = axios.create({
  baseURL: deviceApi,
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercept requests to add the authorization token
api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};

// export const getDeviceStatusCounts = async (date, interval) => {
//   const interval1 = interval === 'daterange' ? 'range' : interval
//   if (typeof date.from == 'object') {
//     date.from = date.from.format('YYYY-MM-DD')
//     date.to = date.to.format('YYYY-MM-DD')
//   }
//   try {
//     const response = await api.get(
//       `/getDeviceStatusCounts?from=${date.from}&to=${date.to}&interval=${interval1}`
//     )
//     return response.data
//   } catch (error) {
//     handleApiError(error)
//   }
// }

export const getDeviceStatusCounts = async (date, interval) => {
  const interval1 = interval === "daterange" ? "RANGE" : interval;

  // Ensure date is formatted correctly if it's provided
  if (date && typeof date.from === "object") {
    date.from = date.from.format("YYYY-MM-DD");
    date.to = date.to.format("YYYY-MM-DD");
  }

  // Build the query string based on the presence of date
  const queryString = date
    ? `/getDeviceStatusCounts?from=${date.from}&to=${date.to}&interval=${interval1}`
    : `/getDeviceStatusCounts?interval=${interval1}`; // No dates for MONTHLY

  try {
    const response = await api.get(queryString);

    return response.data; // Return the fetched data
  } catch (error) {
    handleApiError(error);
    return []; // Return an empty array in case of error
  }
};

// Fetch all devices with optional query parameters
export const fetchAllDevices = async (page, limit) => {
  try {
    const response = await api.get("/", {
      params: { page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Get a device by its ID
export const getDeviceById = async (deviceId) => {
  try {
    const response = await api.get(`/${deviceId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Create a new device
export const createDevice = async (data) => {
  try {
    const response = await api.post("/", data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Update an existing device by its ID
export const updateDevice = async (data) => {
  try {
    const response = await api.put(`/${data.id}`, data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Delete a device by its ID
export const deleteDevice = async (id) => {
  try {
    const response = await api.delete(`/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch device by name with pagination
export const fetchDevicesByName = async (name, page, limit) => {
  try {
    const response = await api.get("/fetchDevicesByName", {
      params: { name, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch device by name with pagination
export const fetchDevicesByDeviceType = async (deviceType, page, limit) => {
  try {
    const response = await api.get("/fetchDevicesByDeviceType", {
      params: { deviceType, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch device by make with pagination
export const fetchDevicesByMake = async (make, page, limit) => {
  try {
    const response = await api.get("/fetchDevicesByMake", {
      params: { make, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch device by model with pagination
export const fetchDevicesByModel = async (model, page, limit) => {
  try {
    const response = await api.get("/fetchDevicesByModel", {
      params: { model, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchDevicesByMultipleCriteria = async (
  id,
  make,
  model,
  page,
  limit
) => {
  try {
    const response = await api.get("/fetchDevicesByMultipleCriteria", {
      params: {
        id,
        make,
        model,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
