/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import {
  Checkbox,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getUnitById, updateUnit } from '../../services/unitNew.service'
import { updateDevice } from '../../services/deviceNew.services'
import { updateUnitGroup } from '../../services/unitGroupNew.service'
import ErrorMessage from '../../modules/common/ErrorMessage'
import { updateUser } from '../../services/userNew.service'
import EditScreenEmptyState from '../../modules/common/EditScreenEmptyState'
import deviceEmptyscreenIcon from '../../assets/icons/deviceEmptyscreen.svg'
import positionMarkIcon from '../../assets/icons/positionMarkIcon.svg'
import userEmptyScreen from '../../assets/icons/userEmptyScreen.svg'
import unitEmptyScreen from '../../assets/icons/unitEmptyScreen.svg'
import unitGroupsEmptyScreen from '../../assets/icons/unitGroupsEmptyScreen.svg'
import { StyledInputControlContainer } from '../../modules/management/billingPlans/styles/BillingPlansStyles'
// eslint-disable-next-line no-unused-vars
import EditScreenActivateState from '../../modules/common/EditScreenActivateState'
import { UnitsModel } from '../../model/units.model'
import { iconMap } from '../../constants/iconsList'
import {
  StyledAddMoreButton,
  StyledFormControlLabel,
  StyledIconButton3,
  StyledSearchTextField,
  StyledSelectAllButton
} from '../../styledComponents'

export default function EditTabs(props) {
  const [existingSearchValue, setexistingSearchValue] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [selectItems, setSelectItems] = useState([])
  const [filteredSelectItems, setFilteredSelectItems] = useState([])
  const [filteredValues, setFilteredValues] = useState([])
  const [remainingValues, setRemainingValues] = useState([])
  const [filteredRemainingValues, setFilteredRemainingValues] = useState([])
  const [filteredFilteredValues, setFilteredFilteredValues] = useState([])
  const [selectedUnitGroup, setSelectedUnitGroup] = useState(UnitsModel)
  const [errorMessage, setErrorMessage] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [unitGroup, setUnitGroup] = useState()
  const navigate = useNavigate()
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.items && props.items.length > 0) {
      // Filter checkBoxValues based on accountDetails.id
      if (props.name == 'Device') {
        const filtered = props.items.filter(
          (item) => item.status === 'PROVISIONED'
        )
        setFilteredValues(filtered)
        const remaining = props.items.filter(
          (item) => item.status === 'UNPROVISIONED'
        )
        setRemainingValues(remaining)
        setFilteredRemainingValues(remaining)
        setFilteredFilteredValues(filtered)
      } else if (props.name == 'User') {
        const filtered = props.items.filter((item) => item.status === 'ACTIVE')
        setFilteredValues(filtered)
        const remaining = props.items.filter(
          (item) => item.status === 'INACTIVE'
        )
        setRemainingValues(remaining)
        setFilteredRemainingValues(remaining)
        setFilteredFilteredValues(filtered)
      } else if (props.name == 'Unit Group') {
        const filtered = props.items
        setFilteredValues(filtered)
        const remaining = []
        setRemainingValues(remaining)
        setFilteredRemainingValues(remaining)
        // setFilteredFilteredValues(filtered);
      } else if (props.name == 'Unit') {
        const filtered = props.items.filter(
          (item) => item.status === 'ACTIVATED'
        )
        setFilteredValues(filtered)
        const remaining = props.items.filter(
          (item) => item.status === 'DEACTIVATED'
        )
        setRemainingValues(remaining)
        setFilteredRemainingValues(remaining)
        setFilteredFilteredValues(filtered)
      } else {
        const filtered = props.items.filter(
          (item) => item.status === 'ACTIVATED'
        )
        setFilteredValues(filtered)
        // Get remaining items where id is not equal to accountDetails.id
        const remaining = props.items.filter(
          (item) => item.status === 'DEACTIVATED'
        )
        setRemainingValues(remaining)

        // Initially set filtered list items to all items
        setFilteredRemainingValues(remaining)
        setFilteredFilteredValues(filtered)
      }
    }
  }, [props.items])

  const handleExistingSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase()
    setexistingSearchValue(searchValue)

    // Filter remainingValues based on searchValue
    const filteredRemaining = remainingValues.filter(
      (item) =>
        (item.firstName &&
          item.firstName.toLowerCase().includes(searchValue)) ||
        (item.name && item.name.toLowerCase().includes(searchValue)) ||
        (item.make && item.make.toLowerCase().includes(searchValue))
    )
    setFilteredRemainingValues(filteredRemaining)
  }

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase()
    setSearchValue(searchValue)

    // Filter filteredValues based on searchValue
    const filteredFiltered = filteredValues.filter(
      (item) =>
        (item.firstName &&
          item.firstName.toLowerCase().includes(searchValue)) ||
        (item.name && item.name.toLowerCase().includes(searchValue)) ||
        (item.make && item.make.toLowerCase().includes(searchValue))
    )
    setFilteredFilteredValues(filteredFiltered)
  }
  async function fetchUnitNames(unitIds) {
    const promises = unitIds.map((unitId) => getUnitById(unitId.unitId))
    return Promise.all(promises)
  }
  const handleCheckBox = (value) => {
    // if (props.name === "Unit Group") {
    //   setSelectedUnitGroup(value);
    //   console.log(value);
    //   if (selectItems.includes(value)) {
    //     // If the value is unchecked, set FilteredFilteredValues as empty
    //     setFilteredFilteredValues([]);
    //   } else {
    //     // If the value is checked, fetch unit names and update FilteredFilteredValues
    //     fetchUnitNames(value.units)
    //       .then((unitNames) => {
    //         const updatedFilteredFilteredValues = value.units.map(
    //           (unitId, index) => ({
    //             id: unitId.id,
    //             name: unitNames[index].name, // Assuming unitNames is an array of unit names in the same order as unitIds
    //           })
    //         );
    //         setFilteredFilteredValues(updatedFilteredFilteredValues);
    //       })
    //       .catch((error) => {
    //         console.error("Error fetching unit names:", error);
    //       });
    //   }
    // }
    // setFilteredFilteredValues(value.units);
    if (selectItems.includes(value)) {
      // If the value is already in the selectItems array, remove it
      setSelectItems((prevItems) => prevItems.filter((item) => item !== value))
    } else {
      // If the value is not in the selectItems array, add it
      setSelectItems((prevItems) => [...prevItems, value])
    }
  }

  const handleSelectedCheckBox = (value) => {
    if (filteredSelectItems.includes(value)) {
      // If the value is already in the selectItems array, remove it
      setFilteredSelectItems((prevItems) =>
        prevItems.filter((item) => item !== value)
      )
    } else {
      // If the value is not in the selectItems array, add it
      setFilteredSelectItems((prevItems) => [...prevItems, value])
    }
  }

  const handleSelectAll = () => {
   
     // If the condition of the ternary operator is false, deselect all items
     if (selectItems.length !== 0) {
      setSelectItems([])
    } else {
      // If all items are already selected, select all items
      setSelectItems(remainingValues)
    }
  }

  const handleSelectAllSelected = () => {
    if (filteredSelectItems.length !== 0) {
      setFilteredSelectItems([])
    } else {
      setFilteredSelectItems(filteredValues)
    }
  }

  const handleUpdate = async () => {
    // Assuming selectItems is the array of objects
    for (let i = 0; i < selectItems.length; i++) {
      // Update the accountId field of each object
      if (props.name == 'Device') {
        selectItems[i].status = 'PROVISIONED'
      } else if (props.name == 'User') {
        selectItems[i].status = 'ACTIVE'
      } else if (props.name == 'Unit') {
        selectItems[i].status = 'ACTIVATED'
      }
      // setIsLoading(true);
      try {
        var response
        if (props.name == 'Unit') {
          response = await updateUnit(selectItems[i].id, selectItems[i])
        } else if (props.name == 'Device') {
          response = await updateDevice(selectItems[i].id, selectItems[i])
        } else if (props.name == 'Unit Group') {
          setSelectedUnitGroup({
            ...selectedUnitGroup,
            units: [...selectedUnitGroup.units, selectItems[i]]
          })
          response = await updateUnitGroup(selectedUnitGroup.id, {
            ...selectedUnitGroup,
            units: [...selectedUnitGroup.units, selectItems[i]]
          })
          //   response = await updateUnitGroups(selectItems[i].id, selectItems[i]);
        } else if (props.name == 'User') {
          response = await updateUser(selectItems[i].id, selectItems[i])
        }
        if (response) {
          window.location.reload()
        } else {
          console.error(`Failed to update object ${i + 1}`)
        }
        // setIsLoading(false);
      } catch (error) {
        setErrorMessage(error.message)
        // setIsLoading(false);
      }
    }
  }

  const handleRemove = async () => {
    // Assuming selectItems is the array of objects
    for (let i = 0; i < filteredSelectItems.length; i++) {
      filteredSelectItems[i].status = 'DEACTIVATED'
      if (props.name == 'Device') {
        filteredSelectItems[i].status = 'UNPROVISIONED'
      } else if (props.name == 'User') {
        filteredSelectItems[i].status = 'INACTIVE'
      } else if (props.name == 'Unit') {
        filteredSelectItems[i].status = 'DEACTIVATED'
      }
      // setIsLoading(true)
      try {
        var response
        if (props.name == 'Unit') {
          response = await updateUnit(
            filteredSelectItems[i].id,
            filteredSelectItems[i]
          )
        } else if (props.name == 'Device') {
          response = await updateDevice(
            filteredSelectItems[i].id,
            filteredSelectItems[i]
          )
        } else if (props.name == 'Unit Group') {
          const updatedFilteredFilteredValues = filteredFilteredValues.filter(
            (item) =>
              !filteredSelectItems.some(
                (selectItem) => selectItem.id === item.id
              )
          )
          setSelectedUnitGroup({
            ...selectedUnitGroup,
            units: updatedFilteredFilteredValues
          })

          // Set the state with the updated array
          // setFilteredFilteredValues(updatedFilteredFilteredValues);
          // response = await updateUnitGroups(selectItems[i].id, selectItems[i]);
          response = await updateUnitGroup(selectedUnitGroup.id, {
            ...selectedUnitGroup,
            units: updatedFilteredFilteredValues
          })
        } else if (props.name == 'User') {
          response = await updateUser(
            filteredSelectItems[i].id,
            filteredSelectItems[i]
          )
        }
        if (response) {
          window.location.reload()
        } else {
          console.error(`Failed to update object ${i + 1}`)
        }
        // setIsLoading(false);
      } catch (error) {
        if (error.response?.data) {
          // Optional chaining used here
          setErrorMessage(error.response.data.message)
          // setIsLoading(false);
        } else {
          setErrorMessage(error.message)
          // setIsLoading(false);
        }
      }
    }
  }

  const handleNavigate = () => {
    navigate(props.navigate)
  }
  const iconMappingTop = {
    Device: deviceEmptyscreenIcon,
    User: userEmptyScreen,
    Unit: unitEmptyScreen,
    'Unit Group': unitGroupsEmptyScreen
    // Add more icon mappings as needed
  }

  const iconMappingBottom = {
    Device: positionMarkIcon,
    User: positionMarkIcon,
    Unit: positionMarkIcon,
    'Unit Group': positionMarkIcon

    // Add more icon mappings as needed
  }
  const handleFieldChange = (value) => {
    const filtered = props.items
    setFilteredValues(filtered)

    const remaining = props.unitData
    setRemainingValues(remaining)

    // Create a copy of remaining
    let updatedRemaining = [...remaining]
    // Iterate over filtered array
    //  filtered.forEach((item) => {
    // Get the units from the current item in filtered
    const units = value.units

    // Iterate over units array
    units.forEach((unit) => {
      console.log(unit)
      const unitId = unit.unitId

      // Remove items with the same ID from updatedRemaining
      updatedRemaining = updatedRemaining.filter(
        (remItem) => remItem.id !== unitId
      )
    })

    // Set the updated remaining values
    setFilteredRemainingValues(updatedRemaining)
    setUnitGroup(value.id)
    setSelectedUnitGroup(value)
    if (selectItems.includes(value)) {
      // If the value is unchecked, set FilteredFilteredValues as empty
      setFilteredFilteredValues([])
    } else {
      // If the value is checked, fetch unit names and update FilteredFilteredValues
      fetchUnitNames(value.units)
        .then(() => {
          console.log(value.units)
          const updatedFilteredFilteredValues = value.units.map(
            (unitId) => ({
              id: unitId.unitId,
              name: unitId.unitName, // Assuming unitNames is an array of unit names in the same order as unitIds
              
            })
          )
          setFilteredFilteredValues(updatedFilteredFilteredValues)
        })
        // console.log(value.units)
        .catch((error) => {
          console.error('Error fetching unit names:', error)
        })
    }
  }
  return (
    <Grid>
      <ErrorMessage message={errorMessage} />
      {props.name == 'Unit Group' && (
        <StyledInputControlContainer>
          <Typography variant="label">Select Unit Group</Typography>
          <Select
            labelId="demo-simple-select-label"
            name="accountId"
            id="demo-simple-select"
            sx={{ width: '100%' }}
            // value={unitGroup?.accountId}
            onChange={(event) => handleFieldChange(event.target.value)}
          >
            {props.items.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </StyledInputControlContainer>
      )}

      <Grid container sx={{ height: '100vh' }}>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            height: '100vh',
            border: '1px solid #d8d8df',
            backgroundColor: '#f7f7f8',
            padding: '12px',
            borderRadius: '4px'
          }}
        >
          <Grid
            sx={{ borderBottom: '1px solid #d8d8df', marginBottom: '12px' }}
          >
            <Typography
              variant="subTitle1"
              sx={{
                fontWeight: 500,
                letterSpacing: '-0.02em',
                color: '#0A2C47'
              }}
            >
              {props.name == 'User'
                ? `Inactive ${props.name}s`
                : props.name == 'Unit'
                ? `Deactivated ${props.name}s`
                : props.name == 'Unit Group'
                ? 'Units'
                : props.name == 'Device'
                ? `Unprovisioned ${props.name}s`
                : ''}
            </Typography>
            <StyledSearchTextField
              variant="outlined"
              margin="normal"
              placeholder={'Find ' + props.name + '...'}
              value={existingSearchValue}
              onChange={handleExistingSearchChange}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <img src={iconMap['SearchIcon']} alt="SearchIcon" />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
       <Box
        sx={{
               maxHeight: '250px', // Set the desired max height
               overflow: 'auto',
               padding: '10px'
           }}>
       {filteredRemainingValues.map((value, index) => (
            <Box key={index}>
              {value.name != '' &&
                value.firstName != '' &&
                value.make != '' && (
                  <StyledFormControlLabel
                    control={
                      <Checkbox
                        checked={selectItems.includes(value)}
                        icon={
                              <img
                                src={iconMap['CheckBox']}
                                alt="CheckBox"
                                style={{ width: 20, height: 20 }}
                              />
                            }
                            checkedIcon={
                              <img
                                src={iconMap['CheckBoxChecked']}
                                alt="CheckBoxChecked"
                                style={{ width: 20, height: 20 }}
                              />
                            }
                        onChange={() => handleCheckBox(value)}
                      />
                    }
                    label={
                      value.firstName
                        ? value.firstName
                        : value.name
                        ? value.name
                        : value.make
                    }
                  />
                )}
            </Box>
          ))}
       </Box>
          <StyledSelectAllButton variant="outlined" onClick={handleSelectAll}>
          {selectItems.length == 0 ? 'Select All' : 'Unselect All'}
          </StyledSelectAllButton>
        </Grid>
        <Grid
          item
          xs={12}
          md={1}
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '20px'
          }}
        >
          <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
            <StyledIconButton3 sx={{transform: 'rotate(180deg)'}} onClick={handleUpdate}>
              <img src={iconMap['fastforward2']} alt="fastforward1" />
            </StyledIconButton3>
            <StyledIconButton3  onClick={handleRemove}>
              <img src={iconMap['fastforward2']} alt="fastforward2" />
            </StyledIconButton3>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            height: '100vh',
            border: '1px solid #d8d8df',
            padding: '12px',
            borderRadius: '4px'
          }}
        >
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #d8d8df',
              marginBottom: '12px'
            }}
          >
            <Typography
              item
              variant="subTitle1"
              sx={{
                fontWeight: 500,
                letterSpacing: '-0.02em',
                color: '#0A2C47'
              }}
            >
              {props.name == 'Unit Group'
                ? 'Grouped Units'
                : props.name == 'Unit'
                ? `Activated ${props.name}s`
                : props.name == 'Device'
                ? `Provisioned ${props.name}s`
                : props.name == 'User'
                ? `Active ${props.name}s`
                : ''}{' '}
            </Typography>
            <StyledAddMoreButton item sx={{ mb: 2 }} onClick={handleNavigate}>
              <img src={iconMap['AddMorePlusIcon']} alt="PlusIcon" />
              Create new {props.name}s
            </StyledAddMoreButton>
            <StyledSearchTextField
              item
              xs={12}
              variant="outlined" // <== changed this
              margin="normal"
              placeholder={'Find ' + props.name + '...'}
              value={searchValue}
              onChange={handleSearchChange}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <img src={iconMap['SearchIcon']} alt="SearchIcon" />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          {filteredFilteredValues.length === 0 &&
            filteredRemainingValues.length === 0 &&
            selectedUnitGroup.accountId === '' && (
              <Typography align="center">
                <EditScreenEmptyState
                  name={props.name}
                  iconTop={iconMappingTop[props.name]}
                  iconBottom={iconMappingBottom[props.name]}
                />
              </Typography>
            )}
          {filteredFilteredValues == 0 &&
            filteredRemainingValues.length > 0 &&
            selectedUnitGroup.accountId === '' && (
              <Typography align="center">
                <EditScreenActivateState
                  name={props.name}
                  iconTop={iconMappingTop[props.name]}
                  iconBottom={iconMappingBottom[props.name]}
                />
              </Typography>
            )}
         <Box
          sx={{
               maxHeight: '250px', // Set the desired maxheight
               overflow: 'auto',
               padding: '10px'
             }}
            >
         {filteredFilteredValues.map(
            (value, index) =>
              (value.firstName || value.name) && (
                <Box key={index}>
                  <StyledFormControlLabel
                    control={
                      <Checkbox
                        checked={filteredSelectItems.includes(value)}
                        icon={
                              <img
                                src={iconMap['CheckBox']}
                                alt="CheckBox"
                                style={{ width: 20, height: 20 }}
                              />
                            }
                            checkedIcon={
                              <img
                                src={iconMap['CheckBoxChecked']}
                                alt="CheckBoxChecked"
                                style={{ width: 20, height: 20 }}
                              />
                            }
                        onChange={() => handleSelectedCheckBox(value)}
                      />
                    }
                    label={
                      value.firstName
                        ? value.firstName
                        : value.name
                        ? value.name
                        : value.make
                    }
                  />
                </Box>
              )
          )}
         </Box>
          {filteredFilteredValues.length > 0 && (
            <StyledSelectAllButton
              variant="outlined"
              onClick={handleSelectAllSelected}
            >
              {filteredSelectItems.length == 0
                    ? 'Select All'
                    : 'Unselect All'}
            </StyledSelectAllButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
