/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  Grid,
  Menu,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import DeleteModal from "../../../modals/DeleteModal";
import Loader from "../../../common/Loader";
import {
  StyledIconButton,
  StyledMenuItem,
  StyledTableContainer,
  StyledTableHead,
} from "../../../../styledComponents";
import { billingPlanColumnNew } from "../../../../utils/constant";
import {
  PricingCycleItem,
  PricingCycleText,
  ServiceIcon,
  ServiceItem,
  ServiceLimit,
  ServiceName,
} from "../styles/BillingPlansStyles";
import { iconMap } from "../../../../constants/iconsList";
import EmptyState from "../../../common/EmptyState";
import debounce from "lodash.debounce";
import { deleteBillingPlan } from "../../../../services/billingPlanNew.service";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";

const BillingPlansListTableNew = () => {
  const [errors, setErrors] = useState([]);
  const [openMenuBillingPlanId, setOpenMenuBillingPlanId] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [shouldReload, setShouldReload] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();
  const { billingPlans, fetchMore, isLoading, isEmpty } = useOutletContext();
  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [billingPlans, isLoading, handleScroll]);

  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    event.stopPropagation();
    setOpenMenuBillingPlanId(selectedItem.id);
    selectedItem(selectedItem);
  }, []);

  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuBillingPlanId(null);
    event?.stopPropagation();
  }, []);

  const handleDeleteModalOpen = useCallback(
    (event, billingPlanId) => {
      handleActionsMenuClose();
      selectedItem(billingPlanId);
      setOpen(true);
    },
    [handleActionsMenuClose]
  );

  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    selectedItem(null);
  }, []);

  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteBillingPlan(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, "Billing Plan deleted successfully.");
    //     handleError([]);
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error);
    // } finally {
    // setOpen(false);
    // setSelectedItem(null);
    // }
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
    }
  };
  const handleError = (error) => {
    window.scrollTo(0, 0);
    // if (error.name === 'ValidationError') {
    //   handleValidationErrors(error)
    // } else {
    setErrors(error.error);
    // }
  };

  const navigateToEditScreen = useCallback(
    (billingPlanId) => {
      navigate(`/management/billing-plans/edit/${billingPlanId}`);
    },
    [navigate]
  );

  return (
    <>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledTableContainer ref={containerRef}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="BILLING PLANS"
              message="Oops! You have no billingPlans for your search criteria"
              info="Please feel free to create a new billingPlan by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {!isLoading && !isEmpty && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <StyledTableHead>
                <TableRow>
                  {billingPlanColumnNew.map((column) => (
                    <TableCell key={column}>
                      <Typography variant="tableHeader">{column}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {billingPlans?.map((billingPlan) => (
                  <TableRow
                    key={billingPlan.id}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigateToEditScreen(billingPlan.id)}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ verticalAlign: "top" }}
                    >
                      <Typography variant="tableValue">
                        {billingPlan.name}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {billingPlan.servicesLimit
                          .filter(
                            (service) =>
                              service.limit !== -1 && service.limit !== 0
                            // service.value === false
                          )
                          .map((service) => (
                            <ServiceItem key={service.serviceId}>
                              <ServiceIcon>
                                <img
                                  src={iconMap[service.serviceName]}
                                  alt={service.serviceName}
                                  style={{ height: "25px" }}
                                />
                                <ServiceName>{service.serviceName}</ServiceName>
                                <ServiceLimit>
                                  {service.limit}{" "}
                                  {service.serviceName == "Data Bundle"
                                    ? "MB"
                                    : ""}
                                </ServiceLimit>
                              </ServiceIcon>
                            </ServiceItem>
                          ))}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {billingPlan.servicesLimit
                          .filter(
                            (service) =>
                              service.limit === -1 &&
                              service.type === "Features" &&
                              service.value === true
                          )
                          .map((service) => (
                            <ServiceItem key={service.serviceId}>
                              <ServiceIcon>
                                <img
                                  src={iconMap[service.serviceName]}
                                  alt={service.serviceName}
                                  style={{ height: "25px" }}
                                />
                                <ServiceName>{service.serviceName}</ServiceName>
                              </ServiceIcon>
                            </ServiceItem>
                          ))}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {billingPlan.servicesLimit
                          .filter(
                            (service) =>
                              service.limit === -1 &&
                              service.type === "Alerts" &&
                              service.value === true
                          )
                          .map((service) => (
                            <ServiceItem key={service.serviceId}>
                              <ServiceIcon>
                                <img
                                  src={iconMap[service.serviceName]}
                                  alt={service.serviceName}
                                  style={{ height: "25px" }}
                                />
                                <ServiceName>{service.serviceName}</ServiceName>
                              </ServiceIcon>
                            </ServiceItem>
                          ))}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {billingPlan.servicesLimit
                          .filter(
                            (service) =>
                              service.limit === -1 &&
                              service.type === "Reports" &&
                              service.value === true
                          )
                          .map((service) => (
                            <ServiceItem key={service.serviceId}>
                              <ServiceIcon>
                                <img
                                  src={iconMap[service.serviceName]}
                                  alt={service.serviceName}
                                  style={{ height: "18px" }}
                                />
                                <ServiceName>{service.serviceName}</ServiceName>
                              </ServiceIcon>
                            </ServiceItem>
                          ))}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {billingPlan.prices.map((price) => (
                          <PricingCycleItem key={price.priceId}>
                            <PricingCycleText>
                              {price.pricingCycle}
                            </PricingCycleText>
                          </PricingCycleItem>
                        ))}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {billingPlan.servicesLimit
                          .filter(
                            (service) =>
                              service.limit === -1 &&
                              service.type === "Sensors" &&
                              service.value === true
                          )
                          .map((service) => (
                            <ServiceItem key={service.serviceId}>
                              <ServiceIcon>
                                <img
                                  src={iconMap[service.serviceName]}
                                  alt={service.serviceName}
                                  style={{ height: "25px" }}
                                />
                                <ServiceName>{service.serviceName}</ServiceName>
                              </ServiceIcon>
                            </ServiceItem>
                          ))}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <StyledIconButton
                        id={`menu-button-${billingPlan.id}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleActionsMenuOpen(event, billingPlan);
                        }}
                      >
                        <MoreVertIcon />
                      </StyledIconButton>
                      <Menu
                        anchorEl={document.getElementById(
                          `menu-button-${billingPlan.id}`
                        )}
                        open={openMenuBillingPlanId === billingPlan.id} // The menu is open if the openMenuBillingPlanId matches
                        onClose={(event) => handleActionsMenuClose(event)}
                      >
                        <StyledMenuItem
                          onClick={(event) => {
                            event.stopPropagation(); // Prevents the click event from reaching the TableRow
                            handleDeleteModalOpen(event, billingPlan);
                          }}
                        >
                          <Grid sx={{ display: "flex", alignItems: "center" }}>
                            <DeleteOutlineIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            <Typography>Delete Billing Plan</Typography>
                          </Grid>
                        </StyledMenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledTableContainer>

      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default BillingPlansListTableNew;
