import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import UnitGraph from "../components/unitGraph";
import UserGraph from "../components/userGraph";
import DeviceGraph from "../components/deviceGraph";
import AccountGraph from "../components/accountGraph";
import styled from "styled-components";
import theme from "../../../../utils/theme";
import { iconMap } from "../../../../constants/iconsList";

const TitleBox = styled(Box)`
  display: grid;
  grid-template-rows: auto auto;
  gap: 8px;
`;
const MainTitle = styled(Typography)`
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  ${theme.breakpoints.down("md")} {
    font-size: 20px;
    width: 200px;
  }
`;
const SubText = styled(Typography)`
  color: #65818b;
  ${theme.breakpoints.down("md")} {
    font-size: 14px;
  }
`;
export default function Graphs() {
  return (
    <Box>
      <TitleBox>
        <MainTitle variant="mainTitle">
          <img
            height="30px"
            src={iconMap["DashboardBlue"]}
            alt="icon"
            style={{ marginRight: "10px" }}
          ></img>
          Dashboard
        </MainTitle>
        <SubText variant="subTitle" sx={{ marginBottom: "16px" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit
        </SubText>
      </TitleBox>
      <Grid container>
        <Grid md={6}>
          <UnitGraph title="Unit" />
        </Grid>
        <Grid md={6}>
          <UserGraph title="User" />
        </Grid>
        <Grid md={6}>
          <DeviceGraph title="Device" />
        </Grid>
        <Grid md={6}>
          <AccountGraph title="Account" />
        </Grid>
      </Grid>
    </Box>
  );
}
