import * as yup from "yup";

export const devicesSchema = yup.object().shape({
  deviceImageUrl: yup.string().trim().nullable(), // assuming it's optional
  deviceType: yup
    .string()
    .required("Device type is required.")
    .oneOf(["E-lock", "OBD Tracker/GPS", "Wired GPS"], "Invalid device type"),
  name: yup
    .string() 
    .trim()
    .min(3, "Name should be at least 3 characters.")
    .max(50, "Name cannot exceed 50 characters.")
    .required("Name is required."),
  make: yup
    .string()
    .trim()
    .min(3, "Make should be at least 3 characters.")
    .max(50, "Make cannot exceed 50 characters.")
    .required("Make is required."),
  model: yup
    .string()
    .trim()
    .min(3, "Model should be at least 3 characters.")
    .max(50, "Model cannot exceed 50 characters.")
    .required("Model is required."),
  SSID: yup
    .string()
    .trim()
    .max(50, "SSID cannot exceed 50 characters.")
    .nullable(), // assuming it's optional
  UID: yup
    .string()
    .trim()
    .max(50, "UID cannot exceed 50 characters.")
    .nullable() // assuming it's optional
    .required("UID is required."),
  simCardId: yup
    .string()
    .required("Sim card ID is required") // Sim card ID is required without any conditional check
    .trim(),
  serverAddress: yup
    .string()
    .trim()
    .max(200, "Server address cannot exceed 200 characters.")
    .nullable(), // assuming it's optional
  rfids: yup.array().of(yup.string().trim()).nullable(), // assuming it's optional
  description: yup
    .string()
    .trim()
    .max(400, "Description cannot exceed 400 characters.")
    .nullable(), // assuming it's optional
  setupInstructions: yup
    .string()
    .trim()
    .max(400, "Setup instructions cannot exceed 400 characters.")
    .nullable(), // assuming it's optional
  status: yup
    .string()
    .oneOf(["PROVISIONED", "UNPROVISIONED"], "Invalid status")
    .default("UNPROVISIONED"),
  createdBy: yup.string().nullable(), // assuming it's optional
  updatedBy: yup.string().nullable(), // assuming it's optional
});
