import React from "react";
import { Typography, Grid, CardContent, CardMedia } from "@mui/material";
// import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {
  StyledSubtitle,
  StyledCard,
  EllipseContainer,
} from "../styles/HomeStyles";
import { modules } from "../../../../utils/constant";

const Home = () => {
  const navigate = useNavigate();

  // const userData = useSelector((state) => state.user.userData);

  const handleModuleClick = (link) => {
    navigate(link);
  };

  // if (!userData) {
  //   return <div>Loading...</div>; // Or any other loading indicator
  // }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="mainTitle">Application Dashboard</Typography>
        <StyledSubtitle>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </StyledSubtitle>
      </Grid>
      {modules.map((module, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <StyledCard
            hovercolor={module.hovercolor}
            bgcolor={module.bgcolor}
            onClick={() => handleModuleClick(module.link)}
            sx={{ width: "325px" }}
          >
            <CardContent>
              <Typography
                sx={{
                  fontSize: "26px",
                  fontWeight: "500",
                  color: "#0a2c47",
                  lineHeight: "28px",
                }}
                component="div"
              >
                {/* {module.name} */}
                {module.name.split(" ")[0]}
                <br />
                {module.name.split(" ").slice(1).join(" ")}
              </Typography>
              <Typography
                // variant="body2"
                // color="text.secondary"
                sx={{ padding: "16px 0", fontSize: "14px", color: "#6c6c81" }}
              >
                {module.info}
              </Typography>
            </CardContent>
            <EllipseContainer ellipsecolor={module.ellipsecolor}>
              <CardMedia
                component="img"
                src={module.icon}
                alt={module.name}
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
            </EllipseContainer>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default Home;
