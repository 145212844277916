/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SearchFormSelect from "../../../common/SearchFormSelect";
import { fetchAllDevices } from "../../../../services/deviceNew.services";
import FilterListIcon from "@mui/icons-material/FilterList";

const DevicesFilters = ({ onFiltersChange }) => {
  const [filters, setFilters] = useState({
    id: "",
    make: "",
    model: "",
  });
  const [devices, setDevices] = useState([]);
  const [page] = useState(1);
  const [limit] = useState(15);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters]);

  useEffect(() => {
    _fetchAllDevices();
  }, [page, limit]);

  const _fetchAllDevices = async () => {
    if (isLoadingDevices) return; // Prevent multiple calls
    setIsLoadingDevices(true);

    try {
      let allDevices = [];
      let currentPage = 1;
      let totalFetched = 0;

      let data;
      do {
        data = await fetchAllDevices(currentPage, limit);
        allDevices = [...allDevices, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      setDevices(allDevices);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching devices:", errorMessage);
    } finally {
      setIsLoadingDevices(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex" }}>
        <Grid item xs={12} sm={1} sx={{ display: "flex" }}>
          <Grid
            sx={{ marginTop: "20px", color: "#6C6C81", fontWeight: "600" }}
            mr={1}
          >
            FILTERS
          </Grid>
          <FilterListIcon sx={{ marginTop: "20px" }} />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Name"
            value={filters.id}
            onChange={(selected) => {
              handleFieldChange("id", selected);
            }}
            options={devices.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Make"
            value={filters.make}
            onChange={(selected) => {
              handleFieldChange("make", selected);
            }}
            options={[
              ...new Map(
                devices.map((item) => [
                  item.make,
                  { value: item.make, label: item.make },
                ])
              ).values(),
            ]}
          />
        </Grid>

        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Model"
            value={filters.model}
            onChange={(selected) => {
              handleFieldChange("model", selected);
            }}
            options={[
              ...new Map(
                devices.map((item) => [
                  item.model,
                  { value: item.model, label: item.model },
                ])
              ).values(),
            ]}
          />
        </Grid>

        {/* <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Model"
            value={filters.model}
            onChange={(selected) => {
              handleFieldChange("model", selected);
            }}
            options={devices.map((item) => ({
              value: item.model,
              label: item.model,
            }))}
          />
        </Grid> */}
      </Grid>
    </>
  );
};

DevicesFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired, // Validate that onFiltersChange is a required function prop
};

export default DevicesFilters;
