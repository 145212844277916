/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid, IconButton, Typography } from '@mui/material'
import {
  StyledFormContainer,
  StyledHeader2,
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
  StyledInputControlContainer,
  NextButton
} from '../../../../styledComponents'
import { iconMap } from '../../../../constants/iconsList'
import FormField from '../../../common/FormField'
import FormFieldMultiline from '../../../common/FormFieldMultiline'
import { GeofencingModel } from '../../../../model/geofencing.model'
import {
  validateForm,
  validateForm2
} from '../../../../utils/yupValidations'
import { geofenceSchema } from '../../../../utils/validation-schemas/geofencesSchema'
import { Title } from '../../../management/units/styles/UnitsStyles'
import ColorPicker from '../../../common/ColorPicker'
import GoogleMaps from './googleMaps'
import { useNavigate, useParams } from 'react-router-dom'
import {
  createGeofence,
  getGeofenceById,
  updateGeofence
} from '../../../../services/geofencesNew.service'
import FormOptionSelector from '../../../common/FormOptionSelector'
import { geofenceTypes } from '../../../../utils/constant'
import Loader from '../../../common/Loader'
import FormError from '../../../common/FormError'
import ErrorMessage1 from '../../../common/ErrorMessage1'
import SuccessMessage from '../../../common/SuccessMessage'
import { getRoute } from '../../../../constants/routes'
// import FormColorPicker from '../../../common/FormColorPicker'

const GeofencesForm = () => {
  const { id } = useParams()
  const [successMessage, setSuccessMessage] = useState('')
  const [validationErrors, setValidationErrors] = useState({})
  const [geofence, setGeofence] = useState(GeofencingModel)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [errors, setErrors] = useState([])
  const [defaultsSet, setDefaultsSet] = useState(false)
  const [validateCoordinates, setValidateCoordinates] = useState(false)

  const defaultAccountId =
    localStorage.getItem('defaultAccountId') !== ''
      ? JSON.parse(localStorage.getItem('defaultAccountId'))
      : ''

  useEffect(() => {
    if (id) {
      _getGeofenceById(id)
    } else {
      setDefaults()
    }
  }, [id])

  useEffect(() => {
    if (validateCoordinates === true) {
      if (geofence.type === 'CIRCLE') {
        validateForm2(geofence, 'radius', setValidationErrors, geofenceSchema)
      } else {
        validateForm2(
          geofence,
          'coordinates',
          setValidationErrors,
          geofenceSchema
        )
      }
    }
    setValidateCoordinates(false)
  }, [validateCoordinates])

  useEffect(() => {
    setGeofence({
      ...geofence,
      radius: null,
      area: null,
      perimeter: null,
      coordinates: []
    })
  }, [geofence.type])

  const _getGeofenceById = async (id) => {
    setIsLoading(true)
    try {
      const data = await getGeofenceById(id)
      if (data.type == 'POLYGON') {
        data.center = data.coordinates[0]
      }
      setGeofence(data)
      handleResponse(data, 'Geofence fetched successfully!', 'Get')
      // setSelectedGeofenceType(data.type)
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const setDefaults = () => {
    const updatedGeofence = {
      ...geofence,
      accountId: defaultAccountId
    }
    setGeofence(updatedGeofence)
    setDefaultsSet(true)
  }

  const handleFieldChange = async (name, value) => {
    try {
      const updatedGeofence = { ...geofence, name: value }
      await validateForm2(
        updatedGeofence,
        name,
        setValidationErrors,
        geofenceSchema
      )
      setGeofence((prevGeofence) => ({
        ...prevGeofence,
        [name]: value,
        ...(name === 'type' && { type: value })
      }))
      console.log(updatedGeofence)
    } catch (error) {
      // No need to update validationErrors here, as it's done in the validateField function
    }
  }

  // const handleFieldChange = async (name, value) => {
  //   try {
  //     setGeofence((prevGeofence) => {
  //       const updatedGeofence = { 
  //         ...prevGeofence, 
  //         [name]: value 
  //       };
  //       return updatedGeofence;
  //     });
  
  //     await validateForm2(
  //       { ...geofence, [name]: value },
  //       name,
  //       setValidationErrors,
  //       geofenceSchema
  //     );
  //   } catch (error) {
  //     console.error('Error updating field:', error);
  //   }
  // };

  const handleColorChange = (newColor) => {
    setGeofence((prevState) => ({
      ...prevState,
      color: newColor
    }))
  }

  const handleMapChange = (value, type) => {
    if (type === 'CIRCLE') {
      setGeofence((prevState) => ({
        ...prevState,
        type: type,
        radius: value.radius,
        center: value.center,
        coordinates: [
          {
            lat: 0,
            lng: 0
          }
        ]
      }))
    } else if (type === 'LINE') {
      setGeofence((prevState) => ({
        ...prevState,
        type: type,
        coordinates: value
      }))
    } else {
      setGeofence((prevState) => {
        if (!Array.isArray(value) || value.length === 0) {
          return prevState
        }
        const firstItem = value[0]
        const newCoordinates = [...value, firstItem]

        return {
          ...prevState,
          type: type,
          coordinates: newCoordinates
        }
      })
    }
    setValidateCoordinates(true)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const isValid = await validateForm(
        geofence,
        setValidationErrors,
        geofenceSchema
      )
      if (!isValid) {
        console.error('Form validation failed')
        setIsLoading(false)
        return
      }

      let response

      if (id === undefined) {
        response = await createGeofence(geofence)
        handleResponse(response, 'Geofence created successfully!', 'Card')
      } else {
        response = await updateGeofence(geofence)
        handleResponse(response, 'Geofence updated successfully!', 'Card')
      }
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0)
    if (response) {
      setSuccessMessage(successMessage)
      setTimeout(() => {
        if (mode === 'Card') {
          navigate(getRoute('Geofence', mode))
        }
      }, 3000) // Closing parentheses and braces for setTimeout
    }
  }
  const handleError = (error) => {
    window.scrollTo(0, 0)
    if (error.name === 'ValidationError') {
      handleValidationErrors(error)
    } else {
      setErrors(error.error)
    }
  }

  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message
      return acc
    }, {})
    setValidationErrors(errors)
  }

  const handleClose = () => {
    setErrors([])
    setSuccessMessage('')
  }

  const handleBackClick = () => {
    navigate(-1);
    };

  // Check if id is undefined and defaultSet is true
  if (id === undefined && !defaultsSet) {
    return null // or any other fallback UI you want to show
  }

  return (
    <StyledFormContainer>
      <ErrorMessage1 errors={errors} onClose={handleClose} />
      <SuccessMessage message={successMessage} onClose={handleClose} />
      {isLoading && <Loader />}
      <StyledHeader2>
        <Grid item xs={12}>
          <Title item><IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
              <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
            </IconButton>{id ? 'Update' : 'Create'} Geofence</Title>
        </Grid>
      </StyledHeader2>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">General parameters</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormField
            label="Name"
            required
            placeholder="Enter name"
            value={geofence.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            error={!!validationErrors['name']}
            errorMessage={validationErrors['name']}
          />

          <FormFieldMultiline
            label="Description"
            required
            placeholder="Enter description"
            value={geofence.description}
            onChange={(e) => handleFieldChange('description', e.target.value)}
            multiline
            error={!!validationErrors['description']}
            errorMessage={validationErrors['description']}
          />
          <StyledInputControlContainer>
            <Typography item variant="label">
              Pick Any Color in Hex Value *
            </Typography>
            {/* <FormColorPicker
            label="Pick Any Color in Hex Value"
            required
            defaultColor={geofence.color}
            onFormValueChange={handleColorChange}
            error={!!validationErrors.color}
            errorMessage={validationErrors.color} 
          /> */}
            <ColorPicker
              defaultColor={geofence.color}
              onFormValueChange={handleColorChange}
            />
            {validationErrors?.color && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '5px'
                }}
              >
                <img
                  src={iconMap['ALERTICON']}
                  alt="alertIcon"
                  style={{
                    marginRight: '5px',
                    width: '25px',
                    height: '25px'
                  }}
                />
                <Typography sx={{ fontSize: '16px', color: '#e71111' }}>
                  {validationErrors.color}
                </Typography>
              </div>
            )}
          </StyledInputControlContainer>
          <FormOptionSelector
            label="Geofence Type"
            required
            options={geofenceTypes}
            selectedOption={geofence.type}
            handleOptionChange={(value) => handleFieldChange('type', value)}
            error={!!validationErrors.type}
            errorMessage={validationErrors.type}
          />
        </StyledSectionRight>
      </StyledFormSection>
      <Grid sx={{ marginTop: '32px' }}>
        {validationErrors.coordinates && (
          <FormError errorMessage={validationErrors.coordinates} />
        )}
        {validationErrors.radius && (
          <FormError errorMessage={validationErrors.radius} />
        )}
        <GoogleMaps
          onChange={handleMapChange}
          coordinates={geofence.coordinates}
          type={geofence.type}
          radius={geofence.radius}
          center={geofence.center}
          id={id}
        />
      </Grid>
      <Grid
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
      >
        <Grid sx={{ mt: 2 }}>
          <NextButton onClick={handleSubmit}>SAVE</NextButton>
        </Grid>
      </Grid>
    </StyledFormContainer>
  )
}

export default GeofencesForm
