import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  StyledFormContainer1,
  StyledFormSection1,
  StyledSectionHeader
} from '../../../../styledComponents'
import { Grid, Typography } from '@mui/material'
import FormSelect from '../../../common/FormSelect'
import { fetchCustomersByAccountId } from '../../../../services/customerNew.service'
import FormField from '../../../common/FormField'
import FormPhone from '../../../common/FormPhone'
import FormAddress2 from '../../../common/FormAddress2'
import { ConsigneeModel } from '../../../../model/consignee.model'
// import { display } from 'html2canvas/dist/types/css/property-descriptors/display';

const ConsignmentsForm1 = ({
  consignment,
  onFormValueChange,
  validationErrors
}) => {
  const [customers, setCustomers] = useState([ConsigneeModel])
  const [selectedCustomer, setSelectedCustomer] = useState(ConsigneeModel)

  useEffect(() => {
    // Retrieve the state from local storage when the component mounts
    const savedCustomer = localStorage.getItem('selectedCustomer')
    if (savedCustomer) {
      setSelectedCustomer(JSON.parse(savedCustomer))
    }
  }, [])

  useEffect(() => {
    const _fetchCustomersByAccountId = async () => {
      try {
        const data = await fetchCustomersByAccountId(1, 50)
        setCustomers(data.rows)

        // Check if consignment.deliveryDetails.addressId exists
        if (
          consignment.deliveryDetails &&
          consignment.deliveryDetails.addressId
        ) {
          // Find the customer who has the addressId
          const customer = data.rows.find((c) =>
            c.address.some(
              (a) => a._id === consignment.deliveryDetails.addressId
            )
          )
          if (customer) {
            setSelectedCustomer(customer)
          }
        }
      } catch (error) {
        console.error('Error fetching customers:', error)
      }
    }
    _fetchCustomersByAccountId()
  }, [consignment.deliveryDetails])

  const splitPhoneNumber = (value) => {
    const phoneNumberParts = value.split(' ')
    return {
      areaCode: phoneNumberParts[0],
      phone: phoneNumberParts.slice(1).join(' ')
    }
  }
  const handleFieldChange = async (name, value) => {
    try {
      let updatedConsignment = { ...consignment }

      // Check if the field name starts with 'pickupDetails.address.'
      if (name.startsWith('pickupDetails.address.')) {
        const addressField = name.split('pickupDetails.address.')[1]
        updatedConsignment.pickupDetails.address[addressField] = value
        if (
          updatedConsignment.pickupDetails.address.addressType === 'Address'
        ) {
          if (updatedConsignment.pickupDetails.address.geofenceId != null) {
            handleFieldChange('pickupDetails.address.geofenceId', null)
          }
        }
        if (
          updatedConsignment.pickupDetails.address.addressType === 'Geofence'
        ) {
          if (
            updatedConsignment.pickupDetails.address.streetOne != null &&
            updatedConsignment.pickupDetails.address.streetOne != ''
          ) {
            handleFieldChange('pickupDetails.address.streetOne', null)
          }
          if (
            updatedConsignment.pickupDetails.address.streetTwo != null &&
            updatedConsignment.pickupDetails.address.streetTwo != ''
          ) {
            handleFieldChange('pickupDetails.address.streetTwo', null)
          }
          if (
            updatedConsignment.pickupDetails.address.city != null &&
            updatedConsignment.pickupDetails.address.city != ''
          ) {
            handleFieldChange('pickupDetails.address.city', null)
          }
          if (
            updatedConsignment.pickupDetails.address.state != null &&
            updatedConsignment.pickupDetails.address.state != ''
          ) {
            handleFieldChange('pickupDetails.address.state', null)
          }
          if (
            updatedConsignment.pickupDetails.address.country != null &&
            updatedConsignment.pickupDetails.address.country != ''
          ) {
            handleFieldChange('pickupDetails.address.country', null)
          }
          if (
            updatedConsignment.pickupDetails.address.postalCode != null &&
            updatedConsignment.pickupDetails.address.postalCode != ''
          ) {
            handleFieldChange('pickupDetails.address.postalCode', null)
          }
        }
      } else if (name === 'pickupDetails.name') {
        updatedConsignment.pickupDetails = {
          ...updatedConsignment.pickupDetails,
          name: value
        }
      } else if (name === 'pickupDetails.phone') {
        const { phone, areaCode } = splitPhoneNumber(value)
        updatedConsignment.pickupDetails = {
          ...updatedConsignment.pickupDetails,
          phone: phone,
          areaCode: areaCode
        }
      } else if (name === 'deliveryDetails.addressId') {
        updatedConsignment.deliveryDetails = {
          ...updatedConsignment.deliveryDetails,
          addressId: value._id
        }
      } else if (name === 'deliveryDetails.name') {
        updatedConsignment.deliveryDetails = {
          ...updatedConsignment.deliveryDetails,
          name: value
        }
      } else if (name === 'deliveryDetails.phone') {
        const { phone, areaCode } = splitPhoneNumber(value)
        updatedConsignment.deliveryDetails = {
          ...updatedConsignment.deliveryDetails,
          phone: phone,
          areaCode: areaCode
        }
      } else if (name === 'customerId') {
        setSelectedCustomer(value)
        // Save the state to local storage
        localStorage.setItem('selectedCustomer', JSON.stringify(value))
        updatedConsignment[name] = value.id
        // await _getCustomerById(value);
      } else {
        updatedConsignment[name] = value
      }
      onFormValueChange(updatedConsignment, name, value, 0) // Pass step index here
    } catch (error) {
      console.error('Error in handleFieldChange:', error)
    }
  }

  return (
    <StyledFormContainer1>
      <StyledFormSection1>
        <Grid>
          <FormSelect
            label="Customer Name"
            disabled={consignment.id !== undefined}
            value={consignment.customerId}
            required
            onChange={(selected) => {
              handleFieldChange('customerId', selected)
            }}
            options={customers.map((item) => ({
              value: item.id,
              label: item.name,
              item
            }))}
            error={!!validationErrors.customerId}
            errorMessage={validationErrors.customerId}
          />
        </Grid>
        <StyledSectionHeader>
          <Typography variant="heading">Pickup Address</Typography>
        </StyledSectionHeader>
        <Grid>
          {((consignment?.id !== undefined && consignment?.accountId) ||
            consignment?.id == undefined) && (
            <FormAddress2
              address={consignment.pickupDetails.address}
              addressKey="-1"
              handleFieldChange={(name, value) =>
                handleFieldChange('pickupDetails.address.' + name, value)
              }
              validationErrors={validationErrors}
            />
          )}
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormField
                label="Pickup Person Name"
                required
                placeholder="Enter Pickup Person Name"
                value={consignment.pickupDetails?.name}
                onChange={(e) =>
                  handleFieldChange('pickupDetails.name', e.target.value)
                }
                error={!!validationErrors['pickupDetails.name']}
                errorMessage={validationErrors['pickupDetails.name']}
              />
            </Grid>
            <Grid item xs={6}>
              <FormPhone
                label="Phone"
                value={
                  consignment.pickupDetails.areaCode +
                  ' ' +
                  consignment.pickupDetails.phone
                }
                onChange={(newValue) =>
                  handleFieldChange('pickupDetails.phone', newValue)
                }
                required
                error={!!validationErrors['pickupDetails.phone']}
                errorMessage={validationErrors['pickupDetails.phone']}
              />
            </Grid>
          </Grid>
        </Grid>
      </StyledFormSection1>
      <Grid>
        <StyledFormSection1>
          <StyledSectionHeader>
            <Typography variant="heading">Delivery Address</Typography>
          </StyledSectionHeader>
          <Grid>
            <FormSelect
              label="Delivery Address"
              value={consignment.deliveryDetails.addressId}
              required
              onChange={(selected) => {
                handleFieldChange('deliveryDetails.addressId', selected)
              }}
              options={selectedCustomer.address.map((item) => ({
                value: item._id,
                label:
                  item.geofenceId ||
                  item.streetOne +
                    ', ' +
                    item.streetTwo +
                    ', ' +
                    item.city +
                    ', ' +
                    item.state +
                    ', ' +
                    item.country +
                    ', ' +
                    item.postalCode,
                item
              }))}
              error={!!validationErrors['deliveryDetails.addressId']}
              errorMessage={validationErrors['deliveryDetails.addressId']}
            />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormField
                  label="Delivery Person Name"
                  required
                  placeholder="Enter Delivery Person Name"
                  value={consignment.deliveryDetails?.name}
                  onChange={(e) =>
                    handleFieldChange('deliveryDetails.name', e.target.value)
                  }
                  error={!!validationErrors['deliveryDetails.name']}
                  errorMessage={validationErrors['deliveryDetails.name']}
                />
              </Grid>
              <Grid item xs={6}>
                <FormPhone
                  label="Phone"
                  value={
                    consignment.deliveryDetails?.areaCode +
                    ' ' +
                    consignment.deliveryDetails?.phone
                  }
                  onChange={(newValue) =>
                    handleFieldChange('deliveryDetails.phone', newValue)
                  }
                  required
                  error={!!validationErrors['deliveryDetails.phone']}
                  errorMessage={validationErrors['deliveryDetails.phone']}
                />
              </Grid>
            </Grid>
          </Grid>
        </StyledFormSection1>
      </Grid>
    </StyledFormContainer1>
  )
}

ConsignmentsForm1.propTypes = {
  consignment: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired
}

export default ConsignmentsForm1
