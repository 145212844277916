/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
} from "@mui/material";
import BillingPlansForm2 from "./BillingPlansForm2";
import { billingPlanModel } from "../../../../model/billingPlan.model";
import { ServiceModel } from "../../../../model/services.model";
import { useNavigate, useParams } from "react-router-dom";
import { Title } from "../styles/BillingPlansStyles";
import { fetchAllServices } from "../../../../services/serviceNew.services";
import {
  createBillingPlan,
  getBillingPlanById,
  updateBillingPlan,
} from "../../../../services/billingPlanNew.service";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import SuccessMessage from "../../../common/SuccessMessage";
import { createTheme } from "@mui/system";
import BillingPlansForm1New from "./BillingPlansForm1";
import {
  billingPlanSchema,
  billingPlanValidationSchemaStep1,
  billingPlanValidationSchemaStep2,
} from "../../../../utils/validation-schemas/billingPlansSchema";
import { validateForm, validateForm2 } from "../../../../utils/yupValidations";
import Loader from "../../../common/Loader";
import { appendFormData } from "../../../../utils/functions";
import { BackButton, NextButton } from "../../../../styledComponents";
import { getRoute } from "../../../../constants/routes";
import { iconMap } from "../../../../constants/iconsList";

const BillingPlansFormNew = () => {
  const { id } = useParams();
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [billingPlan, setBillingPlan] = useState(billingPlanModel);
  const [services, setServices] = useState(ServiceModel);
  const [allServices, setAllServices] = useState([]);
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const steps = ["Plan Details", "Access and Limits"];

  const validationSchemas = {
    0: billingPlanValidationSchemaStep1,
    1: billingPlanSchema,
  };

  useEffect(() => {
    fetchServices();
    if (id) {
      _getBillingPlanById(id);
    }
  }, [id]);

  useEffect(() => {
    updateServicesLimit();
  }, [billingPlan]);

  useEffect(() => {
    // Create a copy of billingPlan.servicesLimit
    const updatedServicesLimit = [...billingPlan.servicesLimit];

    // Iterate over allServices
    allServices.forEach((service) => {
      // Check if the service is not present in billingPlan.servicesLimit
      if (
        !updatedServicesLimit.find((s) => s.serviceName === service.serviceName)
      ) {
        // If not present, add it to updatedServicesLimit
        updatedServicesLimit.push(service);
      }
    });
    // Update the billingPlan with the updated servicesLimit
    setBillingPlan((prevPlan) => ({
      ...prevPlan,
      servicesLimit: updatedServicesLimit,
    }));
  }, [allServices]);

  const fetchServices = async () => {
    try {
      const data = await fetchAllServices();
      setServices(data.rows);

      // Create an array to hold the updated servicesLimit
      const updatedServicesLimit = [];

      // Loop through the data.rows to create servicesLimit objects array
      data.rows.forEach((service) => {
        updatedServicesLimit.push({
          serviceId: service.id,
          serviceName: service.name,
          limit: -1,
          value: false,
          cost: service.amount,
          type: service.type,
          fieldType: service.fieldType,
          days: service.days,
          frequency: service.frequency,
          MB: service.MB,
        });
      });
      // Update the billingPlan state once after the loop
      setBillingPlan({
        ...billingPlan,
        servicesLimit: updatedServicesLimit,
      });
    } catch (error) {
      handleError(error);
      // handleFetchError(error);
    }
  };

  const _fetchAllServices = async () => {
    try {
      const data = await fetchAllServices();
      setAllServices(data.rows);

      // Create an array to hold the updated servicesLimit
      const updatedServicesLimit = [];

      // Loop through the data.rows to create servicesLimit objects array
      data.rows.forEach((service) => {
        updatedServicesLimit.push({
          serviceId: service.id,
          serviceName: service.name,
          limit: -1,
          value: false,
          cost: service.amount,
          type: service.type,
          fieldType: service.fieldType,
          days: service.days,
          frequency: service.frequency,
          MB: service.MB,
        });
      });
      // Update the billingPlan state once after the loop
      setAllServices(updatedServicesLimit);
    } catch (error) {
      handleError(error);
      // handleFetchError(error);
    }
  };

  const updateServicesLimit = async () => {
    try {
      // Create an array to hold the updated servicesLimit
      const updatedServicesLimit = billingPlan.servicesLimit;

      updatedServicesLimit.forEach((serviceLimit) => {
        const matchedServices = Array.isArray(services)
          ? services.filter((service) => service.id === serviceLimit.serviceId)
          : [];
        if (matchedServices.length > 0) {
          matchedServices.forEach((matchedService) => {
            serviceLimit.fieldType = matchedService.fieldType;
          });
        } else {
          // console.log(`No service found for id: ${serviceLimit.serviceId}`);
        }
      });
      const updatedServicesLimit1 = [...billingPlan.servicesLimit];

      // Iterate over allServices
      allServices.forEach((service) => {
        // Check if the service is not present in billingPlan.servicesLimit
        if (
          !updatedServicesLimit1.find(
            (s) => s.serviceName === service.serviceName
          )
        ) {
          // If not present, add it to updatedServicesLimit
          updatedServicesLimit1.push(service);
        }
      });
      // Update the billingPlan state once after the loop
      setBillingPlan({
        ...billingPlan,
        servicesLimit: [...updatedServicesLimit1],
      });
    } catch (error) {
      handleError(error);
      // handleFetchError(error);
    }
  };

  const _getBillingPlanById = async (id) => {
    setIsLoading(true);
    try {
      const data = await getBillingPlanById(id);
      // await updateServicesLimit();
      setBillingPlan(data);
      _fetchAllServices();
      handleResponse(data, "Billing Plan fetched successfully!", "Get");
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleValueChange = async (
    updatedBillingPlan,
    fieldName,
    fieldValue,
    step
  ) => {
    setBillingPlan((prevBillingPlan) => ({
      ...prevBillingPlan,
      ...updatedBillingPlan,
    }));

    const schema = validationSchemas[step];
    try {
      await validateForm2(
        updatedBillingPlan,
        fieldName,
        setValidationErrors,
        schema
      );
    } catch (error) {
      handleError(error);
    }
  };

  const handleNext = async () => {
    try {
      console.log("step", activeStep);
      const schema = validationSchemas[activeStep];
      if (schema) {
        const isValid = await validateForm(
          billingPlan,
          setValidationErrors,
          schema
        );
        console.log("step2", billingPlan);
        if (isValid) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          console.error("Form validation failed");
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // const handleResponse = (response, successMessage) => {
  //   if (response) {
  //     setSuccessMessage(successMessage)
  //     setErrorMessage('')
  //     setTimeout(() => navigate('/management/billing-plans'), 3000)
  //   }
  // }

  // const handleError = (error) => {
  //   if (error.name === 'ValidationError') {
  //     handleValidationErrors(error)
  //   } else {
  //     setErrorMessage(error.message)
  //   }
  // }

  // const handleValidationErrors = (error) => {
  //   const errors = error?.inner?.reduce((acc, err) => {
  //     acc[err.path] = err.message
  //     return acc
  //   }, {})
  //   setValidationErrors(errors)
  // }

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const schema = validationSchemas[1];
      const isValid = await validateForm(
        billingPlan,
        setValidationErrors,
        schema
      );
      if (!isValid) {
        console.error("Form validation failed");
        setIsLoading(false);
        return;
      }

      let response;
      if (id === undefined) {
        response = await createBillingPlan(billingPlan);
        handleResponse(response, "Billing Plan created successfully!", "Card");
      } else {
        response = await updateBillingPlan(billingPlan);
        handleResponse(response, "Billing Plan updated successfully!", "Card");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
      setTimeout(() => {
        if (mode === "Card") {
          navigate(getRoute("Billing Plan", mode));
        }
      }, 3000); // Closing parentheses and braces for setTimeout
    }
  };

  const handleError = (error) => {
    window.scrollTo(0, 0);
    if (error.name === "ValidationError") {
      handleValidationErrors(error);
    } else {
      setErrors(error.error);
    }
  };

  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message;
      return acc;
    }, {});
    setValidationErrors(errors);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <BillingPlansForm1New
            billingPlan={billingPlan}
            onFormValueChange={handleValueChange}
            validationErrors={validationErrors}
            validationSchema={billingPlanValidationSchemaStep1}
            setValidationErrors={setValidationErrors}
          />
        );
      case 1:
        return (
          <BillingPlansForm2
            billingPlan={billingPlan}
            services={services}
            onFormValueChange={handleValueChange}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  };

  const getStepColor = (index) => {
    if (index < activeStep) {
      return "#26861D"; // Completed step color
    } else if (index === activeStep) {
      return "#0772b8"; // Active step color
    } else {
      return "#EEEEF0"; // Inactive step color
    }
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Box>
      <ErrorMessage1 errors={errors} onClose={handleClose} />
      <SuccessMessage message={successMessage} onClose={handleClose} />
      {isLoading && <Loader />}
      <Box sx={{ width: "100%", padding: "Calc(2.3%-50px) Calc(10.8%-50px)" }}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #d8d8df",
            paddingBottom: "26.5px",
          }}
        >
          <Grid xs={12} md={5}>
            <Title item>
              <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
                <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
              </IconButton>{" "}
              {id ? "Update" : "Create"} Billing Plan
            </Title>
          </Grid>
          <Grid item xs={12} md={7} sx={{ position: "relative", right: "8px" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step
                  key={label}
                  sx={{
                    "& .MuiStepIcon-root": {
                      height: "32px",
                      width: "32px",
                      color: getStepColor(index),
                    },
                    "& .MuiStepIcon-root.Mui-active": {
                      color: "#0772B8", // Active step color
                    },
                    "& .MuiStepIcon-text": {
                      fill: "#5B5B6E", // Change this to your desired number color
                    },
                    "& .MuiStepIcon-root.Mui-active .MuiStepIcon-text": {
                      fill: "#FFFFFF",
                    },
                  }}
                >
                  <StepLabel
                    // StepIconComponent={StepIcon}
                    // StepIconProps={{
                    //     active: index === activeStep,
                    //   }}
                    optional={
                      <Typography
                        style={{
                          fontWeight: index === activeStep ? 600 : 400,
                          color: "#0F446B",
                          fontSize: "16px",
                        }}
                      >
                        {label}
                      </Typography>
                    }
                  ></StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          {/* <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', color: 'red' }}>
              <Typography>{errorMessage}</Typography>
            </Grid> */}
        </Grid>

        <Box sx={{ marginTop: 3, marginBottom: 3 }}>
          {activeStep === steps.length ? (
            <Box>
              <Typography sx={{ mt: 2 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          ) : (
            <Box>
              {getStepContent(activeStep, validationErrors)}
              <Grid
                xs={12}
                sx={{
                  mt: 3,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <BackButton disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </BackButton>
                <NextButton
                  onClick={
                    activeStep === steps.length - 1 ? handleSubmit : handleNext
                  }
                >
                  {activeStep === steps.length - 1
                    ? "FINISH"
                    : "SAVE & CONTINUE"}
                </NextButton>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BillingPlansFormNew;
