import * as yup from "yup";

// SimCards schema
export const simCardsSchema = yup.object().shape({
  areaCode: yup.string().required("Area code is required."),
  simCardNumber: yup
    .string()
    .matches(/^\+?(?:[0-9] ?){7,14}[0-9]$/, "Invalid phone number format.") ///^\+(?:[0-9] ?){6,14}[0-9]$/
    .required("SIM card number is required."),
  imeiNumber: yup
    .string()
    .trim()
    .min(18, "IMEI number should be exactly 18 characters.")
    .max(18, "IMEI number should be exactly 18 characters.")
    .required("IMEI number is required."),
  iccdNumber: yup
    .string()
    .trim()
    .nullable()
    .test(
      "is-valid-length",
      "ICCD number should be between 18 and 22 characters.",
      (value) => {
        return value ? value.length >= 18 && value.length <= 22 : true;
      }
    ),
  mssidnNumber: yup
    .string()
    .nullable()
    .matches(/^\+?(?:[0-9] ?){7,14}[0-9]$/, "Invalid phone number format."), ///^\+(?:[0-9] ?){6,14}[0-9]$/
    APN: yup
    .string()
    .trim()
    .min(3, "APN should be not be less than 3 characters.")
    .max(100, "APN should not be more than 100 characters.")
    .required("IMEI number is required."),
    APNUsername: yup
    .string()
    .trim(),
    APNPassword: yup
    .string()
    .trim(),
  createdBy: yup.string().nullable(),
  updatedBy: yup.string().nullable(),
});
