import React from "react";
import AddIcon from "@mui/icons-material/Add";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
// Import your SVG images
import ManagementIcon from "../assets/icons/managementIcon.svg";
import MonitoringIcon from "../assets/icons/monitoringIcon.svg";
import ManagementIcon2 from "@mui/icons-material/Dashboard";
import MonitoringIcon2 from "@mui/icons-material/Dashboard";

// eslint-disable-next-line no-undef
export const BASE_URL = process.env.REACT_APP_BASE_URL;
// eslint-disable-next-line no-undef
export const MASTER_KEY = process.env.REACT_APP_MASTER_KEY;

export const engineModelTypes = [
  { name: "Diesel engine", value: "Diesel engine", control: "engineModel" },
  { name: "Gasoline engine", value: "Gasoline engine", control: "engineModel" },
  { name: "Radial engine", value: "Radial engine", control: "engineModel" },
  { name: "Rotary engine", value: "Rotary engine", control: "engineModel" },
];

export const documentTypeOptions = ["Insurance", "Customs", "Other"];

export const otherDocumentTypeOptions = [
  "License",
  "Road Permit",
  "Invoice",
  "Delivery Note",
  "Other",
];

export const addressTypes = [
  { name: "Geofenced Area", value: "Geofence" },
  { name: "Add Address Manually", value: "Address" },
];

// Driver measurement types
export const checkboxTypes = [
  { name: "Metric", value: "Metric" },
  { name: "US", value: "US" },
  { name: "Imperial", value: "Imperial" },
  { name: "Metric with gallons", value: "Metric with gallons" },
];

//Geofence types
export const geofenceTypes = [
  { name: "POLYGON", value: "POLYGON" },
  { name: "CIRCLE", value: "CIRCLE" },
];

export const serviceCheckboxTypes = [
  { name: "Management", value: "Management", control: "type" },
  { name: "Monitoring", value: "Monitoring", control: "type" },
  { name: "General", value: "General", control: "type" },
  { name: "Sensors", value: "Sensors", control: "type" },
  { name: "Features", value: "Features", control: "type" },
  { name: "Reports", value: "Reports", control: "type" },
  { name: "Alerts", value: "Alerts", control: "type" },
];

export const fieldTypeCheckboxTypes = [
  { name: "Checkbox", value: "Checkbox", control: "fieldType" },
  { name: "Amount", value: "Amount", control: "fieldType" },
  { name: "Limit", value: "Limit", control: "fieldType" },
  { name: "Days", value: "Days", control: "fieldType" },
  { name: "MB", value: "MB", control: "fieldType" },
  { name: "Frequency", value: "Frequency", control: "fieldType" },
];

export const frequencyTypeCheckboxTypes = [
  { name: "Daily", value: "Daily", control: "frequency" },
  { name: "Weekly", value: "Weekly", control: "frequency" },
  { name: "Monthly", value: "Monthly", control: "frequency" },
];

export const reportTypes = [
  { name: "Driver Run Sheets", value: "Driver Run Sheets", control: "Driver Run Sheets" },
  { name: "Environmental Report", value: "Environmental Report", control: "Environmental Report" },
  { name: "Monitoring Report", value: "Monitoring Report", control: "Monitoring Report" },
];

export const driverRunSheetData = {
  date: "25-10-2024",
  driverName: "Naman",
  vehicleRegistrationNumber: "XOJ773D",
  startShiftTime: "7:00 AM",
  endShiftTime: "5:30 PM",
  totalHours: "10:48:33",
  startBreak1: "12:00 PM",
  endBreak1: "12:30 PM",
  startBreak2: "",
  endBreak2: "",
  startBreak3: "",
  endBreak3: "",
  startBreak4: "",
  endBreak4: "",
  fleetName: "Easy Logistics PTY LTD",
  consignments: [
    {
      consignmentNo: "15839974",
      customerName: "Coca Cola",
      Address: "TRUGANINA",
      pickupAt: "7:00 AM",
      loadingCompletedAt: "7:30 AM",
      deliveredAt: "8:10 AM",
      tripStatus: "Delivered",
      signature: "Signed",
      receivedBy: "FXYP",
    },
    {
      consignmentNo: "CCC030588332",
      customerName: "Sundher",
      Address: "SOUTH GEELONG",
      pickupAt: "9:00 AM",
      loadingCompletedAt: "9:15 AM",
      deliveredAt: "9:45 AM",
      tripStatus: "Delivered",
      signature: "Signed",
      receivedBy: "Faf",
    },
    // Add more consignment objects here as needed...
  ],
};

export const currencyOptions = ["TZS", "USD"];
export const envReportTableColumns = [
  "DEVICEID",
  "DISTANCE (KM)",
  "FUEL CONSUMPTION (KM/L)",
  "CARBON DIOXIDE (KG)",
  "CO (KG)",
  "NOX (KG)",
  "PM (KG)",
  "HC (KG)"
];
export const reportTableColumns = [
  "DEVICE ID",
  "DATE & TIME",
  "DIRECTION",
  "GPS STATUS",
  "IGNITION STATUS",
  "LOCK STATUS",
  "MOTOR STATUS",
  "LOCK WIRE STATUS",
  "SIM STATUS",
  "MILEAGE",
  "SATELLITE",
  "LOW BATTERY ALARM",
  "FUEL STOLEN ALARM",
  "FUEL SENSOR DISCONNECT ALARM",
  "HARSH ACCELERATION",
  "HARSH TURNING",
  "IDLE ALARM",
  "LOCK WIRE CUT ALARM",
  "LOW SPEED ALARM",
  "MOTOR STUCK ALARM",
  "OVER SPEED ALARM",
  "OVER SPEED STATUS",
  "SHAKE VIBRATE ALARM",
];

export const presetColors = [
  "#BF8CF2",
  "#F28C8C",
  "#F2C38C",
  "#F2EE8C",
  "#8CF29C",
  "#8CE0F2",
];
export const TripStatus = [
  { name: "Received", value: "RECEIVED" },
  { name: "Unit Allocated", value: "UNIT_ALLOCATION" },
  { name: "Driver Allocated", value: "DRIVER_ALLOCATION" },
  { name: "Driver Accepted", value: "DRIVER_ACCEPTED" },
  { name: "Driver Declined", value: "DRIVER_DECLINED" },
  { name: "Start", value: "START" },
  { name: "At Pickup Location", value: "AT_PICKUP" },
  { name: "Loading Completed", value: "LOADING_DONE" },
  { name: "EnRoute", value: "ENROUTE" },
  { name: "Arrived at Destination", value: "ARRIVED" },
  { name: "Delivered", value: "DELIVERED" },
];

export const ServiceType = [
  { name: "Management", value: "Management" },
  { name: "Monitoring", value: "Monitoring" },
  { name: "General", value: "General" },
  { name: "Administrative", value: "Administrative" },
  { name: "Sensors", value: "Sensors" },
  { name: "Features", value: "Features" },
  { name: "Reports", value: "Reports" },
  { name: "Alerts", value: "Alerts" },
];

export const checkBoxValues = [
  "Amicfs",
  "blueaxix",
  "Caromedo",
  "CoffeeBoard",
  "Dcgcargo",
  "ForwardAir",
  "Hbcargo",
  "Redlog",
  "Tanzania Coffee",
];
export const navItems = [
  { name: "Dashboard", path: "/dashboard" },
  { name: "Billing Plans", path: "/billing-plans" },
  { name: "Accounts", path: "/accounts" },
  { name: "Users", path: "/users" },
  { name: "Units", path: "/units" },
  { name: "Unit Groups", path: "/unit-groups" },
  { name: "Devices", path: "/devices" },
  { name: "Services", path: "/services" },
];

// Document Columns
export const documentColumns = [
  { label: "ATTACHMENTS", xs: 4 },
  { label: "DOCUMENT TYPE", xs: 3 },
  { label: "DRIVER", xs: 2 },
  { label: "CUSTOMER", xs: 2 },
  { label: "", xs: 1 },
];
export const billingPlanColumn = [
  "NAME",
  "ACCESS",
  "LIMITS",
  "FEATURES",
  "ALERTS",
  "REPORTS",
  "PRICING CYCLE",
  "ADD-ONS",
  "ACCOUNTS",
  "",
];
export const billingPlanColumnNew = [
  "NAME",
  "ACCESS",
  "LIMITS",
  "ALERTS",
  "REPORTS",
  "PRICING CYCLE",
  "FEATURES",
  "",
];
export const serviceTableColumnNew = ["NAME", "SERVICE TYPE", "FIELD TYPE", ""];
export const roleTableColumnNew = ["NAME", ""];
export const simCardColumn = [
  "SIMCARD NUMBER",
  "ACCOUNT",
  "IMEI",
  "ICCD",
  "MSSIDN",
  "",
];
export const simCardTableColumnNew = [
  "SIMCARD NUMBER",
  "ACCOUNT",
  "IMEI",
  "ICCD",
  "MSSIDN",
  "",
];
export const usersTableColumnNew = [
  "USER NAME",
  "STATUS",
  "NAME",
  "ACCOUNT",
  "ROLE",
  "",
];
export const geofenceTableColumnNew = [
  "NAME",
  "RADIUS",
  "CENTER",
  "DESCRIPTION",
  "",
];
export const driverTableColumnNew = [
  "USER NAME",
  "NAME",
  "EMAIL",
  "PHONE",
  "LICENSE NUMBER",
  "LICENSE EXPIRY DATE",
  "PASSPORT NUMBER",
  "PASSPORT EXPIRY DATE",
  "",
];
export const consignmentTableColumnNew = [
  "CUSTOMER",
  "UNIT",
  "DRIVER",
  "PICKUP LOCATION",
  "DELIVERY LOCATION",
  "SPECIAL INSTRUCTIONS",
  "",
];
export const customerTableColumnNew = ["NAME", "PHONE", "EMAIL", "ADDRESS", ""];
export const serviceType = [
  "General",
  "Management",
  "Monitoring",
  "Administrative",
  "Sensors",
];
export const serviceFieldType = ["Checkbox", "Amount", "Limit"];
export const userColumn = [
  "NAME",
  "STATUS",
  "ACCOUNTS",
  "ROLE",
  "LAST VISITED",
  "",
];
export const driverColumn = [
  "NAME",
  "STATUS",
  "EMAIL",
  "PHONE",
  "LICENSE NUMBER",
  "LICENSE EXPIRY DATE",
  "PASSPORT NUMBER",
  "PASSPORT EXPIRY DATE",
  "LAST VISITED",
  "",
];
export const geofenceColumn = [
  "NAME",
  "TYPE",
  "AREA",
  "PERIMETER",
  "RADIUS",
  "DESCRIPTION",
  "",
];
export const unitTableColumnNew = [
  "NAME",
  "UNIT TYPE",
  "VIN",
  "LICENSE PLATE",
  "MAKE",
  "MODEL",
  "YEAR",
  "DEVICE ID",
  "UNIQUE ID",
  "",
];
export const optimizedRoutesTable = [
  "NAME",
  "PICKUP ADDRESS",
  "DELIVERY ADDRESS",
];
// export const elockColumn = [
//   'NAME',
//   'STATUS',
//   'UNIT TYPE',
//   'LOCK ID',
//   'SERIAL NUMBER',
//   'MANUFACTURER',
//   'FIRMWARE VERSION',
//   'BATTERY LEVEL',
//   ''
// ]
export const unitElockColumn = ["NAME", ""];
export const paymentColumn = [
  "BILLING DATE",
  "LATE FEE",
  "AMOUNT",
  "PAYMENT MODE",
  "DESCRIPTION",
  "STATUS",
  "",
];
export const accountColumn = [
  "NAME",
  "PARENT ACCOUNT",
  "BILLING PLAN",
  "PRICING CYCLE",
  "START DATE",
  "RENEWAL DATE",
  "",
];
export const unitGroupTableColumnNew = ["NAME", "ACCOUNT NAME", "UNIT", ""];
export const deviceColumn = [
  "NAME",
  "STATUS",
  "UID",
  "MAKE",
  "MODEL",
  "SIM Card",
  "",
];
export const customerColumn = ["NAME", "PHONE", "EMAIL", "ADDRESS", ""];
export const consignmentColumn = [
  "NAME",
  "STATUS",
  "CONSIGNMENT ID",
  "UNIT",
  "DRIVER",
  "CARGO TYPE",
  "PICKUP ADDRESS",
  "DELIVERY ADDRESS",
  "PICKUP DATE",
  "DELIVERY DATE",
  "SPECIAL INSTRUCTION",
  "",
];
export const fuelType = ["Petrol", "Diesel"];
export const deviceType = [
  { name: "E-lock", value: "E-lock" },
  { name: "OBD Tracker/GPS", value: "OBD Tracker/GPS" },
  { name: "Wired GPS", value: "Wired GPS" }

];
export const measurementType = [
  { name: "Metric", value: "Metric", control: "measurementSystem" },
  { name: "US", value: "US", control: "measurementSystem" },
  { name: "Imperial", value: "Imperial", control: "measurementSystem" },
  {
    name: "Metric with gallons",
    value: "Metric with gallons",
    control: "measurementSystem",
  },
];
export const paymentFieldType = ["Nill", "yes"];
export const vehicleType = [
  { name: "Flatbed Truck", value: "Flatbed Truck", control: "vehicleType" },
  { name: "Box Truck", value: "Box Truck", control: "vehicleType" },
  {
    name: "Refrigerated Truck",
    value: "Refrigerated Truck",
    control: "vehicleType",
  },
  { name: "Tank Truck", value: "Tank Truck", control: "vehicleType" },
  { name: "Dump Truck", value: "Dump Truck", control: "vehicleType" },
  {
    name: "Curtain Side Truck",
    value: "Curtain Side Truck",
    control: "vehicleType",
  },
  { name: "Container Truck", value: "Container Truck", control: "vehicleType" },
  { name: "Tractor Truck", value: "Tractor Truck", control: "vehicleType" },
  { name: "Mixer Truck", value: "Mixer Truck", control: "vehicleType" },
  { name: "Cargo Van", value: "Cargo Van", control: "vehicleType" },
];
export const sensorType = [
  { name: "GPS", value: "GPS", control: "sensorType" },
  { name: "Fuel Level", value: "Fuel Level", control: "sensorType" },
  { name: "Temperature", value: "Temperature", control: "sensorType" },
  { name: "Speed", value: "Speed", control: "sensorType" },
  { name: "Odometer", value: "Odometer", control: "sensorType" },
];
export const lockType = [
  {
    name: "AT10M E Telematics Padlock",
    value: "AT10M E Telematics Padlock",
    control: "lockType",
  },
  {
    name: "Other E Lock Type",
    value: "Other E Lock Type",
    control: "lockType",
  },
];
export const cargoType = [
  { name: "General Cargo", value: "General Cargo", control: "cargoType" },
  {
    name: "Refrigerated Goods",
    value: "Refrigerated Goods",
    control: "cargoType",
  },
  { name: "Liquid Bulk", value: "Liquid Bulk", control: "cargoType" },
  { name: "Dry Bulk", value: "Dry Bulk", control: "cargoType" },
  { name: "Livestock", value: "Livestock", control: "cargoType" },
  { name: "Perishable Goods", value: "Perishable Goods", control: "cargoType" },
  { name: "Dangerous Goods", value: "Dangerous Goods", control: "cargoType" },
  { name: "Furniture", value: "Furniture", control: "cargoType" },
  {
    name: "Construction Materials",
    value: "Construction Materials",
    control: "cargoType",
  },
  { name: "Chemicals", value: "Chemicals", control: "cargoType" },
];
export const consignmentCargoType = [
  {
    name: "Container 20 feet",
    info: "Default sizes: 20ft container",
    value: "Container 20 feet",
  },
  {
    name: "Container 40 feet",
    info: "Default sizes: 40ft container",
    value: "Container 40 feet",
  },
  {
    name: "Pallet Standard",
    info: "(1165 x 1165mm)",
    value: "Pallet Standard",
  },
  { name: "Non Standard Pallets", value: "Non Standard Pallets" },
  { name: "Loose cargo", value: "Loose cargo" },
  { name: "Other type of goods", value: "Other type of goods" },
];

export const unitType = [
  { name: "Vehicle", value: "Vehicle" },
  { name: "Container", value: "Container" },
];

export const userStatus = [
  { name: "ACTIVE", value: "ACTIVE" },
  { name: "INACTIVE", value: "INACTIVE" },
];
export const driverStatus = [
  { name: "ACTIVE", value: "ACTIVE" },
  { name: "INACTIVE", value: "INACTIVE" },
];
export const unitStatus = [
  { name: "ACTIVATED", value: "ACTIVATED" },
  { name: "DEACTIVATED", value: "DEACTIVATED" },
];
export const unitGroupStatus = [
  { name: "ACTIVATED", value: "ACTIVATED" },
  { name: "DEACTIVATED", value: "DEACTIVATED" },
];
export const deviceStatus = [
  { name: "Provisioned", value: "Provisioned" },
  { name: "Unprovisioned", value: "Unprovisioned" },
];
export const roles = [
  { name: "System Administrator", value: "System Administrator" },
  { name: "Administrator", value: "Administrator" },
  { name: "Fleet Manager", value: "Fleet Manager" },
  { name: "Dispatcher", value: "Dispatcher" },
  { name: "Driver", value: "Driver" },
  { name: "Customer", value: "Customer" },
];

export const column = [
  {
    name: "",
  },
  {
    icon: <AddIcon />,
    name: "ADD",
  },
  {
    icon: <BorderColorIcon />,
    name: "EDIT",
  },
  {
    icon: <RemoveRedEyeIcon />,
    name: "VIEW",
  },
  {
    icon: <DeleteIcon />,
    name: "DELETE",
  },
];

export const userApi = `${BASE_URL}/users`;
export const driverApi = `${BASE_URL}/drivers`;
export const geofenceApi = `${BASE_URL}/geofence`;
export const accountApi = `${BASE_URL}/accounts`;
export const billingPlanApi = `${BASE_URL}/billingPlans`;
export const loginApi = `${BASE_URL}/users/login`;
export const forgetPasswordApi = `${BASE_URL}/users/forgetPassword`;
export const resetPasswordApi = `${BASE_URL}/users/resetPassword`;
export const serviceApi = `${BASE_URL}/services`;
export const deviceApi = `${BASE_URL}/devices`;
export const unitApi = `${BASE_URL}/units`;
export const roleApi = `${BASE_URL}/roles`;
export const unitGroupsApi = `${BASE_URL}/unitGroups`;
export const receivableApi = `${BASE_URL}/receivables`;
export const paymentApi = `${BASE_URL}/payments`;
export const notificationApi = `${BASE_URL}/notification`;
export const simCardApi = `${BASE_URL}/simCard`;
export const customerApi = `${BASE_URL}/customers`;
export const consignmentApi = `${BASE_URL}/consignments`;
export const logApi = `${BASE_URL}/logs`;
export const vehicleTypeApi = `${BASE_URL}/vehicleTypes`;
export const maintenanceCheckListApi = `${BASE_URL}/checkList`;
export const waypointApi = `${BASE_URL}/googleMaps`;
export const awsApi = `${BASE_URL}/aws`;
export const reportApi = `${BASE_URL}/reports`;
export const unitsMonitoringApi = `${BASE_URL}/unitsMonitoring`;
export const modules = [
  {
    name: "Management System",
    icon: ManagementIcon,
    icon2: <ManagementIcon2 />,
    ellipsecolor: "#e1f0fd",
    hovercolor: "#0772b8",
    info: "The operator or manager has access to this system to enable them to manage accounts, resources, users, units, and other macro-objects.",
    bgcolor: "#f0f8ff",
    link: "/management/dashboard",
  },
  {
    name: "Monitoring System",
    icon: MonitoringIcon,
    icon2: <MonitoringIcon2 />,
    ellipsecolor: "#28285c0a",
    hovercolor: "#8e8ea2",
    info: "Used by operators, managers, and users for tracking units and fleet management.",
    bgcolor: "#28285c0f",
    link: "/monitoring/consignments", // Example link, replace with your actual link
  },
  {
    name: "Settings",
    icon: MonitoringIcon,
    icon2: <MonitoringIcon2 />,
    ellipsecolor: "#28285c0a",
    hovercolor: "#8e8ea2",
    info: "Used by operators, managers, and users for tracking units and fleet management.",
    bgcolor: "#28285c0f",
    link: "/settings/vehicle-type", // Example link, replace with your actual link
  },
];

export const transportationModeType = [
  { name: "Truck", value: "Truck" },
  { name: "Ship", value: "Ship" },
  { name: "Airplane", value: "Airplane" },
  { name: "Railway", value: "Railway" },
];
export const transportationRateFinalize = [
  { name: "Yes, Finalized", value: true },
  { name: "No", value: false },
];
export const currenciesOptions = [
  {
    value: "TZS",
    label: "TZS",
    flagUrl: "https://www.countryflags.io/tz/shiny/24.png",
  },
  {
    value: "USD",
    label: "USD",
    flagUrl: "https://www.countryflags.io/tz/shiny/24.png",
  },
  // Add more currencies as needed
];
export const paymentTerms = [
  { name: "Payment in advance", value: "Payment in advance" },
  { name: "Payment on delivery", value: "Payment on delivery" },
  { name: "Payment via bank transfer", value: "Payment via bank transfer" },
  { name: "Credit point", value: "Credit point" },
];

export const countryList = [
  { name: "Afghanistan", code: "AF" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const statesList = [
  {
    country: "Afghanistan",
    states: [
      "Badakhshan",
      "Badghis",
      "Baghlan",
      "Balkh",
      "Bamian",
      "Daykondi",
      "Farah",
      "Faryab",
      "Ghazni",
      "Ghowr",
      "Helmand",
      "Herat",
      "Jowzjan",
      "Kabul",
      "Kandahar",
      "Kapisa",
      "Khost",
      "Konar",
      "Kondoz",
      "Laghman",
      "Lowgar",
      "Nangarhar",
      "Nimruz",
      "Nurestan",
      "Oruzgan",
      "Paktia",
      "Paktika",
      "Panjshir",
      "Parvan",
      "Samangan",
      "Sar-e Pol",
      "Takhar",
      "Vardak",
      "Zabol",
    ],
  },
  {
    country: "Albania",
    states: [
      "Berat",
      "Dibres",
      "Durres",
      "Elbasan",
      "Fier",
      "Gjirokastre",
      "Korce",
      "Kukes",
      "Lezhe",
      "Shkoder",
      "Tirane",
      "Vlore",
    ],
  },
  {
    country: "Algeria",
    states: [
      "Adrar",
      "Ain Defla",
      "Ain Temouchent",
      "Alger",
      "Annaba",
      "Batna",
      "Bechar",
      "Bejaia",
      "Biskra",
      "Blida",
      "Bordj Bou Arreridj",
      "Bouira",
      "Boumerdes",
      "Chlef",
      "Constantine",
      "Djelfa",
      "El Bayadh",
      "El Oued",
      "El Tarf",
      "Ghardaia",
      "Guelma",
      "Illizi",
      "Jijel",
      "Khenchela",
      "Laghouat",
      "Muaskar",
      "Medea",
      "Mila",
      "Mostaganem",
      "M'Sila",
      "Naama",
      "Oran",
      "Ouargla",
      "Oum el Bouaghi",
      "Relizane",
      "Saida",
      "Setif",
      "Sidi Bel Abbes",
      "Skikda",
      "Souk Ahras",
      "Tamanghasset",
      "Tebessa",
      "Tiaret",
      "Tindouf",
      "Tipaza",
      "Tissemsilt",
      "Tizi Ouzou",
      "Tlemcen",
    ],
  },
  {
    country: "Andorra",
    states: [
      "Andorra la Vella",
      "Canillo",
      "Encamp",
      "Escaldes-Engordany",
      "La Massana",
      "Ordino",
      "Sant Julia de Loria",
    ],
  },
  {
    country: "Angola",
    states: [
      "Bengo",
      "Benguela",
      "Bie",
      "Cabinda",
      "Cuando Cubango",
      "Cuanza Norte",
      "Cuanza Sul",
      "Cunene",
      "Huambo",
      "Huila",
      "Luanda",
      "Lunda Norte",
      "Lunda Sul",
      "Malanje",
      "Moxico",
      "Namibe",
      "Uige",
      "Zaire",
    ],
  },
  {
    country: "Antarctica",
    states: [],
  },
  {
    country: "Antigua and Barbuda",
    states: [
      "Barbuda",
      "Redonda",
      "Saint George",
      "Saint John",
      "Saint Mary",
      "Saint Paul",
      "Saint Peter",
      "Saint Philip",
    ],
  },
  {
    country: "Argentina",
    states: [
      "Buenos Aires",
      "Buenos Aires Capital",
      "Catamarca",
      "Chaco",
      "Chubut",
      "Cordoba",
      "Corrientes",
      "Entre Rios",
      "Formosa",
      "Jujuy",
      "La Pampa",
      "La Rioja",
      "Mendoza",
      "Misiones",
      "Neuquen",
      "Rio Negro",
      "Salta",
      "San Juan",
      "San Luis",
      "Santa Cruz",
      "Santa Fe",
      "Santiago del Estero",
      "Tierra del Fuego",
      "Tucuman",
    ],
  },
  {
    country: "Armenia",
    states: [
      "Aragatsotn",
      "Ararat",
      "Armavir",
      "Geghark'unik'",
      "Kotayk'",
      "Lorri",
      "Shirak",
      "Syunik'",
      "Tavush",
      "Vayots' Dzor",
      "Yerevan",
    ],
  },
  {
    country: "Australia",
    states: [],
  },
  {
    country: "Austria",
    states: [
      "Burgenland",
      "Kaernten",
      "Niederoesterreich",
      "Oberoesterreich",
      "Salzburg",
      "Steiermark",
      "Tirol",
      "Vorarlberg",
      "Wien",
    ],
  },
  {
    country: "Azerbaijan",
    states: [
      "Abseron Rayonu",
      "Agcabadi Rayonu",
      "Agdam Rayonu",
      "Agdas Rayonu",
      "Agstafa Rayonu",
      "Agsu Rayonu",
      "Astara Rayonu",
      "Balakan Rayonu",
      "Barda Rayonu",
      "Beylaqan Rayonu",
      "Bilasuvar Rayonu",
      "Cabrayil Rayonu",
      "Calilabad Rayonu",
      "Daskasan Rayonu",
      "Davaci Rayonu",
      "Fuzuli Rayonu",
      "Gadabay Rayonu",
      "Goranboy Rayonu",
      "Goycay Rayonu",
      "Haciqabul Rayonu",
      "Imisli Rayonu",
      "Ismayilli Rayonu",
      "Kalbacar Rayonu",
      "Kurdamir Rayonu",
      "Lacin Rayonu",
      "Lankaran Rayonu",
      "Lerik Rayonu",
      "Masalli Rayonu",
      "Neftcala Rayonu",
      "Oguz Rayonu",
      "Qabala Rayonu",
      "Qax Rayonu",
      "Qazax Rayonu",
      "Qobustan Rayonu",
      "Quba Rayonu",
      "Qubadli Rayonu",
      "Qusar Rayonu",
      "Saatli Rayonu",
      "Sabirabad Rayonu",
      "Saki Rayonu",
      "Salyan Rayonu",
      "Samaxi Rayonu",
      "Samkir Rayonu",
      "Samux Rayonu",
      "Siyazan Rayonu",
      "Susa Rayonu",
      "Tartar Rayonu",
      "Tovuz Rayonu",
      "Ucar Rayonu",
      "Xacmaz Rayonu",
      "Xanlar Rayonu",
      "Xizi Rayonu",
      "Xocali Rayonu",
      "Xocavand Rayonu",
      "Yardimli Rayonu",
      "Yevlax Rayonu",
      "Zangilan Rayonu",
      "Zaqatala Rayonu",
      "Zardab Rayonu",
      "Ali Bayramli Sahari",
      "Baki Sahari",
      "Ganca Sahari",
      "Lankaran Sahari",
      "Mingacevir Sahari",
      "Naftalan Sahari",
      "Saki Sahari",
      "Sumqayit Sahari",
      "Susa Sahari",
      "Xankandi Sahari",
      "Yevlax Sahari",
      "Naxcivan Muxtar",
    ],
  },
  {
    country: "Bahamas",
    states: [
      "Acklins and Crooked Islands",
      "Bimini",
      "Cat Island",
      "Exuma",
      "Freeport",
      "Fresh Creek",
      "Governor's Harbour",
      "Green Turtle Cay",
      "Harbour Island",
      "High Rock",
      "Inagua",
      "Kemps Bay",
      "Long Island",
      "Marsh Harbour",
      "Mayaguana",
      "New Providence",
      "Nichollstown and Berry Islands",
      "Ragged Island",
      "Rock Sound",
      "Sandy Point",
      "San Salvador and Rum Cay",
    ],
  },
  {
    country: "Bahrain",
    states: [
      "Al Hadd",
      "Al Manamah",
      "Al Mintaqah al Gharbiyah",
      "Al Mintaqah al Wusta",
      "Al Mintaqah ash Shamaliyah",
      "Al Muharraq",
      "Ar Rifa' wa al Mintaqah al Janubiyah",
      "Jidd Hafs",
      "Madinat Hamad",
      "Madinat 'Isa",
      "Juzur Hawar",
      "Sitrah",
    ],
  },
  {
    country: "Bangladesh",
    states: ["Barisal", "Chittagong", "Dhaka", "Khulna", "Rajshahi", "Sylhet"],
  },
  {
    country: "Barbados",
    states: [
      "Christ Church",
      "Saint Andrew",
      "Saint George",
      "Saint James",
      "Saint John",
      "Saint Joseph",
      "Saint Lucy",
      "Saint Michael",
      "Saint Peter",
      "Saint Philip",
      "Saint Thomas",
    ],
  },
  {
    country: "Belarus",
    states: [
      "Brest",
      "Homyel",
      "Horad Minsk",
      "Hrodna",
      "Mahilyow",
      "Minsk",
      "Vitsyebsk",
    ],
  },
  {
    country: "Belgium",
    states: [
      "Antwerpen",
      "Brabant Wallon",
      "Brussels",
      "Flanders",
      "Hainaut",
      "Liege",
      "Limburg",
      "Luxembourg",
      "Namur",
      "Oost-Vlaanderen",
      "Vlaams-Brabant",
      "Wallonia",
      "West-Vlaanderen",
    ],
  },
  {
    country: "Belize",
    states: [
      "Belize",
      "Cayo",
      "Corozal",
      "Orange Walk",
      "Stann Creek",
      "Toledo",
    ],
  },
  {
    country: "Benin",
    states: [
      "Alibori",
      "Atakora",
      "Atlantique",
      "Borgou",
      "Collines",
      "Donga",
      "Kouffo",
      "Littoral",
      "Mono",
      "Oueme",
      "Plateau",
      "Zou",
    ],
  },
  {
    country: "Bermuda",
    states: [
      "Devonshire",
      "Hamilton",
      "Hamilton",
      "Paget",
      "Pembroke",
      "Saint George",
      "Saint George's",
      "Sandys",
      "Smith's",
      "Southampton",
      "Warwick",
    ],
  },
  {
    country: "Bhutan",
    states: [
      "Bumthang",
      "Chukha",
      "Dagana",
      "Gasa",
      "Haa",
      "Lhuntse",
      "Mongar",
      "Paro",
      "Pemagatshel",
      "Punakha",
      "Samdrup Jongkhar",
      "Samtse",
      "Sarpang",
      "Thimphu",
      "Trashigang",
      "Trashiyangste",
      "Trongsa",
      "Tsirang",
      "Wangdue Phodrang",
      "Zhemgang",
    ],
  },
  {
    country: "Bolivia",
    states: [
      "Chuquisaca",
      "Cochabamba",
      "Beni",
      "La Paz",
      "Oruro",
      "Pando",
      "Potosi",
      "Santa Cruz",
      "Tarija",
    ],
  },
  {
    country: "Bosnia and Herzegovina",
    states: [
      "Una-Sana [Federation]",
      "Posavina [Federation]",
      "Tuzla [Federation]",
      "Zenica-Doboj [Federation]",
      "Bosnian Podrinje [Federation]",
      "Central Bosnia [Federation]",
      "Herzegovina-Neretva [Federation]",
      "West Herzegovina [Federation]",
      "Sarajevo [Federation]",
      " West Bosnia [Federation]",
      "Banja Luka [RS]",
      "Bijeljina [RS]",
      "Doboj [RS]",
      "Fo?a [RS]",
      "Sarajevo-Romanija [RS]",
      "Trebinje [RS]",
      "Vlasenica [RS]",
    ],
  },
  {
    country: "Botswana",
    states: [
      "Central",
      "Ghanzi",
      "Kgalagadi",
      "Kgatleng",
      "Kweneng",
      "North East",
      "North West",
      "South East",
      "Southern",
    ],
  },
  {
    country: "Brazil",
    states: [
      "Acre",
      "Alagoas",
      "Amapa",
      "Amazonas",
      "Bahia",
      "Ceara",
      "Distrito Federal",
      "Espirito Santo",
      "Goias",
      "Maranhao",
      "Mato Grosso",
      "Mato Grosso do Sul",
      "Minas Gerais",
      "Para",
      "Paraiba",
      "Parana",
      "Pernambuco",
      "Piaui",
      "Rio de Janeiro",
      "Rio Grande do Norte",
      "Rio Grande do Sul",
      "Rondonia",
      "Roraima",
      "Santa Catarina",
      "Sao Paulo",
      "Sergipe",
      "Tocantins",
    ],
  },
  {
    country: "Brunei",
    states: ["Belait", "Brunei and Muara", "Temburong", "Tutong"],
  },
  {
    country: "Bulgaria",
    states: [
      "Blagoevgrad",
      "Burgas",
      "Dobrich",
      "Gabrovo",
      "Khaskovo",
      "Kurdzhali",
      "Kyustendil",
      "Lovech",
      "Montana",
      "Pazardzhik",
      "Pernik",
      "Pleven",
      "Plovdiv",
      "Razgrad",
      "Ruse",
      "Shumen",
      "Silistra",
      "Sliven",
      "Smolyan",
      "Sofiya",
      "Sofiya-Grad",
      "Stara Zagora",
      "Turgovishte",
      "Varna",
      "Veliko Turnovo",
      "Vidin",
      "Vratsa",
      "Yambol",
    ],
  },
  {
    country: "Burkina Faso",
    states: [
      "Bale",
      "Bam",
      "Banwa",
      "Bazega",
      "Bougouriba",
      "Boulgou",
      "Boulkiemde",
      "Comoe",
      "Ganzourgou",
      "Gnagna",
      "Gourma",
      "Houet",
      "Ioba",
      "Kadiogo",
      "Kenedougou",
      "Komondjari",
      "Kompienga",
      "Kossi",
      "Koulpelogo",
      "Kouritenga",
      "Kourweogo",
      "Leraba",
      "Loroum",
      "Mouhoun",
      "Namentenga",
      "Nahouri",
      "Nayala",
      "Noumbiel",
      "Oubritenga",
      "Oudalan",
      "Passore",
      "Poni",
      "Sanguie",
      "Sanmatenga",
      "Seno",
      "Sissili",
      "Soum",
      "Sourou",
      "Tapoa",
      "Tuy",
      "Yagha",
      "Yatenga",
      "Ziro",
      "Zondoma",
      "Zoundweogo",
    ],
  },
  {
    country: "Burma",
    states: [
      "Ayeyarwady",
      "Bago",
      "Magway",
      "Mandalay",
      "Sagaing",
      "Tanintharyi",
      "Yangon",
      "Chin State",
      "Kachin State",
      "Kayin State",
      "Kayah State",
      "Mon State",
      "Rakhine State",
      "Shan State",
    ],
  },
  {
    country: "Burundi",
    states: [
      "Bubanza",
      "Bujumbura Mairie",
      "Bujumbura Rural",
      "Bururi",
      "Cankuzo",
      "Cibitoke",
      "Gitega",
      "Karuzi",
      "Kayanza",
      "Kirundo",
      "Makamba",
      "Muramvya",
      "Muyinga",
      "Mwaro",
      "Ngozi",
      "Rutana",
      "Ruyigi",
    ],
  },
  {
    country: "Cambodia",
    states: [
      "Banteay Mean Chey",
      "Batdambang",
      "Kampong Cham",
      "Kampong Chhnang",
      "Kampong Spoe",
      "Kampong Thum",
      "Kampot",
      "Kandal",
      "Koh Kong",
      "Kracheh",
      "Mondol Kiri",
      "Otdar Mean Chey",
      "Pouthisat",
      "Preah Vihear",
      "Prey Veng",
      "Rotanakir",
      "Siem Reab",
      "Stoeng Treng",
      "Svay Rieng",
      "Takao",
      "Keb",
      "Pailin",
      "Phnom Penh",
      "Preah Seihanu",
    ],
  },
  {
    country: "Cameroon",
    states: [
      "Adamaoua",
      "Centre",
      "Est",
      "Extreme-Nord",
      "Littoral",
      "Nord",
      "Nord-Ouest",
      "Ouest",
      "Sud",
      "Sud-Ouest",
    ],
  },
  {
    country: "Canada",
    states: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon Territory",
    ],
  },
  {
    country: "Cape Verde",
    states: [],
  },
  {
    country: "Central African Republic",
    states: [
      "Bamingui-Bangoran",
      "Bangui",
      "Basse-Kotto",
      "Haute-Kotto",
      "Haut-Mbomou",
      "Kemo",
      "Lobaye",
      "Mambere-Kadei",
      "Mbomou",
      "Nana-Grebizi",
      "Nana-Mambere",
      "Ombella-Mpoko",
      "Ouaka",
      "Ouham",
      "Ouham-Pende",
      "Sangha-Mbaere",
      "Vakaga",
    ],
  },
  {
    country: "Chad",
    states: [
      "Batha",
      "Biltine",
      "Borkou-Ennedi-Tibesti",
      "Chari-Baguirmi",
      "Guéra",
      "Kanem",
      "Lac",
      "Logone Occidental",
      "Logone Oriental",
      "Mayo-Kebbi",
      "Moyen-Chari",
      "Ouaddaï",
      "Salamat",
      "Tandjile",
    ],
  },
  {
    country: "Chile",
    states: [
      "Aysen",
      "Antofagasta",
      "Araucania",
      "Atacama",
      "Bio-Bio",
      "Coquimbo",
      "O'Higgins",
      "Los Lagos",
      "Magallanes y la Antartica Chilena",
      "Maule",
      "Santiago Region Metropolitana",
      "Tarapaca",
      "Valparaiso",
    ],
  },
  {
    country: "China",
    states: [
      "Anhui",
      "Fujian",
      "Gansu",
      "Guangdong",
      "Guizhou",
      "Hainan",
      "Hebei",
      "Heilongjiang",
      "Henan",
      "Hubei",
      "Hunan",
      "Jiangsu",
      "Jiangxi",
      "Jilin",
      "Liaoning",
      "Qinghai",
      "Shaanxi",
      "Shandong",
      "Shanxi",
      "Sichuan",
      "Yunnan",
      "Zhejiang",
      "Guangxi",
      "Nei Mongol",
      "Ningxia",
      "Xinjiang",
      "Xizang (Tibet)",
      "Beijing",
      "Chongqing",
      "Shanghai",
      "Tianjin",
    ],
  },
  {
    country: "Colombia",
    states: [
      "Amazonas",
      "Antioquia",
      "Arauca",
      "Atlantico",
      "Bogota District Capital",
      "Bolivar",
      "Boyaca",
      "Caldas",
      "Caqueta",
      "Casanare",
      "Cauca",
      "Cesar",
      "Choco",
      "Cordoba",
      "Cundinamarca",
      "Guainia",
      "Guaviare",
      "Huila",
      "La Guajira",
      "Magdalena",
      "Meta",
      "Narino",
      "Norte de Santander",
      "Putumayo",
      "Quindio",
      "Risaralda",
      "San Andres & Providencia",
      "Santander",
      "Sucre",
      "Tolima",
      "Valle del Cauca",
      "Vaupes",
      "Vichada",
    ],
  },
  {
    country: "Comoros",
    states: ["Grande Comore (Njazidja)", "Anjouan (Nzwani)", "Moheli (Mwali)"],
  },
  {
    country: "Congo, Democratic Republic",
    states: [
      "Bandundu",
      "Bas-Congo",
      "Equateur",
      "Kasai-Occidental",
      "Kasai-Oriental",
      "Katanga",
      "Kinshasa",
      "Maniema",
      "Nord-Kivu",
      "Orientale",
      "Sud-Kivu",
    ],
  },
  {
    country: "Congo, Republic of the",
    states: [
      "Bouenza",
      "Brazzaville",
      "Cuvette",
      "Cuvette-Ouest",
      "Kouilou",
      "Lekoumou",
      "Likouala",
      "Niari",
      "Plateaux",
      "Pool",
      "Sangha",
    ],
  },
  {
    country: "Costa Rica",
    states: [
      "Alajuela",
      "Cartago",
      "Guanacaste",
      "Heredia",
      "Limon",
      "Puntarenas",
      "San Jose",
    ],
  },
  {
    country: "Cote d'Ivoire",
    states: [],
  },
  {
    country: "Croatia",
    states: [
      "Bjelovarsko-Bilogorska",
      "Brodsko-Posavska",
      "Dubrovacko-Neretvanska",
      "Istarska",
      "Karlovacka",
      "Koprivnicko-Krizevacka",
      "Krapinsko-Zagorska",
      "Licko-Senjska",
      "Medimurska",
      "Osjecko-Baranjska",
      "Pozesko-Slavonska",
      "Primorsko-Goranska",
      "Sibensko-Kninska",
      "Sisacko-Moslavacka",
      "Splitsko-Dalmatinska",
      "Varazdinska",
      "Viroviticko-Podravska",
      "Vukovarsko-Srijemska",
      "Zadarska",
      "Zagreb",
      "Zagrebacka",
    ],
  },
  {
    country: "Cuba",
    states: [
      "Camaguey",
      "Ciego de Avila",
      "Cienfuegos",
      "Ciudad de La Habana",
      "Granma",
      "Guantanamo",
      "Holguin",
      "Isla de la Juventud",
      "La Habana",
      "Las Tunas",
      "Matanzas",
      "Pinar del Rio",
      "Sancti Spiritus",
      "Santiago de Cuba",
      "Villa Clara",
    ],
  },
  {
    country: "Cyprus",
    states: [
      "Famagusta",
      "Kyrenia",
      "Larnaca",
      "Limassol",
      "Nicosia",
      "Paphos",
    ],
  },
  {
    country: "Czech Republic",
    states: [
      "Jihocesky Kraj",
      "Jihomoravsky Kraj",
      "Karlovarsky Kraj",
      "Kralovehradecky Kraj",
      "Liberecky Kraj",
      "Moravskoslezsky Kraj",
      "Olomoucky Kraj",
      "Pardubicky Kraj",
      "Plzensky Kraj",
      "Praha",
      "Stredocesky Kraj",
      "Ustecky Kraj",
      "Vysocina",
      "Zlinsky Kraj",
    ],
  },
  {
    country: "Denmark",
    states: [
      "Arhus",
      "Bornholm",
      "Frederiksberg",
      "Frederiksborg",
      "Fyn",
      "Kobenhavn",
      "Kobenhavns",
      "Nordjylland",
      "Ribe",
      "Ringkobing",
      "Roskilde",
      "Sonderjylland",
      "Storstrom",
      "Vejle",
      "Vestsjalland",
      "Viborg",
    ],
  },
  {
    country: "Djibouti",
    states: ["Ali Sabih", "Dikhil", "Djibouti", "Obock", "Tadjoura"],
  },
  {
    country: "Dominica",
    states: [
      "Saint Andrew",
      "Saint David",
      "Saint George",
      "Saint John",
      "Saint Joseph",
      "Saint Luke",
      "Saint Mark",
      "Saint Patrick",
      "Saint Paul",
      "Saint Peter",
    ],
  },
  {
    country: "Dominican Republic",
    states: [
      "Azua",
      "Baoruco",
      "Barahona",
      "Dajabon",
      "Distrito Nacional",
      "Duarte",
      "Elias Pina",
      "El Seibo",
      "Espaillat",
      "Hato Mayor",
      "Independencia",
      "La Altagracia",
      "La Romana",
      "La Vega",
      "Maria Trinidad Sanchez",
      "Monsenor Nouel",
      "Monte Cristi",
      "Monte Plata",
      "Pedernales",
      "Peravia",
      "Puerto Plata",
      "Salcedo",
      "Samana",
      "Sanchez Ramirez",
      "San Cristobal",
      "San Jose de Ocoa",
      "San Juan",
      "San Pedro de Macoris",
      "Santiago",
      "Santiago Rodriguez",
      "Santo Domingo",
      "Valverde",
    ],
  },
  {
    country: "East Timor",
    states: [
      "Aileu",
      "Ainaro",
      "Baucau",
      "Bobonaro",
      "Cova-Lima",
      "Dili",
      "Ermera",
      "Lautem",
      "Liquica",
      "Manatuto",
      "Manufahi",
      "Oecussi",
      "Viqueque",
    ],
  },
  {
    country: "Ecuador",
    states: [
      "Azuay",
      "Bolivar",
      "Canar",
      "Carchi",
      "Chimborazo",
      "Cotopaxi",
      "El Oro",
      "Esmeraldas",
      "Galapagos",
      "Guayas",
      "Imbabura",
      "Loja",
      "Los Rios",
      "Manabi",
      "Morona-Santiago",
      "Napo",
      "Orellana",
      "Pastaza",
      "Pichincha",
      "Sucumbios",
      "Tungurahua",
      "Zamora-Chinchipe",
    ],
  },
  {
    country: "Egypt",
    states: [
      "Ad Daqahliyah",
      "Al Bahr al Ahmar",
      "Al Buhayrah",
      "Al Fayyum",
      "Al Gharbiyah",
      "Al Iskandariyah",
      "Al Isma'iliyah",
      "Al Jizah",
      "Al Minufiyah",
      "Al Minya",
      "Al Qahirah",
      "Al Qalyubiyah",
      "Al Wadi al Jadid",
      "Ash Sharqiyah",
      "As Suways",
      "Aswan",
      "Asyut",
      "Bani Suwayf",
      "Bur Sa'id",
      "Dumyat",
      "Janub Sina'",
      "Kafr ash Shaykh",
      "Matruh",
      "Qina",
      "Shamal Sina'",
      "Suhaj",
    ],
  },
  {
    country: "El Salvador",
    states: [
      "Ahuachapan",
      "Cabanas",
      "Chalatenango",
      "Cuscatlan",
      "La Libertad",
      "La Paz",
      "La Union",
      "Morazan",
      "San Miguel",
      "San Salvador",
      "Santa Ana",
      "San Vicente",
      "Sonsonate",
      "Usulutan",
    ],
  },
  {
    country: "Equatorial Guinea",
    states: [
      "Annobon",
      "Bioko Norte",
      "Bioko Sur",
      "Centro Sur",
      "Kie-Ntem",
      "Litoral",
      "Wele-Nzas",
    ],
  },
  {
    country: "Eritrea",
    states: [
      "Anseba",
      "Debub",
      "Debubawi K'eyih Bahri",
      "Gash Barka",
      "Ma'akel",
      "Semenawi Keyih Bahri",
    ],
  },
  {
    country: "Estonia",
    states: [
      "Harjumaa (Tallinn)",
      "Hiiumaa (Kardla)",
      "Ida-Virumaa (Johvi)",
      "Jarvamaa (Paide)",
      "Jogevamaa (Jogeva)",
      "Laanemaa (Haapsalu)",
      "Laane-Virumaa (Rakvere)",
      "Parnumaa (Parnu)",
      "Polvamaa (Polva)",
      "Raplamaa (Rapla)",
      "Saaremaa (Kuressaare)",
      "Tartumaa (Tartu)",
      "Valgamaa (Valga)",
      "Viljandimaa (Viljandi)",
      "Vorumaa (Voru)",
    ],
  },
  {
    country: "Ethiopia",
    states: [
      "Addis Ababa",
      "Afar",
      "Amhara",
      "Binshangul Gumuz",
      "Dire Dawa",
      "Gambela Hizboch",
      "Harari",
      "Oromia",
      "Somali",
      "Tigray",
      "Southern Nations, Nationalities, and Peoples Region",
    ],
  },
  {
    country: "Fiji",
    states: [
      "Central (Suva)",
      "Eastern (Levuka)",
      "Northern (Labasa)",
      "Rotuma",
      "Western (Lautoka)",
    ],
  },
  {
    country: "Finland",
    states: [
      "Aland",
      "Etela-Suomen Laani",
      "Ita-Suomen Laani",
      "Lansi-Suomen Laani",
      "Lappi",
      "Oulun Laani",
    ],
  },
  {
    country: "France",
    states: [
      "Alsace",
      "Aquitaine",
      "Auvergne",
      "Basse-Normandie",
      "Bourgogne",
      "Bretagne",
      "Centre",
      "Champagne-Ardenne",
      "Corse",
      "Franche-Comte",
      "Haute-Normandie",
      "Ile-de-France",
      "Languedoc-Roussillon",
      "Limousin",
      "Lorraine",
      "Midi-Pyrenees",
      "Nord-Pas-de-Calais",
      "Pays de la Loire",
      "Picardie",
      "Poitou-Charentes",
      "Provence-Alpes-Cote d'Azur",
      "Rhone-Alpes",
    ],
  },
  {
    country: "Gabon",
    states: [
      "Estuaire",
      "Haut-Ogooue",
      "Moyen-Ogooue",
      "Ngounie",
      "Nyanga",
      "Ogooue-Ivindo",
      "Ogooue-Lolo",
      "Ogooue-Maritime",
      "Woleu-Ntem",
    ],
  },
  {
    country: "Gambia",
    states: [
      "Banjul",
      "Central River",
      "Lower River",
      "North Bank",
      "Upper River",
      "Western",
    ],
  },
  {
    country: "Georgia",
    states: [],
  },
  {
    country: "Germany",
    states: [
      "Baden-Wuerttemberg",
      "Bayern",
      "Berlin",
      "Brandenburg",
      "Bremen",
      "Hamburg",
      "Hessen",
      "Mecklenburg-Vorpommern",
      "Niedersachsen",
      "Nordrhein-Westfalen",
      "Rheinland-Pfalz",
      "Saarland",
      "Sachsen",
      "Sachsen-Anhalt",
      "Schleswig-Holstein",
      "Thueringen",
    ],
  },
  {
    country: "Ghana",
    states: [
      "Ashanti",
      "Brong-Ahafo",
      "Central",
      "Eastern",
      "Greater Accra",
      "Northern",
      "Upper East",
      "Upper West",
      "Volta",
      "Western",
    ],
  },
  {
    country: "Greece",
    states: [
      "Agion Oros",
      "Achaia",
      "Aitolia kai Akarmania",
      "Argolis",
      "Arkadia",
      "Arta",
      "Attiki",
      "Chalkidiki",
      "Chanion",
      "Chios",
      "Dodekanisos",
      "Drama",
      "Evros",
      "Evrytania",
      "Evvoia",
      "Florina",
      "Fokidos",
      "Fthiotis",
      "Grevena",
      "Ileia",
      "Imathia",
      "Ioannina",
      "Irakleion",
      "Karditsa",
      "Kastoria",
      "Kavala",
      "Kefallinia",
      "Kerkyra",
      "Kilkis",
      "Korinthia",
      "Kozani",
      "Kyklades",
      "Lakonia",
      "Larisa",
      "Lasithi",
      "Lefkas",
      "Lesvos",
      "Magnisia",
      "Messinia",
      "Pella",
      "Pieria",
      "Preveza",
      "Rethynnis",
      "Rodopi",
      "Samos",
      "Serrai",
      "Thesprotia",
      "Thessaloniki",
      "Trikala",
      "Voiotia",
      "Xanthi",
      "Zakynthos",
    ],
  },
  {
    country: "Greenland",
    states: [
      "Avannaa (Nordgronland)",
      "Tunu (Ostgronland)",
      "Kitaa (Vestgronland)",
    ],
  },
  {
    country: "Grenada",
    states: [
      "Carriacou and Petit Martinique",
      "Saint Andrew",
      "Saint David",
      "Saint George",
      "Saint John",
      "Saint Mark",
      "Saint Patrick",
    ],
  },
  {
    country: "Guatemala",
    states: [
      "Alta Verapaz",
      "Baja Verapaz",
      "Chimaltenango",
      "Chiquimula",
      "El Progreso",
      "Escuintla",
      "Guatemala",
      "Huehuetenango",
      "Izabal",
      "Jalapa",
      "Jutiapa",
      "Peten",
      "Quetzaltenango",
      "Quiche",
      "Retalhuleu",
      "Sacatepequez",
      "San Marcos",
      "Santa Rosa",
      "Solola",
      "Suchitepequez",
      "Totonicapan",
      "Zacapa",
    ],
  },
  {
    country: "Guinea",
    states: [
      "Beyla",
      "Boffa",
      "Boke",
      "Conakry",
      "Coyah",
      "Dabola",
      "Dalaba",
      "Dinguiraye",
      "Dubreka",
      "Faranah",
      "Forecariah",
      "Fria",
      "Gaoual",
      "Gueckedou",
      "Kankan",
      "Kerouane",
      "Kindia",
      "Kissidougou",
      "Koubia",
      "Koundara",
      "Kouroussa",
      "Labe",
      "Lelouma",
      "Lola",
      "Macenta",
      "Mali",
      "Mamou",
      "Mandiana",
      "Nzerekore",
      "Pita",
      "Siguiri",
      "Telimele",
      "Tougue",
      "Yomou",
    ],
  },
  {
    country: "Guinea-Bissau",
    states: [
      "Bafata",
      "Biombo",
      "Bissau",
      "Bolama",
      "Cacheu",
      "Gabu",
      "Oio",
      "Quinara",
      "Tombali",
    ],
  },
  {
    country: "Guyana",
    states: [
      "Barima-Waini",
      "Cuyuni-Mazaruni",
      "Demerara-Mahaica",
      "East Berbice-Corentyne",
      "Essequibo Islands-West Demerara",
      "Mahaica-Berbice",
      "Pomeroon-Supenaam",
      "Potaro-Siparuni",
      "Upper Demerara-Berbice",
      "Upper Takutu-Upper Essequibo",
    ],
  },
  {
    country: "Haiti",
    states: [
      "Artibonite",
      "Centre",
      "Grand 'Anse",
      "Nord",
      "Nord-Est",
      "Nord-Ouest",
      "Ouest",
      "Sud",
      "Sud-Est",
    ],
  },
  {
    country: "Honduras",
    states: [
      "Atlantida",
      "Choluteca",
      "Colon",
      "Comayagua",
      "Copan",
      "Cortes",
      "El Paraiso",
      "Francisco Morazan",
      "Gracias a Dios",
      "Intibuca",
      "Islas de la Bahia",
      "La Paz",
      "Lempira",
      "Ocotepeque",
      "Olancho",
      "Santa Barbara",
      "Valle",
      "Yoro",
    ],
  },
  {
    country: "Hong Kong",
    states: [],
  },
  {
    country: "Hungary",
    states: [
      "Bacs-Kiskun",
      "Baranya",
      "Bekes",
      "Borsod-Abauj-Zemplen",
      "Csongrad",
      "Fejer",
      "Gyor-Moson-Sopron",
      "Hajdu-Bihar",
      "Heves",
      "Jasz-Nagykun-Szolnok",
      "Komarom-Esztergom",
      "Nograd",
      "Pest",
      "Somogy",
      "Szabolcs-Szatmar-Bereg",
      "Tolna",
      "Vas",
      "Veszprem",
      "Zala",
      "Bekescsaba",
      "Debrecen",
      "Dunaujvaros",
      "Eger",
      "Gyor",
      "Hodmezovasarhely",
      "Kaposvar",
      "Kecskemet",
      "Miskolc",
      "Nagykanizsa",
      "Nyiregyhaza",
      "Pecs",
      "Sopron",
      "Szeged",
      "Szekesfehervar",
      "Szolnok",
      "Szombathely",
      "Tatabanya",
      "Veszprem",
      "Zalaegerszeg",
    ],
  },
  {
    country: "Iceland",
    states: [
      "Austurland",
      "Hofudhborgarsvaedhi",
      "Nordhurland Eystra",
      "Nordhurland Vestra",
      "Sudhurland",
      "Sudhurnes",
      "Vestfirdhir",
      "Vesturland",
    ],
  },
  {
    country: "India",
    states: [
      "Andaman and Nicobar Islands",
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chandigarh",
      "Chhattisgarh",
      "Dadra and Nagar Haveli",
      "Daman and Diu",
      "Delhi",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Lakshadweep",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Orissa",
      "Pondicherry",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Tripura",
      "Uttaranchal",
      "Uttar Pradesh",
      "West Bengal",
    ],
  },
  {
    country: "Indonesia",
    states: [
      "Aceh",
      "Bali",
      "Banten",
      "Bengkulu",
      "Gorontalo",
      "Irian Jaya Barat",
      "Jakarta Raya",
      "Jambi",
      "Jawa Barat",
      "Jawa Tengah",
      "Jawa Timur",
      "Kalimantan Barat",
      "Kalimantan Selatan",
      "Kalimantan Tengah",
      "Kalimantan Timur",
      "Kepulauan Bangka Belitung",
      "Kepulauan Riau",
      "Lampung",
      "Maluku",
      "Maluku Utara",
      "Nusa Tenggara Barat",
      "Nusa Tenggara Timur",
      "Papua",
      "Riau",
      "Sulawesi Barat",
      "Sulawesi Selatan",
      "Sulawesi Tengah",
      "Sulawesi Tenggara",
      "Sulawesi Utara",
      "Sumatera Barat",
      "Sumatera Selatan",
      "Sumatera Utara",
      "Yogyakarta",
    ],
  },
  {
    country: "Iran",
    states: [
      "Ardabil",
      "Azarbayjan-e Gharbi",
      "Azarbayjan-e Sharqi",
      "Bushehr",
      "Chahar Mahall va Bakhtiari",
      "Esfahan",
      "Fars",
      "Gilan",
      "Golestan",
      "Hamadan",
      "Hormozgan",
      "Ilam",
      "Kerman",
      "Kermanshah",
      "Khorasan-e Janubi",
      "Khorasan-e Razavi",
      "Khorasan-e Shemali",
      "Khuzestan",
      "Kohgiluyeh va Buyer Ahmad",
      "Kordestan",
      "Lorestan",
      "Markazi",
      "Mazandaran",
      "Qazvin",
      "Qom",
      "Semnan",
      "Sistan va Baluchestan",
      "Tehran",
      "Yazd",
      "Zanjan",
    ],
  },
  {
    country: "Iraq",
    states: [
      "Al Anbar",
      "Al Basrah",
      "Al Muthanna",
      "Al Qadisiyah",
      "An Najaf",
      "Arbil",
      "As Sulaymaniyah",
      "At Ta'mim",
      "Babil",
      "Baghdad",
      "Dahuk",
      "Dhi Qar",
      "Diyala",
      "Karbala'",
      "Maysan",
      "Ninawa",
      "Salah ad Din",
      "Wasit",
    ],
  },
  {
    country: "Ireland",
    states: [
      "Carlow",
      "Cavan",
      "Clare",
      "Cork",
      "Donegal",
      "Dublin",
      "Galway",
      "Kerry",
      "Kildare",
      "Kilkenny",
      "Laois",
      "Leitrim",
      "Limerick",
      "Longford",
      "Louth",
      "Mayo",
      "Meath",
      "Monaghan",
      "Offaly",
      "Roscommon",
      "Sligo",
      "Tipperary",
      "Waterford",
      "Westmeath",
      "Wexford",
      "Wicklow",
    ],
  },
  {
    country: "Israel",
    states: [
      "Central",
      "Haifa",
      "Jerusalem",
      "Northern",
      "Southern",
      "Tel Aviv",
    ],
  },
  {
    country: "Italy",
    states: [
      "Abruzzo",
      "Basilicata",
      "Calabria",
      "Campania",
      "Emilia-Romagna",
      "Friuli-Venezia Giulia",
      "Lazio",
      "Liguria",
      "Lombardia",
      "Marche",
      "Molise",
      "Piemonte",
      "Puglia",
      "Sardegna",
      "Sicilia",
      "Toscana",
      "Trentino-Alto Adige",
      "Umbria",
      "Valle d'Aosta",
      "Veneto",
    ],
  },
  {
    country: "Jamaica",
    states: [
      "Clarendon",
      "Hanover",
      "Kingston",
      "Manchester",
      "Portland",
      "Saint Andrew",
      "Saint Ann",
      "Saint Catherine",
      "Saint Elizabeth",
      "Saint James",
      "Saint Mary",
      "Saint Thomas",
      "Trelawny",
      "Westmoreland",
    ],
  },
  {
    country: "Japan",
    states: [
      "Aichi",
      "Akita",
      "Aomori",
      "Chiba",
      "Ehime",
      "Fukui",
      "Fukuoka",
      "Fukushima",
      "Gifu",
      "Gumma",
      "Hiroshima",
      "Hokkaido",
      "Hyogo",
      "Ibaraki",
      "Ishikawa",
      "Iwate",
      "Kagawa",
      "Kagoshima",
      "Kanagawa",
      "Kochi",
      "Kumamoto",
      "Kyoto",
      "Mie",
      "Miyagi",
      "Miyazaki",
      "Nagano",
      "Nagasaki",
      "Nara",
      "Niigata",
      "Oita",
      "Okayama",
      "Okinawa",
      "Osaka",
      "Saga",
      "Saitama",
      "Shiga",
      "Shimane",
      "Shizuoka",
      "Tochigi",
      "Tokushima",
      "Tokyo",
      "Tottori",
      "Toyama",
      "Wakayama",
      "Yamagata",
      "Yamaguchi",
      "Yamanashi",
    ],
  },
  {
    country: "Jordan",
    states: [
      "Ajlun",
      "Al 'Aqabah",
      "Al Balqa'",
      "Al Karak",
      "Al Mafraq",
      "'Amman",
      "At Tafilah",
      "Az Zarqa'",
      "Irbid",
      "Jarash",
      "Ma'an",
      "Madaba",
    ],
  },
  {
    country: "Kazakhstan",
    states: [
      "Almaty Oblysy",
      "Almaty Qalasy",
      "Aqmola Oblysy",
      "Aqtobe Oblysy",
      "Astana Qalasy",
      "Atyrau Oblysy",
      "Batys Qazaqstan Oblysy",
      "Bayqongyr Qalasy",
      "Mangghystau Oblysy",
      "Ongtustik Qazaqstan Oblysy",
      "Pavlodar Oblysy",
      "Qaraghandy Oblysy",
      "Qostanay Oblysy",
      "Qyzylorda Oblysy",
      "Shyghys Qazaqstan Oblysy",
      "Soltustik Qazaqstan Oblysy",
      "Zhambyl Oblysy",
    ],
  },
  {
    country: "Kenya",
    states: [
      "Central",
      "Coast",
      "Eastern",
      "Nairobi Area",
      "North Eastern",
      "Nyanza",
      "Rift Valley",
      "Western",
    ],
  },
  {
    country: "Kiribati",
    states: [],
  },
  {
    country: "Korea North",
    states: [
      "Chagang",
      "North Hamgyong",
      "South Hamgyong",
      "North Hwanghae",
      "South Hwanghae",
      "Kangwon",
      "North P'yongan",
      "South P'yongan",
      "Yanggang",
      "Kaesong",
      "Najin",
      "Namp'o",
      "Pyongyang",
    ],
  },
  {
    country: "Korea South",
    states: [
      "Seoul",
      "Busan City",
      "Daegu City",
      "Incheon City",
      "Gwangju City",
      "Daejeon City",
      "Ulsan",
      "Gyeonggi Province",
      "Gangwon Province",
      "North Chungcheong Province",
      "South Chungcheong Province",
      "North Jeolla Province",
      "South Jeolla Province",
      "North Gyeongsang Province",
      "South Gyeongsang Province",
      "Jeju",
    ],
  },
  {
    country: "Kuwait",
    states: [
      "Al Ahmadi",
      "Al Farwaniyah",
      "Al Asimah",
      "Al Jahra",
      "Hawalli",
      "Mubarak Al-Kabeer",
    ],
  },
  {
    country: "Kyrgyzstan",
    states: [
      "Batken Oblasty",
      "Bishkek Shaary",
      "Chuy Oblasty",
      "Jalal-Abad Oblasty",
      "Naryn Oblasty",
      "Osh Oblasty",
      "Talas Oblasty",
      "Ysyk-Kol Oblasty",
    ],
  },
  {
    country: "Laos",
    states: [
      "Attapu",
      "Bokeo",
      "Bolikhamxai",
      "Champasak",
      "Houaphan",
      "Khammouan",
      "Louangnamtha",
      "Louangphrabang",
      "Oudomxai",
      "Phongsali",
      "Salavan",
      "Savannakhet",
      "Viangchan",
      "Viangchan",
      "Xaignabouli",
      "Xaisomboun",
      "Xekong",
      "Xiangkhoang",
    ],
  },
  {
    country: "Latvia",
    states: [
      "Aizkraukles Rajons",
      "Aluksnes Rajons",
      "Balvu Rajons",
      "Bauskas Rajons",
      "Cesu Rajons",
      "Daugavpils",
      "Daugavpils Rajons",
      "Dobeles Rajons",
      "Gulbenes Rajons",
      "Jekabpils Rajons",
      "Jelgava",
      "Jelgavas Rajons",
      "Jurmala",
      "Kraslavas Rajons",
      "Kuldigas Rajons",
      "Liepaja",
      "Liepajas Rajons",
      "Limbazu Rajons",
      "Ludzas Rajons",
      "Madonas Rajons",
      "Ogres Rajons",
      "Preilu Rajons",
      "Rezekne",
      "Rezeknes Rajons",
      "Riga",
      "Rigas Rajons",
      "Saldus Rajons",
      "Talsu Rajons",
      "Tukuma Rajons",
      "Valkas Rajons",
      "Valmieras Rajons",
      "Ventspils",
      "Ventspils Rajons",
    ],
  },
  {
    country: "Lebanon",
    states: [
      "Beyrouth",
      "Beqaa",
      "Liban-Nord",
      "Liban-Sud",
      "Mont-Liban",
      "Nabatiye",
    ],
  },
  {
    country: "Lesotho",
    states: [
      "Berea",
      "Butha-Buthe",
      "Leribe",
      "Mafeteng",
      "Maseru",
      "Mohale's Hoek",
      "Mokhotlong",
      "Qacha's Nek",
      "Quthing",
      "Thaba-Tseka",
    ],
  },
  {
    country: "Liberia",
    states: [
      "Bomi",
      "Bong",
      "Gbarpolu",
      "Grand Bassa",
      "Grand Cape Mount",
      "Grand Gedeh",
      "Grand Kru",
      "Lofa",
      "Margibi",
      "Maryland",
      "Montserrado",
      "Nimba",
      "River Cess",
      "River Gee",
      "Sinoe",
    ],
  },
  {
    country: "Libya",
    states: [
      "Ajdabiya",
      "Al 'Aziziyah",
      "Al Fatih",
      "Al Jabal al Akhdar",
      "Al Jufrah",
      "Al Khums",
      "Al Kufrah",
      "An Nuqat al Khams",
      "Ash Shati'",
      "Awbari",
      "Az Zawiyah",
      "Banghazi",
      "Darnah",
      "Ghadamis",
      "Gharyan",
      "Misratah",
      "Murzuq",
      "Sabha",
      "Sawfajjin",
      "Surt",
      "Tarabulus",
      "Tarhunah",
      "Tubruq",
      "Yafran",
      "Zlitan",
    ],
  },
  {
    country: "Liechtenstein",
    states: [
      "Balzers",
      "Eschen",
      "Gamprin",
      "Mauren",
      "Planken",
      "Ruggell",
      "Schaan",
      "Schellenberg",
      "Triesen",
      "Triesenberg",
      "Vaduz",
    ],
  },
  {
    country: "Lithuania",
    states: [
      "Alytaus",
      "Kauno",
      "Klaipedos",
      "Marijampoles",
      "Panevezio",
      "Siauliu",
      "Taurages",
      "Telsiu",
      "Utenos",
      "Vilniaus",
    ],
  },
  {
    country: "Luxembourg",
    states: ["Diekirch", "Grevenmacher", "Luxembourg"],
  },
  {
    country: "Macedonia",
    states: [
      "Aerodrom",
      "Aracinovo",
      "Berovo",
      "Bitola",
      "Bogdanci",
      "Bogovinje",
      "Bosilovo",
      "Brvenica",
      "Butel",
      "Cair",
      "Caska",
      "Centar",
      "Centar Zupa",
      "Cesinovo",
      "Cucer-Sandevo",
      "Debar",
      "Debartsa",
      "Delcevo",
      "Demir Hisar",
      "Demir Kapija",
      "Dojran",
      "Dolneni",
      "Drugovo",
      "Gazi Baba",
      "Gevgelija",
      "Gjorce Petrov",
      "Gostivar",
      "Gradsko",
      "Ilinden",
      "Jegunovce",
      "Karbinci",
      "Karpos",
      "Kavadarci",
      "Kicevo",
      "Kisela Voda",
      "Kocani",
      "Konce",
      "Kratovo",
      "Kriva Palanka",
      "Krivogastani",
      "Krusevo",
      "Kumanovo",
      "Lipkovo",
      "Lozovo",
      "Makedonska Kamenica",
      "Makedonski Brod",
      "Mavrovo i Rastusa",
      "Mogila",
      "Negotino",
      "Novaci",
      "Novo Selo",
      "Ohrid",
      "Oslomej",
      "Pehcevo",
      "Petrovec",
      "Plasnica",
      "Prilep",
      "Probistip",
      "Radovis",
      "Rankovce",
      "Resen",
      "Rosoman",
      "Saraj",
      "Skopje",
      "Sopiste",
      "Staro Nagoricane",
      "Stip",
      "Struga",
      "Strumica",
      "Studenicani",
      "Suto Orizari",
      "Sveti Nikole",
      "Tearce",
      "Tetovo",
      "Valandovo",
      "Vasilevo",
      "Veles",
      "Vevcani",
      "Vinica",
      "Vranestica",
      "Vrapciste",
      "Zajas",
      "Zelenikovo",
      "Zelino",
      "Zrnovci",
    ],
  },
  {
    country: "Madagascar",
    states: [
      "Antananarivo",
      "Antsiranana",
      "Fianarantsoa",
      "Mahajanga",
      "Toamasina",
      "Toliara",
    ],
  },
  {
    country: "Malawi",
    states: [
      "Balaka",
      "Blantyre",
      "Chikwawa",
      "Chiradzulu",
      "Chitipa",
      "Dedza",
      "Dowa",
      "Karonga",
      "Kasungu",
      "Likoma",
      "Lilongwe",
      "Machinga",
      "Mangochi",
      "Mchinji",
      "Mulanje",
      "Mwanza",
      "Mzimba",
      "Ntcheu",
      "Nkhata Bay",
      "Nkhotakota",
      "Nsanje",
      "Ntchisi",
      "Phalombe",
      "Rumphi",
      "Salima",
      "Thyolo",
      "Zomba",
    ],
  },
  {
    country: "Malaysia",
    states: [
      "Johor",
      "Kedah",
      "Kelantan",
      "Kuala Lumpur",
      "Labuan",
      "Malacca",
      "Negeri Sembilan",
      "Pahang",
      "Perak",
      "Perlis",
      "Penang",
      "Sabah",
      "Sarawak",
      "Selangor",
      "Terengganu",
    ],
  },
  {
    country: "Maldives",
    states: [
      "Alifu",
      "Baa",
      "Dhaalu",
      "Faafu",
      "Gaafu Alifu",
      "Gaafu Dhaalu",
      "Gnaviyani",
      "Haa Alifu",
      "Haa Dhaalu",
      "Kaafu",
      "Laamu",
      "Lhaviyani",
      "Maale",
      "Meemu",
      "Noonu",
      "Raa",
      "Seenu",
      "Shaviyani",
      "Thaa",
      "Vaavu",
    ],
  },
  {
    country: "Mali",
    states: [
      "Bamako (Capital)",
      "Gao",
      "Kayes",
      "Kidal",
      "Koulikoro",
      "Mopti",
      "Segou",
      "Sikasso",
      "Tombouctou",
    ],
  },
  {
    country: "Malta",
    states: [],
  },
  {
    country: "Marshall Islands",
    states: [],
  },
  {
    country: "Mauritania",
    states: [
      "Adrar",
      "Assaba",
      "Brakna",
      "Dakhlet Nouadhibou",
      "Gorgol",
      "Guidimaka",
      "Hodh Ech Chargui",
      "Hodh El Gharbi",
      "Inchiri",
      "Nouakchott",
      "Tagant",
      "Tiris Zemmour",
      "Trarza",
    ],
  },
  {
    country: "Mauritius",
    states: [
      "Agalega Islands",
      "Black River",
      "Cargados Carajos Shoals",
      "Flacq",
      "Grand Port",
      "Moka",
      "Pamplemousses",
      "Plaines Wilhems",
      "Port Louis",
      "Riviere du Rempart",
      "Rodrigues",
      "Savanne",
    ],
  },
  {
    country: "Mexico",
    states: [
      "Aguascalientes",
      "Baja California",
      "Baja California Sur",
      "Campeche",
      "Chiapas",
      "Chihuahua",
      "Coahuila de Zaragoza",
      "Colima",
      "Distrito Federal",
      "Durango",
      "Guanajuato",
      "Guerrero",
      "Hidalgo",
      "Jalisco",
      "Mexico",
      "Michoacan de Ocampo",
      "Morelos",
      "Nayarit",
      "Nuevo Leon",
      "Oaxaca",
      "Puebla",
      "Queretaro de Arteaga",
      "Quintana Roo",
      "San Luis Potosi",
      "Sinaloa",
      "Sonora",
      "Tabasco",
      "Tamaulipas",
      "Tlaxcala",
      "Veracruz-Llave",
      "Yucatan",
      "Zacatecas",
    ],
  },
  {
    country: "Micronesia",
    states: [],
  },
  {
    country: "Moldova",
    states: [
      "Anenii Noi",
      "Basarabeasca",
      "Briceni",
      "Cahul",
      "Cantemir",
      "Calarasi",
      "Causeni",
      "Cimislia",
      "Criuleni",
      "Donduseni",
      "Drochia",
      "Dubasari",
      "Edinet",
      "Falesti",
      "Floresti",
      "Glodeni",
      "Hincesti",
      "Ialoveni",
      "Leova",
      "Nisporeni",
      "Ocnita",
      "Orhei",
      "Rezina",
      "Riscani",
      "Singerei",
      "Soldanesti",
      "Soroca",
      "Stefan-Voda",
      "Straseni",
      "Taraclia",
      "Telenesti",
      "Ungheni",
      "Balti",
      "Bender",
      "Chisinau",
      "Gagauzia",
      "Stinga Nistrului",
    ],
  },
  {
    country: "Mongolia",
    states: [
      "Arhangay",
      "Bayanhongor",
      "Bayan-Olgiy",
      "Bulgan",
      "Darhan Uul",
      "Dornod",
      "Dornogovi",
      "Dundgovi",
      "Dzavhan",
      "Govi-Altay",
      "Govi-Sumber",
      "Hentiy",
      "Hovd",
      "Hovsgol",
      "Omnogovi",
      "Orhon",
      "Ovorhangay",
      "Selenge",
      "Suhbaatar",
      "Tov",
      "Ulaanbaatar",
      "Uvs",
    ],
  },
  {
    country: "Morocco",
    states: [
      "Agadir",
      "Al Hoceima",
      "Azilal",
      "Beni Mellal",
      "Ben Slimane",
      "Boulemane",
      "Casablanca",
      "Chaouen",
      "El Jadida",
      "El Kelaa des Sraghna",
      "Er Rachidia",
      "Essaouira",
      "Fes",
      "Figuig",
      "Guelmim",
      "Ifrane",
      "Kenitra",
      "Khemisset",
      "Khenifra",
      "Khouribga",
      "Laayoune",
      "Larache",
      "Marrakech",
      "Meknes",
      "Nador",
      "Ouarzazate",
      "Oujda",
      "Rabat-Sale",
      "Safi",
      "Settat",
      "Sidi Kacem",
      "Tangier",
      "Tan-Tan",
      "Taounate",
      "Taroudannt",
      "Tata",
      "Taza",
      "Tetouan",
      "Tiznit",
    ],
  },
  {
    country: "Monaco",
    states: [],
  },
  {
    country: "Mozambique",
    states: [
      "Cabo Delgado",
      "Gaza",
      "Inhambane",
      "Manica",
      "Maputo",
      "Cidade de Maputo",
      "Nampula",
      "Niassa",
      "Sofala",
      "Tete",
      "Zambezia",
    ],
  },
  {
    country: "Namibia",
    states: [
      "Caprivi",
      "Erongo",
      "Hardap",
      "Karas",
      "Khomas",
      "Kunene",
      "Ohangwena",
      "Okavango",
      "Omaheke",
      "Omusati",
      "Oshana",
      "Oshikoto",
      "Otjozondjupa",
    ],
  },
  {
    country: "Nauru",
    states: [],
  },
  {
    country: "Nepal",
    states: [
      "Bagmati",
      "Bheri",
      "Dhawalagiri",
      "Gandaki",
      "Janakpur",
      "Karnali",
      "Kosi",
      "Lumbini",
      "Mahakali",
      "Mechi",
      "Narayani",
      "Rapti",
      "Sagarmatha",
      "Seti",
    ],
  },
  {
    country: "Netherlands",
    states: [
      "Drenthe",
      "Flevoland",
      "Friesland",
      "Gelderland",
      "Groningen",
      "Limburg",
      "Noord-Brabant",
      "Noord-Holland",
      "Overijssel",
      "Utrecht",
      "Zeeland",
      "Zuid-Holland",
    ],
  },
  {
    country: "New Zealand",
    states: [
      "Auckland",
      "Bay of Plenty",
      "Canterbury",
      "Chatham Islands",
      "Gisborne",
      "Hawke's Bay",
      "Manawatu-Wanganui",
      "Marlborough",
      "Nelson",
      "Northland",
      "Otago",
      "Southland",
      "Taranaki",
      "Tasman",
      "Waikato",
      "Wellington",
      "West Coast",
    ],
  },
  {
    country: "Nicaragua",
    states: [
      "Atlantico Norte",
      "Atlantico Sur",
      "Boaco",
      "Carazo",
      "Chinandega",
      "Chontales",
      "Esteli",
      "Granada",
      "Jinotega",
      "Leon",
      "Madriz",
      "Managua",
      "Masaya",
      "Matagalpa",
      "Nueva Segovia",
      "Rio San Juan",
      "Rivas",
    ],
  },
  {
    country: "Niger",
    states: [
      "Agadez",
      "Diffa",
      "Dosso",
      "Maradi",
      "Niamey",
      "Tahoua",
      "Tillaberi",
      "Zinder",
    ],
  },
  {
    country: "Nigeria",
    states: [
      "Abia",
      "Abuja Federal Capital",
      "Adamawa",
      "Akwa Ibom",
      "Anambra",
      "Bauchi",
      "Bayelsa",
      "Benue",
      "Borno",
      "Cross River",
      "Delta",
      "Ebonyi",
      "Edo",
      "Ekiti",
      "Enugu",
      "Gombe",
      "Imo",
      "Jigawa",
      "Kaduna",
      "Kano",
      "Katsina",
      "Kebbi",
      "Kogi",
      "Kwara",
      "Lagos",
      "Nassarawa",
      "Niger",
      "Ogun",
      "Ondo",
      "Osun",
      "Oyo",
      "Plateau",
      "Rivers",
      "Sokoto",
      "Taraba",
      "Yobe",
      "Zamfara",
    ],
  },
  {
    country: "Norway",
    states: [
      "Akershus",
      "Aust-Agder",
      "Buskerud",
      "Finnmark",
      "Hedmark",
      "Hordaland",
      "More og Romsdal",
      "Nordland",
      "Nord-Trondelag",
      "Oppland",
      "Oslo",
      "Ostfold",
      "Rogaland",
      "Sogn og Fjordane",
      "Sor-Trondelag",
      "Telemark",
      "Troms",
      "Vest-Agder",
      "Vestfold",
    ],
  },
  {
    country: "Oman",
    states: [
      "Ad Dakhiliyah",
      "Al Batinah",
      "Al Wusta",
      "Ash Sharqiyah",
      "Az Zahirah",
      "Masqat",
      "Musandam",
      "Dhofar",
    ],
  },
  {
    country: "Pakistan",
    states: [
      "Balochistan",
      "North-West Frontier Province",
      "Punjab",
      "Sindh",
      "Islamabad Capital Territory",
      "Federally Administered Tribal Areas",
    ],
  },
  {
    country: "Panama",
    states: [
      "Bocas del Toro",
      "Chiriqui",
      "Cocle",
      "Colon",
      "Darien",
      "Herrera",
      "Los Santos",
      "Panama",
      "San Blas",
      "Veraguas",
    ],
  },
  {
    country: "Papua New Guinea",
    states: [
      "Bougainville",
      "Central",
      "Chimbu",
      "Eastern Highlands",
      "East New Britain",
      "East Sepik",
      "Enga",
      "Gulf",
      "Madang",
      "Manus",
      "Milne Bay",
      "Morobe",
      "National Capital",
      "New Ireland",
      "Northern",
      "Sandaun",
      "Southern Highlands",
      "Western",
      "Western Highlands",
      "West New Britain",
    ],
  },
  {
    country: "Paraguay",
    states: [
      "Alto Paraguay",
      "Alto Parana",
      "Amambay",
      "Asuncion",
      "Boqueron",
      "Caaguazu",
      "Caazapa",
      "Canindeyu",
      "Central",
      "Concepcion",
      "Cordillera",
      "Guaira",
      "Itapua",
      "Misiones",
      "Neembucu",
      "Paraguari",
      "Presidente Hayes",
      "San Pedro",
    ],
  },
  {
    country: "Peru",
    states: [
      "Amazonas",
      "Ancash",
      "Apurimac",
      "Arequipa",
      "Ayacucho",
      "Cajamarca",
      "Callao",
      "Cusco",
      "Huancavelica",
      "Huanuco",
      "Ica",
      "Junin",
      "La Libertad",
      "Lambayeque",
      "Lima",
      "Loreto",
      "Madre de Dios",
      "Moquegua",
      "Pasco",
      "Piura",
      "Puno",
      "San Martin",
      "Tacna",
      "Tumbes",
      "Ucayali",
    ],
  },
  {
    country: "Philippines",
    states: [
      "Abra",
      "Agusan del Norte",
      "Agusan del Sur",
      "Aklan",
      "Albay",
      "Antique",
      "Apayao",
      "Aurora",
      "Basilan",
      "Bataan",
      "Batanes",
      "Batangas",
      "Biliran",
      "Benguet",
      "Bohol",
      "Bukidnon",
      "Bulacan",
      "Cagayan",
      "Camarines Norte",
      "Camarines Sur",
      "Camiguin",
      "Capiz",
      "Catanduanes",
      "Cavite",
      "Cebu",
      "Compostela",
      "Davao del Norte",
      "Davao del Sur",
      "Davao Oriental",
      "Eastern Samar",
      "Guimaras",
      "Ifugao",
      "Ilocos Norte",
      "Ilocos Sur",
      "Iloilo",
      "Isabela",
      "Kalinga",
      "Laguna",
      "Lanao del Norte",
      "Lanao del Sur",
      "La Union",
      "Leyte",
      "Maguindanao",
      "Marinduque",
      "Masbate",
      "Mindoro Occidental",
      "Mindoro Oriental",
      "Misamis Occidental",
      "Misamis Oriental",
      "Mountain Province",
      "Negros Occidental",
      "Negros Oriental",
      "North Cotabato",
      "Northern Samar",
      "Nueva Ecija",
      "Nueva Vizcaya",
      "Palawan",
      "Pampanga",
      "Pangasinan",
      "Quezon",
      "Quirino",
      "Rizal",
      "Romblon",
      "Samar",
      "Sarangani",
      "Siquijor",
      "Sorsogon",
      "South Cotabato",
      "Southern Leyte",
      "Sultan Kudarat",
      "Sulu",
      "Surigao del Norte",
      "Surigao del Sur",
      "Tarlac",
      "Tawi-Tawi",
      "Zambales",
      "Zamboanga del Norte",
      "Zamboanga del Sur",
      "Zamboanga Sibugay",
    ],
  },
  {
    country: "Poland",
    states: [
      "Greater Poland (Wielkopolskie)",
      "Kuyavian-Pomeranian (Kujawsko-Pomorskie)",
      "Lesser Poland (Malopolskie)",
      "Lodz (Lodzkie)",
      "Lower Silesian (Dolnoslaskie)",
      "Lublin (Lubelskie)",
      "Lubusz (Lubuskie)",
      "Masovian (Mazowieckie)",
      "Opole (Opolskie)",
      "Podlasie (Podlaskie)",
      "Pomeranian (Pomorskie)",
      "Silesian (Slaskie)",
      "Subcarpathian (Podkarpackie)",
      "Swietokrzyskie (Swietokrzyskie)",
      "Warmian-Masurian (Warminsko-Mazurskie)",
      "West Pomeranian (Zachodniopomorskie)",
    ],
  },
  {
    country: "Portugal",
    states: [
      "Aveiro",
      "Acores",
      "Beja",
      "Braga",
      "Braganca",
      "Castelo Branco",
      "Coimbra",
      "Evora",
      "Faro",
      "Guarda",
      "Leiria",
      "Lisboa",
      "Madeira",
      "Portalegre",
      "Porto",
      "Santarem",
      "Setubal",
      "Viana do Castelo",
      "Vila Real",
      "Viseu",
    ],
  },
  {
    country: "Qatar",
    states: [
      "Ad Dawhah",
      "Al Ghuwayriyah",
      "Al Jumayliyah",
      "Al Khawr",
      "Al Wakrah",
      "Ar Rayyan",
      "Jarayan al Batinah",
      "Madinat ash Shamal",
      "Umm Sa'id",
      "Umm Salal",
    ],
  },
  {
    country: "Romania",
    states: [
      "Alba",
      "Arad",
      "Arges",
      "Bacau",
      "Bihor",
      "Bistrita-Nasaud",
      "Botosani",
      "Braila",
      "Brasov",
      "Bucuresti",
      "Buzau",
      "Calarasi",
      "Caras-Severin",
      "Cluj",
      "Constanta",
      "Covasna",
      "Dimbovita",
      "Dolj",
      "Galati",
      "Gorj",
      "Giurgiu",
      "Harghita",
      "Hunedoara",
      "Ialomita",
      "Iasi",
      "Ilfov",
      "Maramures",
      "Mehedinti",
      "Mures",
      "Neamt",
      "Olt",
      "Prahova",
      "Salaj",
      "Satu Mare",
      "Sibiu",
      "Suceava",
      "Teleorman",
      "Timis",
      "Tulcea",
      "Vaslui",
      "Vilcea",
      "Vrancea",
    ],
  },
  {
    country: "Russia",
    states: [
      "Amur",
      "Arkhangel'sk",
      "Astrakhan'",
      "Belgorod",
      "Bryansk",
      "Chelyabinsk",
      "Chita",
      "Irkutsk",
      "Ivanovo",
      "Kaliningrad",
      "Kaluga",
      "Kamchatka",
      "Kemerovo",
      "Kirov",
      "Kostroma",
      "Kurgan",
      "Kursk",
      "Leningrad",
      "Lipetsk",
      "Magadan",
      "Moscow",
      "Murmansk",
      "Nizhniy Novgorod",
      "Novgorod",
      "Novosibirsk",
      "Omsk",
      "Orenburg",
      "Orel",
      "Penza",
      "Perm'",
      "Pskov",
      "Rostov",
      "Ryazan'",
      "Sakhalin",
      "Samara",
      "Saratov",
      "Smolensk",
      "Sverdlovsk",
      "Tambov",
      "Tomsk",
      "Tula",
      "Tver'",
      "Tyumen'",
      "Ul'yanovsk",
      "Vladimir",
      "Volgograd",
      "Vologda",
      "Voronezh",
      "Yaroslavl'",
      "Adygeya",
      "Altay",
      "Bashkortostan",
      "Buryatiya",
      "Chechnya",
      "Chuvashiya",
      "Dagestan",
      "Ingushetiya",
      "Kabardino-Balkariya",
      "Kalmykiya",
      "Karachayevo-Cherkesiya",
      "Kareliya",
      "Khakasiya",
      "Komi",
      "Mariy-El",
      "Mordoviya",
      "Sakha",
      "North Ossetia",
      "Tatarstan",
      "Tyva",
      "Udmurtiya",
      "Aga Buryat",
      "Chukotka",
      "Evenk",
      "Khanty-Mansi",
      "Komi-Permyak",
      "Koryak",
      "Nenets",
      "Taymyr",
      "Ust'-Orda Buryat",
      "Yamalo-Nenets",
      "Altay",
      "Khabarovsk",
      "Krasnodar",
      "Krasnoyarsk",
      "Primorskiy",
      "Stavropol'",
      "Moscow",
      "St. Petersburg",
      "Yevrey",
    ],
  },
  {
    country: "Rwanda",
    states: [
      "Butare",
      "Byumba",
      "Cyangugu",
      "Gikongoro",
      "Gisenyi",
      "Gitarama",
      "Kibungo",
      "Kibuye",
      "Kigali Rurale",
      "Kigali-ville",
      "Umutara",
      "Ruhengeri",
    ],
  },
  {
    country: "Samoa",
    states: [
      "A'ana",
      "Aiga-i-le-Tai",
      "Atua",
      "Fa'asaleleaga",
      "Gaga'emauga",
      "Gagaifomauga",
      "Palauli",
      "Satupa'itea",
      "Tuamasaga",
      "Va'a-o-Fonoti",
      "Vaisigano",
    ],
  },
  {
    country: "San Marino",
    states: [
      "Acquaviva",
      "Borgo Maggiore",
      "Chiesanuova",
      "Domagnano",
      "Faetano",
      "Fiorentino",
      "Montegiardino",
      "San Marino Citta",
      "Serravalle",
    ],
  },
  {
    country: "Sao Tome",
    states: [],
  },
  {
    country: "Saudi Arabia",
    states: [
      "Al Bahah",
      "Al Hudud ash Shamaliyah",
      "Al Jawf",
      "Al Madinah",
      "Al Qasim",
      "Ar Riyad",
      "Ash Sharqiyah",
      "'Asir",
      "Ha'il",
      "Jizan",
      "Makkah",
      "Najran",
      "Tabuk",
    ],
  },
  {
    country: "Senegal",
    states: [
      "Dakar",
      "Diourbel",
      "Fatick",
      "Kaolack",
      "Kolda",
      "Louga",
      "Matam",
      "Saint-Louis",
      "Tambacounda",
      "Thies",
      "Ziguinchor",
    ],
  },
  {
    country: "Serbia and Montenegro",
    states: ["Kosovo", "Montenegro", "Serbia", "Vojvodina"],
  },
  {
    country: "Seychelles",
    states: [
      "Anse aux Pins",
      "Anse Boileau",
      "Anse Etoile",
      "Anse Louis",
      "Anse Royale",
      "Baie Lazare",
      "Baie Sainte Anne",
      "Beau Vallon",
      "Bel Air",
      "Bel Ombre",
      "Cascade",
      "Glacis",
      "Grand' Anse",
      "Grand' Anse",
      "La Digue",
      "La Riviere Anglaise",
      "Mont Buxton",
      "Mont Fleuri",
      "Plaisance",
      "Pointe La Rue",
      "Port Glaud",
      "Saint Louis",
      "Takamaka",
    ],
  },
  {
    country: "Sierra Leone",
    states: [],
  },
  {
    country: "Singapore",
    states: [],
  },
  {
    country: "Slovakia",
    states: [
      "Banskobystricky",
      "Bratislavsky",
      "Kosicky",
      "Nitriansky",
      "Presovsky",
      "Trenciansky",
      "Trnavsky",
      "Zilinsky",
    ],
  },
  {
    country: "Slovenia",
    states: [
      "Ajdovscina",
      "Beltinci",
      "Benedikt",
      "Bistrica ob Sotli",
      "Bled",
      "Bloke",
      "Bohinj",
      "Borovnica",
      "Bovec",
      "Braslovce",
      "Brda",
      "Brezice",
      "Brezovica",
      "Cankova",
      "Celje",
      "Cerklje na Gorenjskem",
      "Cerknica",
      "Cerkno",
      "Cerkvenjak",
      "Crensovci",
      "Crna na Koroskem",
      "Crnomelj",
      "Destrnik",
      "Divaca",
      "Dobje",
      "Dobrepolje",
      "Dobrna",
      "Dobrova-Horjul-Polhov Gradec",
      "Dobrovnik-Dobronak",
      "Dolenjske Toplice",
      "Dol pri Ljubljani",
      "Domzale",
      "Dornava",
      "Dravograd",
      "Duplek",
      "Gorenja Vas-Poljane",
      "Gorisnica",
      "Gornja Radgona",
      "Gornji Grad",
      "Gornji Petrovci",
      "Grad",
      "Grosuplje",
      "Hajdina",
      "Hoce-Slivnica",
      "Hodos-Hodos",
      "Horjul",
      "Hrastnik",
      "Hrpelje-Kozina",
      "Idrija",
      "Ig",
      "Ilirska Bistrica",
      "Ivancna Gorica",
      "Izola-Isola",
      "Jesenice",
      "Jezersko",
      "Jursinci",
      "Kamnik",
      "Kanal",
      "Kidricevo",
      "Kobarid",
      "Kobilje",
      "Kocevje",
      "Komen",
      "Komenda",
      "Koper-Capodistria",
      "Kostel",
      "Kozje",
      "Kranj",
      "Kranjska Gora",
      "Krizevci",
      "Krsko",
      "Kungota",
      "Kuzma",
      "Lasko",
      "Lenart",
      "Lendava-Lendva",
      "Litija",
      "Ljubljana",
      "Ljubno",
      "Ljutomer",
      "Logatec",
      "Loska Dolina",
      "Loski Potok",
      "Lovrenc na Pohorju",
      "Luce",
      "Lukovica",
      "Majsperk",
      "Maribor",
      "Markovci",
      "Medvode",
      "Menges",
      "Metlika",
      "Mezica",
      "Miklavz na Dravskem Polju",
      "Miren-Kostanjevica",
      "Mirna Pec",
      "Mislinja",
      "Moravce",
      "Moravske Toplice",
      "Mozirje",
      "Murska Sobota",
      "Muta",
      "Naklo",
      "Nazarje",
      "Nova Gorica",
      "Novo Mesto",
      "Odranci",
      "Oplotnica",
      "Ormoz",
      "Osilnica",
      "Pesnica",
      "Piran-Pirano",
      "Pivka",
      "Podcetrtek",
      "Podlehnik",
      "Podvelka",
      "Polzela",
      "Postojna",
      "Prebold",
      "Preddvor",
      "Prevalje",
      "Ptuj",
      "Puconci",
      "Race-Fram",
      "Radece",
      "Radenci",
      "Radlje ob Dravi",
      "Radovljica",
      "Ravne na Koroskem",
      "Razkrizje",
      "Ribnica",
      "Ribnica na Pohorju",
      "Rogasovci",
      "Rogaska Slatina",
      "Rogatec",
      "Ruse",
      "Salovci",
      "Selnica ob Dravi",
      "Semic",
      "Sempeter-Vrtojba",
      "Sencur",
      "Sentilj",
      "Sentjernej",
      "Sentjur pri Celju",
      "Sevnica",
      "Sezana",
      "Skocjan",
      "Skofja Loka",
      "Skofljica",
      "Slovenj Gradec",
      "Slovenska Bistrica",
      "Slovenske Konjice",
      "Smarje pri Jelsah",
      "Smartno ob Paki",
      "Smartno pri Litiji",
      "Sodrazica",
      "Solcava",
      "Sostanj",
      "Starse",
      "Store",
      "Sveta Ana",
      "Sveti Andraz v Slovenskih Goricah",
      "Sveti Jurij",
      "Tabor",
      "Tisina",
      "Tolmin",
      "Trbovlje",
      "Trebnje",
      "Trnovska Vas",
      "Trzic",
      "Trzin",
      "Turnisce",
      "Velenje",
      "Velika Polana",
      "Velike Lasce",
      "Verzej",
      "Videm",
      "Vipava",
      "Vitanje",
      "Vodice",
      "Vojnik",
      "Vransko",
      "Vrhnika",
      "Vuzenica",
      "Zagorje ob Savi",
      "Zalec",
      "Zavrc",
      "Zelezniki",
      "Zetale",
      "Ziri",
      "Zirovnica",
      "Zuzemberk",
      "Zrece",
    ],
  },
  {
    country: "Solomon Islands",
    states: [
      "Central",
      "Choiseul",
      "Guadalcanal",
      "Honiara",
      "Isabel",
      "Makira",
      "Malaita",
      "Rennell and Bellona",
      "Temotu",
      "Western",
    ],
  },
  {
    country: "Somalia",
    states: [
      "Awdal",
      "Bakool",
      "Banaadir",
      "Bari",
      "Bay",
      "Galguduud",
      "Gedo",
      "Hiiraan",
      "Jubbada Dhexe",
      "Jubbada Hoose",
      "Mudug",
      "Nugaal",
      "Sanaag",
      "Shabeellaha Dhexe",
      "Shabeellaha Hoose",
      "Sool",
      "Togdheer",
      "Woqooyi Galbeed",
    ],
  },
  {
    country: "South Africa",
    states: [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "KwaZulu-Natal",
      "Limpopo",
      "Mpumalanga",
      "North-West",
      "Northern Cape",
      "Western Cape",
    ],
  },
  {
    country: "Spain",
    states: [
      "Andalucia",
      "Aragon",
      "Asturias",
      "Baleares",
      "Ceuta",
      "Canarias",
      "Cantabria",
      "Castilla-La Mancha",
      "Castilla y Leon",
      "Cataluna",
      "Comunidad Valenciana",
      "Extremadura",
      "Galicia",
      "La Rioja",
      "Madrid",
      "Melilla",
      "Murcia",
      "Navarra",
      "Pais Vasco",
    ],
  },
  {
    country: "Sri Lanka",
    states: [
      "Central",
      "North Central",
      "North Eastern",
      "North Western",
      "Sabaragamuwa",
      "Southern",
      "Uva",
      "Western",
    ],
  },
  {
    country: "Sudan",
    states: [
      "A'ali an Nil",
      "Al Bahr al Ahmar",
      "Al Buhayrat",
      "Al Jazirah",
      "Al Khartum",
      "Al Qadarif",
      "Al Wahdah",
      "An Nil al Abyad",
      "An Nil al Azraq",
      "Ash Shamaliyah",
      "Bahr al Jabal",
      "Gharb al Istiwa'iyah",
      "Gharb Bahr al Ghazal",
      "Gharb Darfur",
      "Gharb Kurdufan",
      "Janub Darfur",
      "Janub Kurdufan",
      "Junqali",
      "Kassala",
      "Nahr an Nil",
      "Shamal Bahr al Ghazal",
      "Shamal Darfur",
      "Shamal Kurdufan",
      "Sharq al Istiwa'iyah",
      "Sinnar",
      "Warab",
    ],
  },
  {
    country: "Suriname",
    states: [
      "Brokopondo",
      "Commewijne",
      "Coronie",
      "Marowijne",
      "Nickerie",
      "Para",
      "Paramaribo",
      "Saramacca",
      "Sipaliwini",
      "Wanica",
    ],
  },
  {
    country: "Swaziland",
    states: ["Hhohho", "Lubombo", "Manzini", "Shiselweni"],
  },
  {
    country: "Sweden",
    states: [
      "Blekinge",
      "Dalarnas",
      "Gavleborgs",
      "Gotlands",
      "Hallands",
      "Jamtlands",
      "Jonkopings",
      "Kalmar",
      "Kronobergs",
      "Norrbottens",
      "Orebro",
      "Ostergotlands",
      "Skane",
      "Sodermanlands",
      "Stockholms",
      "Uppsala",
      "Varmlands",
      "Vasterbottens",
      "Vasternorrlands",
      "Vastmanlands",
      "Vastra Gotalands",
    ],
  },
  {
    country: "Switzerland",
    states: [
      "Aargau",
      "Appenzell Ausser-Rhoden",
      "Appenzell Inner-Rhoden",
      "Basel-Landschaft",
      "Basel-Stadt",
      "Bern",
      "Fribourg",
      "Geneve",
      "Glarus",
      "Graubunden",
      "Jura",
      "Luzern",
      "Neuchatel",
      "Nidwalden",
      "Obwalden",
      "Sankt Gallen",
      "Schaffhausen",
      "Schwyz",
      "Solothurn",
      "Thurgau",
      "Ticino",
      "Uri",
      "Valais",
      "Vaud",
      "Zug",
      "Zurich",
    ],
  },
  {
    country: "Syria",
    states: [
      "Al Hasakah",
      "Al Ladhiqiyah",
      "Al Qunaytirah",
      "Ar Raqqah",
      "As Suwayda'",
      "Dar'a",
      "Dayr az Zawr",
      "Dimashq",
      "Halab",
      "Hamah",
      "Hims",
      "Idlib",
      "Rif Dimashq",
      "Tartus",
    ],
  },
  {
    country: "Taiwan",
    states: [
      "Chang-hua",
      "Chia-i",
      "Hsin-chu",
      "Hua-lien",
      "I-lan",
      "Kao-hsiung",
      "Kin-men",
      "Lien-chiang",
      "Miao-li",
      "Nan-t'ou",
      "P'eng-hu",
      "P'ing-tung",
      "T'ai-chung",
      "T'ai-nan",
      "T'ai-pei",
      "T'ai-tung",
      "T'ao-yuan",
      "Yun-lin",
      "Chia-i",
      "Chi-lung",
      "Hsin-chu",
      "T'ai-chung",
      "T'ai-nan",
      "Kao-hsiung city",
      "T'ai-pei city",
    ],
  },
  {
    country: "Tajikistan",
    states: [],
  },
  {
    country: "Tanzania",
    states: [
      "Arusha",
      "Dar es Salaam",
      "Dodoma",
      "Iringa",
      "Kagera",
      "Kigoma",
      "Kilimanjaro",
      "Lindi",
      "Manyara",
      "Mara",
      "Mbeya",
      "Morogoro",
      "Mtwara",
      "Mwanza",
      "Pemba North",
      "Pemba South",
      "Pwani",
      "Rukwa",
      "Ruvuma",
      "Shinyanga",
      "Singida",
      "Tabora",
      "Tanga",
      "Zanzibar Central/South",
      "Zanzibar North",
      "Zanzibar Urban/West",
    ],
  },
  {
    country: "Thailand",
    states: [
      "Amnat Charoen",
      "Ang Thong",
      "Buriram",
      "Chachoengsao",
      "Chai Nat",
      "Chaiyaphum",
      "Chanthaburi",
      "Chiang Mai",
      "Chiang Rai",
      "Chon Buri",
      "Chumphon",
      "Kalasin",
      "Kamphaeng Phet",
      "Kanchanaburi",
      "Khon Kaen",
      "Krabi",
      "Krung Thep Mahanakhon",
      "Lampang",
      "Lamphun",
      "Loei",
      "Lop Buri",
      "Mae Hong Son",
      "Maha Sarakham",
      "Mukdahan",
      "Nakhon Nayok",
      "Nakhon Pathom",
      "Nakhon Phanom",
      "Nakhon Ratchasima",
      "Nakhon Sawan",
      "Nakhon Si Thammarat",
      "Nan",
      "Narathiwat",
      "Nong Bua Lamphu",
      "Nong Khai",
      "Nonthaburi",
      "Pathum Thani",
      "Pattani",
      "Phangnga",
      "Phatthalung",
      "Phayao",
      "Phetchabun",
      "Phetchaburi",
      "Phichit",
      "Phitsanulok",
      "Phra Nakhon Si Ayutthaya",
      "Phrae",
      "Phuket",
      "Prachin Buri",
      "Prachuap Khiri Khan",
      "Ranong",
      "Ratchaburi",
      "Rayong",
      "Roi Et",
      "Sa Kaeo",
      "Sakon Nakhon",
      "Samut Prakan",
      "Samut Sakhon",
      "Samut Songkhram",
      "Sara Buri",
      "Satun",
      "Sing Buri",
      "Sisaket",
      "Songkhla",
      "Sukhothai",
      "Suphan Buri",
      "Surat Thani",
      "Surin",
      "Tak",
      "Trang",
      "Trat",
      "Ubon Ratchathani",
      "Udon Thani",
      "Uthai Thani",
      "Uttaradit",
      "Yala",
      "Yasothon",
    ],
  },
  {
    country: "Togo",
    states: ["Kara", "Plateaux", "Savanes", "Centrale", "Maritime"],
  },
  {
    country: "Tonga",
    states: [],
  },
  {
    country: "Trinidad and Tobago",
    states: [
      "Couva",
      "Diego Martin",
      "Mayaro",
      "Penal",
      "Princes Town",
      "Sangre Grande",
      "San Juan",
      "Siparia",
      "Tunapuna",
      "Port-of-Spain",
      "San Fernando",
      "Arima",
      "Point Fortin",
      "Chaguanas",
      "Tobago",
    ],
  },
  {
    country: "Tunisia",
    states: [
      "Ariana (Aryanah)",
      "Beja (Bajah)",
      "Ben Arous (Bin 'Arus)",
      "Bizerte (Banzart)",
      "Gabes (Qabis)",
      "Gafsa (Qafsah)",
      "Jendouba (Jundubah)",
      "Kairouan (Al Qayrawan)",
      "Kasserine (Al Qasrayn)",
      "Kebili (Qibili)",
      "Kef (Al Kaf)",
      "Mahdia (Al Mahdiyah)",
      "Manouba (Manubah)",
      "Medenine (Madanin)",
      "Monastir (Al Munastir)",
      "Nabeul (Nabul)",
      "Sfax (Safaqis)",
      "Sidi Bou Zid (Sidi Bu Zayd)",
      "Siliana (Silyanah)",
      "Sousse (Susah)",
      "Tataouine (Tatawin)",
      "Tozeur (Tawzar)",
      "Tunis",
      "Zaghouan (Zaghwan)",
    ],
  },
  {
    country: "Turkey",
    states: [
      "Adana",
      "Adiyaman",
      "Afyonkarahisar",
      "Agri",
      "Aksaray",
      "Amasya",
      "Ankara",
      "Antalya",
      "Ardahan",
      "Artvin",
      "Aydin",
      "Balikesir",
      "Bartin",
      "Batman",
      "Bayburt",
      "Bilecik",
      "Bingol",
      "Bitlis",
      "Bolu",
      "Burdur",
      "Bursa",
      "Canakkale",
      "Cankiri",
      "Corum",
      "Denizli",
      "Diyarbakir",
      "Duzce",
      "Edirne",
      "Elazig",
      "Erzincan",
      "Erzurum",
      "Eskisehir",
      "Gaziantep",
      "Giresun",
      "Gumushane",
      "Hakkari",
      "Hatay",
      "Igdir",
      "Isparta",
      "Istanbul",
      "Izmir",
      "Kahramanmaras",
      "Karabuk",
      "Karaman",
      "Kars",
      "Kastamonu",
      "Kayseri",
      "Kilis",
      "Kirikkale",
      "Kirklareli",
      "Kirsehir",
      "Kocaeli",
      "Konya",
      "Kutahya",
      "Malatya",
      "Manisa",
      "Mardin",
      "Mersin",
      "Mugla",
      "Mus",
      "Nevsehir",
      "Nigde",
      "Ordu",
      "Osmaniye",
      "Rize",
      "Sakarya",
      "Samsun",
      "Sanliurfa",
      "Siirt",
      "Sinop",
      "Sirnak",
      "Sivas",
      "Tekirdag",
      "Tokat",
      "Trabzon",
      "Tunceli",
      "Usak",
      "Van",
      "Yalova",
      "Yozgat",
      "Zonguldak",
    ],
  },
  {
    country: "Turkmenistan",
    states: [
      "Ahal Welayaty (Ashgabat)",
      "Balkan Welayaty (Balkanabat)",
      "Dashoguz Welayaty",
      "Lebap Welayaty (Turkmenabat)",
      "Mary Welayaty",
    ],
  },
  {
    country: "Uganda",
    states: [
      "Adjumani",
      "Apac",
      "Arua",
      "Bugiri",
      "Bundibugyo",
      "Bushenyi",
      "Busia",
      "Gulu",
      "Hoima",
      "Iganga",
      "Jinja",
      "Kabale",
      "Kabarole",
      "Kaberamaido",
      "Kalangala",
      "Kampala",
      "Kamuli",
      "Kamwenge",
      "Kanungu",
      "Kapchorwa",
      "Kasese",
      "Katakwi",
      "Kayunga",
      "Kibale",
      "Kiboga",
      "Kisoro",
      "Kitgum",
      "Kotido",
      "Kumi",
      "Kyenjojo",
      "Lira",
      "Luwero",
      "Masaka",
      "Masindi",
      "Mayuge",
      "Mbale",
      "Mbarara",
      "Moroto",
      "Moyo",
      "Mpigi",
      "Mubende",
      "Mukono",
      "Nakapiripirit",
      "Nakasongola",
      "Nebbi",
      "Ntungamo",
      "Pader",
      "Pallisa",
      "Rakai",
      "Rukungiri",
      "Sembabule",
      "Sironko",
      "Soroti",
      "Tororo",
      "Wakiso",
      "Yumbe",
    ],
  },
  {
    country: "Ukraine",
    states: [
      "Cherkasy",
      "Chernihiv",
      "Chernivtsi",
      "Crimea",
      "Dnipropetrovs'k",
      "Donets'k",
      "Ivano-Frankivs'k",
      "Kharkiv",
      "Kherson",
      "Khmel'nyts'kyy",
      "Kirovohrad",
      "Kiev",
      "Kyyiv",
      "Luhans'k",
      "L'viv",
      "Mykolayiv",
      "Odesa",
      "Poltava",
      "Rivne",
      "Sevastopol'",
      "Sumy",
      "Ternopil'",
      "Vinnytsya",
      "Volyn'",
      "Zakarpattya",
      "Zaporizhzhya",
      "Zhytomyr",
    ],
  },
  {
    country: "United Arab Emirates",
    states: [
      "Abu Dhabi",
      "'Ajman",
      "Al Fujayrah",
      "Sharjah",
      "Dubai",
      "Ra's al Khaymah",
      "Umm al Qaywayn",
    ],
  },
  {
    country: "United Kingdom",
    states: [],
  },
  {
    country: "United States",
    states: [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
  },
  {
    country: "Uruguay",
    states: [
      "Artigas",
      "Canelones",
      "Cerro Largo",
      "Colonia",
      "Durazno",
      "Flores",
      "Florida",
      "Lavalleja",
      "Maldonado",
      "Montevideo",
      "Paysandu",
      "Rio Negro",
      "Rivera",
      "Rocha",
      "Salto",
      "San Jose",
      "Soriano",
      "Tacuarembo",
      "Treinta y Tres",
    ],
  },
  {
    country: "Uzbekistan",
    states: [
      "Andijon Viloyati",
      "Buxoro Viloyati",
      "Farg'ona Viloyati",
      "Jizzax Viloyati",
      "Namangan Viloyati",
      "Navoiy Viloyati",
      "Qashqadaryo Viloyati",
      "Qaraqalpog'iston Respublikasi",
      "Samarqand Viloyati",
      "Sirdaryo Viloyati",
      "Surxondaryo Viloyati",
      "Toshkent Shahri",
      "Toshkent Viloyati",
      "Xorazm Viloyati",
    ],
  },
  {
    country: "Vanuatu",
    states: ["Malampa", "Penama", "Sanma", "Shefa", "Tafea", "Torba"],
  },
  {
    country: "Venezuela",
    states: [
      "Amazonas",
      "Anzoategui",
      "Apure",
      "Aragua",
      "Barinas",
      "Bolivar",
      "Carabobo",
      "Cojedes",
      "Delta Amacuro",
      "Dependencias Federales",
      "Distrito Federal",
      "Falcon",
      "Guarico",
      "Lara",
      "Merida",
      "Miranda",
      "Monagas",
      "Nueva Esparta",
      "Portuguesa",
      "Sucre",
      "Tachira",
      "Trujillo",
      "Vargas",
      "Yaracuy",
      "Zulia",
    ],
  },
  {
    country: "Vietnam",
    states: [
      "An Giang",
      "Bac Giang",
      "Bac Kan",
      "Bac Lieu",
      "Bac Ninh",
      "Ba Ria-Vung Tau",
      "Ben Tre",
      "Binh Dinh",
      "Binh Duong",
      "Binh Phuoc",
      "Binh Thuan",
      "Ca Mau",
      "Cao Bang",
      "Dac Lak",
      "Dac Nong",
      "Dien Bien",
      "Dong Nai",
      "Dong Thap",
      "Gia Lai",
      "Ha Giang",
      "Hai Duong",
      "Ha Nam",
      "Ha Tay",
      "Ha Tinh",
      "Hau Giang",
      "Hoa Binh",
      "Hung Yen",
      "Khanh Hoa",
      "Kien Giang",
      "Kon Tum",
      "Lai Chau",
      "Lam Dong",
      "Lang Son",
      "Lao Cai",
      "Long An",
      "Nam Dinh",
      "Nghe An",
      "Ninh Binh",
      "Ninh Thuan",
      "Phu Tho",
      "Phu Yen",
      "Quang Binh",
      "Quang Nam",
      "Quang Ngai",
      "Quang Ninh",
      "Quang Tri",
      "Soc Trang",
      "Son La",
      "Tay Ninh",
      "Thai Binh",
      "Thai Nguyen",
      "Thanh Hoa",
      "Thua Thien-Hue",
      "Tien Giang",
      "Tra Vinh",
      "Tuyen Quang",
      "Vinh Long",
      "Vinh Phuc",
      "Yen Bai",
      "Can Tho",
      "Da Nang",
      "Hai Phong",
      "Hanoi",
      "Ho Chi Minh",
    ],
  },
  {
    country: "Yemen",
    states: [
      "Abyan",
      "'Adan",
      "Ad Dali'",
      "Al Bayda'",
      "Al Hudaydah",
      "Al Jawf",
      "Al Mahrah",
      "Al Mahwit",
      "'Amran",
      "Dhamar",
      "Hadramawt",
      "Hajjah",
      "Ibb",
      "Lahij",
      "Ma'rib",
      "Sa'dah",
      "San'a'",
      "Shabwah",
      "Ta'izz",
    ],
  },
  {
    country: "Zambia",
    states: [
      "Central",
      "Copperbelt",
      "Eastern",
      "Luapula",
      "Lusaka",
      "Northern",
      "North-Western",
      "Southern",
      "Western",
    ],
  },
  {
    country: "Zimbabwe",
    states: [
      "Bulawayo",
      "Harare",
      "Manicaland",
      "Mashonaland Central",
      "Mashonaland East",
      "Mashonaland West",
      "Masvingo",
      "Matabeleland North",
      "Matabeleland South",
      "Midlands",
    ],
  },
];
