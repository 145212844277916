/* eslint-disable no-unused-vars */
import { Box, Button, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { StyledInputControlContainer } from "../../billingPlans/styles/BillingPlansStyles.js";
import FormSelect from "../../../common/FormSelect.js";
import { useParams } from "react-router-dom";
import FormField from "../../../common/FormField.js";
import {
  StyledFormContainer,
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
} from "../../../../styledComponents.js";
import FormPhone from "../../../common/FormPhone.js";
import FormPassword from "../../../common/FormPassword.js";
import { fetchAllUsers } from "../../../../services/userNew.service.js";
import { validateForm2 } from "../../../../utils/yupValidations.js";
import { usersSchema } from "../../../../utils/validation-schemas/usersSchema.js";

const UsersForm1 = ({
  user,
  onFormValueChange,
  validationErrors,
  setValidationErrors,
}) => {
  const [selectedOption, setSelectedOption] = useState(user.type || "New User");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(user.selectedUser || "");
  const { id } = useParams();

  const handleFieldChange = async (name, value) => {
    try {
      // Update the user state based on the field name
      setUsers((prevUser) => {
        const updatedUser = { ...prevUser };

        // Handle specific fields with custom logic
        if (name === "phone") {
          const phoneNumberParts = value.split(" ");
          updatedUser.areaCode = phoneNumberParts[0]; // +255
          updatedUser.phone = phoneNumberParts.slice(1).join(" "); // "21 233 44"
        } else if (name === "firstName") {
          updatedUser.firstName = value;
          updatedUser.selectedUser = value + " " + updatedUser.lastName;
        } else if (name === "lastName") {
          updatedUser.lastName = value;
          updatedUser.selectedUser = updatedUser.firstName + " " + value;
        } else {
          updatedUser[name] = value; // Default case
        }

        // Handle specific actions based on user type
        if (updatedUser.type === "New User") {
          onFormValueChange(updatedUser, name, value, 1);
        } else {
          onFormValueChange(updatedUser, name, value, 2);
        }

        return updatedUser; // Return the updated user object
      });

      // Validate the form field using validateForm2
      await validateForm2(
        { ...user, [name]: value }, // Pass updated value for validation
        name,
        setValidationErrors,
        usersSchema
      );
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };

  const handleSelectOption = (name) => {
    setSelectedOption(name);
    if (name === "New User") {
      onFormValueChange({
        ...user,
        selectedUser: user.firstName + " " + user.lastName,
        type: name,
      });
    } else {
      onFormValueChange({
        ...user,
        selectedUser: "",
        type: name,
      });
    }
  };

  const getUsers = async () => {
    const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));

    if (!accountId) {
      setUsers([]);
      return;
    }

    try {
      const response = await fetchAllUsers();

      const usersWithoutDefaultAccount = response.rows.filter((user) => {
        return user.accessRights.some(
          (access) => access.accountId === accountId.toString()
        );
      });

      setUsers(usersWithoutDefaultAccount);
    } catch (error) {
      if (error.response?.data) {
        console.log(error.response.data.message);
      } else {
        console.log(error.message);
      }
    }
  };

  const handleSelectUser = async (user) => {
    setSelectedUser(user);
    let updatedUser = {
      ...user,
      user,
      selectedUser: user.firstName + " " + user.lastName,
    };
    if (updatedUser.type === "New User") {
      onFormValueChange(updatedUser, "selectedUser", user.selectedDriver, 1);
    } else {
      onFormValueChange(updatedUser, "selectedUser", user.selectedDriver, 2);
    }
  };

  useEffect(() => {
    if (selectedOption === "Existing User") {
      getUsers();
    }
  }, [selectedOption]);

  return (
    <StyledFormContainer>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Basic Details</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          {id === undefined && (
            <StyledInputControlContainer>
              <Typography variant="label">User Type *</Typography>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    variant={
                      selectedOption === "New User" ? "contained" : "outlined"
                    }
                    onClick={() => handleSelectOption("New User")}
                  >
                    New User
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant={
                      selectedOption === "Existing User"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => handleSelectOption("Existing User")}
                  >
                    Existing User
                  </Button>
                </Grid>
              </Grid>
            </StyledInputControlContainer>
          )}
          {selectedOption === "New User" ? (
            <Box>
              <FormField
                label="First Name"
                required
                placeholder="Enter first name"
                value={user.firstName}
                onChange={(e) => handleFieldChange("firstName", e.target.value)}
                error={!!validationErrors.firstName}
                errorMessage={validationErrors.firstName}
              />
              <FormField
                label="Last Name"
                required
                placeholder="Enter last name"
                value={user.lastName}
                onChange={(e) => handleFieldChange("lastName", e.target.value)}
                error={!!validationErrors.lastName}
                errorMessage={validationErrors.lastName}
              />
              <FormField
                label="Email"
                required
                placeholder="Enter Email"
                value={user.email}
                onChange={(e) => handleFieldChange("email", e.target.value)}
                type="email"
                error={!!validationErrors.email}
                errorMessage={validationErrors.email}
              />
              <FormPhone
                label="Phone"
                value={user.areaCode + " " + user.phone}
                onChange={(newValue) => handleFieldChange("phone", newValue)}
                error={!!validationErrors.phone}
                errorMessage={validationErrors.phone}
                required
              />
            </Box>
          ) : (
            <FormSelect
              label="Choose User"
              required
              value={selectedUser}
              onChange={(event) => handleSelectUser(event)}
              options={users.map((item) => ({
                value: item,
                label: `${item.firstName} ${item.lastName}`,
              }))}
              error={!!validationErrors.selectedUser}
              errorMessage={validationErrors.selectedUser}
            />
          )}
        </StyledSectionRight>
      </StyledFormSection>

      <StyledFormSection noBorderBottom={true}>
        {selectedOption === "New User" && (
          <>
            <StyledSectionLeft>
              <Typography variant="heading">Credentials</Typography>
            </StyledSectionLeft>
            <StyledSectionRight>
              <FormField
                label="User Name"
                required
                placeholder="Enter user name"
                value={user.userName}
                onChange={(e) => handleFieldChange("userName", e.target.value)}
                error={!!validationErrors.userName}
                errorMessage={validationErrors.userName}
              />
              {id === undefined && (
                <>
                  <FormPassword
                    label="Password"
                    value={user.password}
                    onChange={(e) =>
                      handleFieldChange("password", e.target.value)
                    }
                    error={!!validationErrors.password}
                    errorMessage={validationErrors.password}
                    required
                  />
                  <FormPassword
                    label="Confirm Password"
                    value={user.confirmPassword}
                    onChange={(e) =>
                      handleFieldChange("confirmPassword", e.target.value)
                    }
                    error={!!validationErrors.confirmPassword}
                    errorMessage={validationErrors.confirmPassword}
                    required
                  />
                </>
              )}
            </StyledSectionRight>
          </>
        )}
      </StyledFormSection>
    </StyledFormContainer>
  );
};

UsersForm1.propTypes = {
  user: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  setValidationErrors: PropTypes.func.isRequired,
};

export default UsersForm1;
