/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SearchFormSelect from "../../../common/SearchFormSelect";
import FilterListIcon from "@mui/icons-material/FilterList";
import { reportTypes } from "../../../../utils/constant";
import { fetchAllAccounts } from "../../../../services/accountNew.service";
import SelectedDateRange from "../../../common/SelectedDateRange";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // if using UTC
import { fetchAllDevices } from "../../../../services/deviceNew.services";

dayjs.extend(utc);

const ReportsFilters = ({ filters, onFiltersChange }) => {
  const [reportType, setReportType] = useState(""); // Track selected report type
  const [accounts, setAccounts] = useState([]);
  const [devices, setDevices] = useState([]);
  const [page] = useState(1);
  const [limit] = useState(15);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const roleName = "Driver";

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters]);

  useEffect(() => {
    _fetchAllAccounts();
    _fetchAllDevices();
  }, [page, limit]);

  const _fetchAllAccounts = async () => {
    if (isLoadingAccounts) return; // Prevent multiple calls
    setIsLoadingAccounts(true);

    try {
      let allAccounts = [];
      let currentPage = 1;
      let totalFetched = 0;

      let data;
      do {
        data = await fetchAllAccounts(currentPage, limit);
        allAccounts = [...allAccounts, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      setAccounts(allAccounts);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching accounts:", errorMessage);
    } finally {
      setIsLoadingAccounts(false);
    }
  };

  const _fetchAllDevices = async () => {
    if (isLoadingDevices) return; // Prevent multiple calls
    setIsLoadingDevices(true);

    try {
      let allDevices = [];
      let currentPage = 1;
      let totalFetched = 0;

      let data;
      do {
        data = await fetchAllDevices(currentPage, limit);
        allDevices = [...allDevices, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      setDevices(allDevices);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching devices:", errorMessage);
    } finally {
      setIsLoadingDevices(false);
    }
  };
  const handleReportTypeChange = (event) => {
    const selectedType = event.target.value;
    setReportType(selectedType);
    onFiltersChange({ ...filters, reportType: selectedType }); // Pass report type to parent
  };

  const handleFieldChange = (field, value) => {

    setReportType(value);
    let formattedValue = value;

    if (field === "dateRange") {
      const { startDate, endDate } = value;
      onFiltersChange({
        ...filters,
        startDate: startDate ? dayjs(startDate).utc().toISOString() : null,
        endDate: endDate ? dayjs(endDate).utc().toISOString() : null,
      });
    } else {
      // Only format dates for fields other than 'deviceId'
      if (field !== "deviceId") {
        if (value instanceof dayjs && value.isValid()) {
          formattedValue = value.toISOString();
        } else if (value instanceof Date && !isNaN(value)) {
          formattedValue = dayjs(value).toISOString();
        } else if (typeof value === "string" && dayjs(value).isValid()) {
          formattedValue = dayjs(value).toISOString();
        }
      }

      onFiltersChange({
        ...filters,
        [field]: formattedValue,
      });

      console.log(`Handling field change: ${field} = ${formattedValue}`);
    }
  };

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex" }}>
        <Grid item xs={12} sm={1} sx={{ display: "flex" }}>
          <Grid
            sx={{ marginTop: "20px", color: "#6C6C81", fontWeight: "600" }}
            mr={1}
          >
            FILTERS
          </Grid>
          <FilterListIcon sx={{ marginTop: "20px" }} />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Report Type"
            value={filters.reportTypes}
            onChange={(selected) => {
              handleFieldChange("reportTypes", selected);
            }}
            options={reportTypes.map((item) => ({
              value: item.value,
              label: item.name,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Account"
            value={filters.accountId}
            onChange={(selected) => {
              handleFieldChange("accountId", selected);
            }}
            options={accounts.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Device"
            value={filters.deviceId}
            onChange={(selected) => {
              handleFieldChange("deviceId", String(selected)); // Explicitly set as string
            }}
            options={devices.map((item) => ({
              value: item.name, // Ensure name is string here if needed
              label: item.name,
            }))}
          />
        </Grid>

        <Grid item xs={12} sm={3.5}>
          <SelectedDateRange
            value={[
              filters.startDate ? new Date(filters.startDate) : null,
              filters.endDate ? new Date(filters.endDate) : null,
            ]}
            onChange={(dates) => handleFieldChange("dateRange", dates)}
            error={false}
            errorMessage=""
            placeholder="Date Range"
          />
        </Grid>
      </Grid>
    </>
  );
};
ReportsFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired, // Validate that onFiltersChange is a required function prop
};

export default ReportsFilters;
