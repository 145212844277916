export const ConsignmentModel = {
  orderNumber: null,
  customerId: null,
  accountId: null,
  unitId: null,
  driverId: null,
  status: "NOT ASSIGNED",
  pickupDetails: {
    address: {
      addressType: null,
      geofenceId: null,
      streetOne: null,
      streetTwo: null,
      city: null,
      state: null,
      postalCode: null,
      country: null,
    },
    name: null,
    areaCode: null,
    phone: null,
  },
  deliveryDetails: {
    addressId: null,
    name: null,
    areaCode: null,
    phone: null,
  },
  cargoDetails: {
    totalWeight: null,
    items: [
      {
        cargoType: {
          name: null,
          info: null,
        },
        quantity: 0,
        weight: 0,
        length: 0,
        breadth: 0,
        height: 0,
        cubicMetres: 0,
        description: null,
        status: null,
        reasons: [],
      },
    ],
  },
  deliverySchedule: {
    expectedPickupDate: null,
    expectedDeliveryDate: null,
    checkpoints: [],
    pickupTime: {
      from: null,
      to: null,
    },
    deliveryTime: {
      from: null,
      to: null,
    },
  },

  palletDetails: {
    expectedReturnDate: null,
    returnPallets: false,
    returnAddress: {
      addressType: null,
      geofenceId: null,
      streetOne: null,
      streetTwo: null,
      city: null,
      state: null,
      postalCode: null,
      country: null,
    },
  },
  insuranceAndCustomsDocuments: [],
  otherDocuments: [],
  pickupDetailDocument: [
    {
      fileURL: null,
      file: null,
    },
  ],
  deliveryDetailDocument: [
    {
      fileURL: null,
      file: null,
    },
  ],
  pickupSignature: {
    fileURL: null,
    file: null,
  },
  deliverySignature: {
    fileURL: "",
    file: null,
  },
  additionalInformation: {
    transportationMode: "Truck",
    specialInstructions: null,
  },
  billingInformation: {
    paymentTerm: "Payment in advance",
    paymentTermValue: null,
    rateFinalized: false,
    amount: null,
    creditDays: null,
    currency: null,
    VAT: null,
    notes: null,
  },
  tripStatus: "RECEIVED",
  assignedBy: null,
  receivedDateTime: null,
  unitAllocationDateTime: null,
  driverAllocationDateTime: null,
  driverAcceptedDateTime: null,
  driverDeclinedDateTime: null,
  startDateTime: null,
  pickupDateTime: null,
  loadingCompletedDateTime: null,
  enrouteDateTime: null,
  arrivedDateTime: null,
  deliveredDateTime: null,
};
