/* eslint-disable react/prop-types */
import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Box, Button, Grid, Typography } from "@mui/material";
import logo from "../../../../assets/logo/Off@3x.png";

const DriverRunSheet = ({ data }) => {
  const generatePDF = () => {
    const input = document.getElementById("run-sheet");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("Driver_Run_Sheet.pdf");
    });
  };

  return (
    <div>
      <Grid id="run-sheet" sx={{ padding: "20px" }}>
        {/* <h2 style={{ textAlign: "center" }}>Easy Track Solution</h2> */}
        <Grid>
          <Box sx={{ textAlign: "center", margin: "20px 10px" }}>
            <img
              src={logo}
              alt="Logo"
              style={{ width: "25%", height: "auto" }}
            />
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              margin: "20px 10px",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            EASY TRACK SOLUTIONS Driver run sheet
          </Typography>
        </Grid>

        <div>
          <table
            border="1"
            cellPadding="5"
            style={{
              width: "100%",
              borderCollapse: "collapse",
              textAlign: "center",
            }}
          >
            <thead>
              <tr>
                <th style={{ verticalAlign: "middle", padding: "10px" }}>
                  Date
                </th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}>
                  {data.date}
                </td>
                <th style={{ verticalAlign: "middle", padding: "10px" }}>
                  Start
                </th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}>
                  {data.startShiftTime}
                </td>
                <th style={{ verticalAlign: "middle", padding: "10px" }}>
                  Start Break 1
                </th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}>
                  {data.startBreak1}
                </td>
                <th style={{ verticalAlign: "middle", padding: "10px" }}>
                  Finish Break 1
                </th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}>
                  {data.endBreak1}
                </td>
              </tr>
            </thead>
            <thead>
              <tr>
                <th style={{ verticalAlign: "middle", padding: "10px" }}>
                  Driver Name
                </th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}>
                  {data.driverName}
                </td>
                <th style={{ verticalAlign: "middle", padding: "10px" }}>
                  Finish
                </th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}>
                  {data.endShiftTime}
                </td>
                <th style={{ verticalAlign: "middle", padding: "10px" }}>
                  Start Break 2
                </th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}>
                  {data.startBreak2}
                </td>
                <th style={{ verticalAlign: "middle", padding: "10px" }}>
                  Finish Break 2
                </th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}>
                  {data.endBreak2}
                </td>
              </tr>
            </thead>
            <thead>
              <tr>
                <th style={{ verticalAlign: "middle", padding: "10px" }}>
                  Reg No
                </th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}>
                  {data.vehicleRegistrationNumber}
                </td>
                <th style={{ verticalAlign: "middle", padding: "10px" }}>
                  Total
                </th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}>
                  {data.totalHours}
                </td>
                <th style={{ verticalAlign: "middle", padding: "10px" }}>
                  Start Break 3
                </th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}>
                  {data.startBreak3}
                </td>
                <th style={{ verticalAlign: "middle", padding: "10px" }}>
                  Finish Break 3
                </th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}>
                  {data.endBreak3}
                </td>
              </tr>
            </thead>
            <thead>
              <tr>
                <th style={{ verticalAlign: "middle", padding: "10px" }}>
                  Fleet Name
                </th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}>
                  {data.fleetName}
                </td>
                <th style={{ verticalAlign: "middle", padding: "10px" }}></th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}></td>
                <th style={{ verticalAlign: "middle", padding: "10px" }}>
                  Start Break 4
                </th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}>
                  {data.startBreak4}
                </td>
                <th style={{ verticalAlign: "middle", padding: "10px" }}>
                  Finish Break 4
                </th>
                <td style={{ verticalAlign: "middle", padding: "10px" }}>
                  {data.endBreak4}
                </td>
              </tr>
            </thead>
          </table>
          <table
            border="1"
            cellPadding="5"
            style={{
              width: "100%",
              borderCollapse: "collapse",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            <thead>
              <tr>
                <th>Consignment No</th>
                <th>Customer Name</th>
                <th>Address</th>
                <th>Pickup Time</th>
                <th>Loading Completed</th>
                <th>Delivered At</th>
                <th>Trip Status</th>
                <th>Signature</th>
                <th>Received By</th>
              </tr>
            </thead>
            <tbody>
              {data.consignments.map((consignment, index) => (
                <tr key={index}>
                  <td>{consignment.consignmentNo}</td>
                  <td>{consignment.customerName}</td>
                  <td>{consignment.Address}</td>
                  <td>{consignment.pickupAt}</td>
                  <td>{consignment.loadingCompletedAt}</td>
                  <td>{consignment.deliveredAt}</td>
                  <td>{consignment.tripStatus}</td>
                  <td>{consignment.signature}</td>
                  <td>{consignment.receivedBy}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Grid>
      <Button
        sx={{
          backgroundColor: "#1498e3",
          color: "white",
          margin: "20px 20px 0px 0px",
          "&:hover": {
            backgroundColor: "blue",
            color: "white",
          },
        }}
        onClick={generatePDF}
      >
        Download PDF
      </Button>
    </div>
  );
};

export default DriverRunSheet;
