/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Grid,
  IconButton
} from '@mui/material'
import UsersForm1 from './UsersForm1'
import UsersForm2 from './UsersForm2'
import UsersForm3 from './UsersForm3'
import { UsersModel } from '../../../../model/users.model'
import { ServiceModel } from '../../../../model/services.model'
import { useNavigate, useParams } from 'react-router-dom'
import { Title } from '../styles/UsersStyles'
import ErrorMessage1 from '../../../common/ErrorMessage1'
import SuccessMessage from '../../../common/SuccessMessage'

import { createTheme } from '@mui/system'
import { fetchAllServices } from '../../../../services/serviceNew.services'
import { getAccountById } from '../../../../services/accountNew.service'
import { createUser, getUserById } from '../../../../services/userNew.service'
import { updateUser } from '../../../../services/userNew.service'
import { validateForm, validateForm2 } from '../../../../utils/yupValidations'
import {
  userSchemaCreateStep1,
  userSchemaEditStep1,
  userSchemaStep2,
  usersSchema
} from '../../../../utils/validation-schemas/usersSchema'
import Loader from '../../../common/Loader'
import { appendFormData } from '../../../../utils/functions'
import { BackButton, NextButton } from '../../../../styledComponents'
import { getRoute } from '../../../../constants/routes'
import { iconMap } from '../../../../constants/iconsList'

const steps = ['User Details', 'Roles & Specifications', 'Access Rights']


const UserForm = () => {
  const { id } = useParams()
  const userId = id
  const [activeStep, setActiveStep] = useState(0)
  const [errors, setErrors] = useState([])
  const [successMessage, setSuccessMessage] = useState('')
  const [user, setUser] = useState(UsersModel)
  const [services, setServices] = useState(ServiceModel)
  const [validationErrors, setValidationErrors] = useState({})
  const navigate = useNavigate()
  const [limit] = useState(99)
  // const [userType, setUserType] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  // eslint-disable-next-line no-unused-vars
  const [defaultsSet, setDefaultsSet] = useState(false)
  const defaultAccountId =
    localStorage.getItem('defaultAccountId') !== ''
      ? JSON.parse(localStorage.getItem('defaultAccountId'))
      : ''
  const validationSchemas = {
    1: userSchemaCreateStep1,
    2: userSchemaEditStep1,
    3: userSchemaStep2
  }

  useEffect(() => {
    if (id == undefined) {
      _fetchAllServices()
    }
    if (id) {
      _getUserById(id)
    } else {
      setDefaults()
    }
  }, [id])

  //Map Access rights to Billing Plans
  const _fetchAllServices = async () => {
    try {
      const data = await fetchAllServices(page, limit)
      setServices(data.rows)

      // Create an array to hold the updated servicesLimit
      const updatedAccessRights = []

      // Loop through the data.rows to create servicesLimit objects array
      data.rows.forEach((service) => {
        updatedAccessRights.push({
          serviceId: service.id,
          serviceName: service.name,
          serviceValue: false,
          add: false,
          edit: false,
          view: false,
          delete: false,
          type: service.type
        })
      })
      // Update the billingPlan state once after the loop
      const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))
      var accountDetail = await getAccountById(accountId)

      if (user.type == 'New User') {
        setUser({
          ...user,
          accessRights: [
            {
              accountId: accountId,
              accountName: accountDetail.name,
              permissions: updatedAccessRights
            }
          ]
        })
      } else {
        setUser({
          ...user,
          accessRights: [
            ...user.accessRights,
            {
              accountId: accountId,
              accountName: accountDetail.name,
              permissions: updatedAccessRights
            }
          ]
        })
      }
      console.log(user)
    } catch (error) {
      // handleFetchError(error);
    }
  }

  const fetchUpdateServices = async (user) => {
    try {
      const data = await fetchAllServices()
      setServices(data.rows)

      // Create an array to hold the updated servicesLimit
      const updatedAccessRights = []

      // Loop through the data.rows to create servicesLimit objects array
      data.rows.forEach((service) => {
        updatedAccessRights.push({
          serviceId: service.id,
          serviceName: service.name,
          serviceValue: false,
          add: false,
          edit: false,
          view: false,
          delete: false,
          type: service.type
        })
      })
      // Update the billingPlan state once after the loop
      const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))
      var accountDetail = await getAccountById(accountId)
      console.log(user)
      const existingAccessRights = user.accessRights.find(
        (access) => access.accountId === accountId
      )
      if (existingAccessRights) {
        // Helper function to check if a permission already exists based on serviceName
        const isDuplicate = (newPermission, existingPermissions) => {
          return existingPermissions.some(
            (permission) => permission.serviceName === newPermission.serviceName
          )
        }
        // Filter out duplicates in newPermissions based on serviceName
        const newPermissions = updatedAccessRights.filter(
          (permission) =>
            !isDuplicate(permission, existingAccessRights.permissions)
        )
        console.log(newPermissions)

        const updatedAccessRightsList = user.accessRights.map((access) =>
          access.accountId === accountId
            ? {
                ...access,
                permissions: [...access.permissions, ...newPermissions]
              }
            : access
        )
        console.log(updatedAccessRightsList)

        setUser({
          ...user,
          accessRights: updatedAccessRightsList
        })
      }
      console.log(user)
    } catch (error) {
      // handleFetchError(error);
    }
  }

  const _getUserById = async (id) => {
    setIsLoading(true)
    try {
      const data = await getUserById(id)
      var userData = {
        ...data,
        selectedUser: data.firstName + ' ' + data.lastName
      }
      setUser(userData)

      await fetchUpdateServices(userData)
      handleResponse(data, 'User fetched successfully!', 'Get');
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false)
    }
  }

  const setDefaults = () => {
    const updatedDriver = {
      ...user,
      accountId: defaultAccountId
    }
    setUser(updatedDriver)
    setDefaultsSet(true)
  }
  //To store values changed from form steps
  const handleValueChange = async (
    updatedUser,
    fieldName,
    fieldValue,
    step
  ) => {
    setUser((prevPlan) => ({
      ...prevPlan,
      selectedUser: updatedUser.firstName + ' ' + updatedUser.lastName,
      ...updatedUser
    }))

    const schema = validationSchemas[step]
    try {
      await validateForm2(updatedUser, fieldName, setValidationErrors, schema)
    } catch (error) {
      console.error('Field validation error:', error)
    }
  }
  //To move to next step
  const handleNext = async () => {
    console.log(user.type)
    try {
      let isValid
      if (activeStep === 0) {
        if (user.type == 'New User') {
          isValid = await validateForm(
            user,
            setValidationErrors,
            validationSchemas[1]
          )
        } else {
          isValid = await validateForm(
            user,
            setValidationErrors,
            validationSchemas[2]
          )
        }
      } else if (activeStep === 1) {
        isValid = await validateForm(
          user,
          setValidationErrors,
          validationSchemas[3]
        )
      }
      console.log(validationErrors)
      console.log(isValid)
      // Proceed to the next step if there are no errors
      if (isValid) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      } else {
        console.error('Form validation failed')
      }
    } catch (error) {
      handleError(error);
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const isValid = await validateForm(
        user,
        setValidationErrors,
        validationSchemas[3]
      )
      if (!isValid) {
        console.error('Form validation failed')
        setIsLoading(false)
        return
      }
      if (!isValid) {
        console.error('Form validation failed')
        setIsLoading(false)
        return
      }
      let response

      const formData = new FormData()
      appendFormData(formData, user)

      if (user.type === 'New User') {
        response = await createUser(formData)
        handleResponse(response, 'User created successfully!', 'Card')
      } else {
        response = await updateUser(user.id, formData)
        handleResponse(response, 'User updated successfully!', 'Card')
      }
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
      setTimeout(() => {
        if (mode === "Card") {
          navigate(getRoute("User", mode));
        }
      }, 3000); // Closing parentheses and braces for setTimeout
    }
  };

  const handleError = (error) => {
    window.scrollTo(0, 0)
    if (error.name === 'ValidationError') {
      handleValidationErrors(error)
    } else {
      setErrors(error.error)
    }
  }

  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message
      return acc
    }, {})
    setValidationErrors(errors)
  }

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage('');
  };
  
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <UsersForm1
            user={user}
            onFormValueChange={handleValueChange}
            validationErrors={validationErrors}
            validationSchema={
              id == undefined ? userSchemaCreateStep1 : userSchemaEditStep1
            }
            setValidationErrors={setValidationErrors}
          />
        )
      case 1:
        return (
          <UsersForm2
            user={user}
            services={services}
            onFormValueChange={handleValueChange}
            validationErrors={validationErrors}
            validationSchema={usersSchema}
            setValidationErrors={setValidationErrors}
          />
        )
      case 2:
        return (
          <UsersForm3 user={user} onFormValueChange={handleValueChange} />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  const getStepColor = (index) => {
    if (index < activeStep) {
      return '#26861D' // Completed step color
    } else if (index === activeStep) {
      return '#0772b8' // Active step color
    } else {
      return '#EEEEF0' // Inactive step color
    }
  }
  // Check if id is undefined and defaultSet is true
  if (id === undefined && !defaultsSet) {
    return null // or any other fallback UI you want to show
  }

  const handleBackClick = () => {
    navigate(-1);
    };

  return (
    <Box>
      <ErrorMessage1 errors={errors} onClose={handleClose} />
      <SuccessMessage message={successMessage} onClose={handleClose} />
      {isLoading && <Loader />}
      <Box sx={{ width: '100%', padding: 'Calc(2.3%-50px) Calc(10.8%-50px)' }}>
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #d8d8df',
            paddingBottom: '26.5px'
          }}
        >
          <Grid xs={12} md={5}>
            <Title item> <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
              <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
            </IconButton>{id ? 'Update' : 'Create'} User</Title>
          </Grid>
          <Grid item xs={12} md={7} sx={{ position: 'relative', right: '8px' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step
                  key={label}
                  sx={{
                    '& .MuiStepIcon-root': {
                      height: '32px',
                      width: '32px',
                      color: getStepColor(index)
                    },
                    '& .MuiStepIcon-root.Mui-active': {
                      color: '#0772B8' // Active step color
                    },
                    '& .MuiStepIcon-text': {
                      fill: '#5B5B6E' // Change this to your desired number color
                    },
                    '& .MuiStepIcon-root.Mui-active .MuiStepIcon-text': {
                      fill: '#FFFFFF'
                    }
                  }}
                >
                  <StepLabel
                    optional={
                      <Typography
                        style={{
                          fontWeight: index === activeStep ? 600 : 400,
                          color: '#0F446B',
                          fontSize: '16px'
                        }}
                      >
                        {label}
                      </Typography>
                    }
                  ></StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 3, marginBottom: 3 }}>
          {activeStep === steps.length ? (
            <Box>
              <Typography sx={{ mt: 2 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          ) : (
            <Box>
              {getStepContent(activeStep, validationErrors)}
              <Grid
                xs={12}
                sx={{
                  mt: 3,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end'
                }}
              >
                <BackButton disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </BackButton>
                <NextButton
                  onClick={
                    activeStep === steps.length - 1 ? handleSubmit : handleNext
                  }
                >
                  {activeStep === steps.length - 1
                    ? 'FINISH'
                    : 'SAVE & CONTINUE'}
                </NextButton>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default UserForm
