/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  Grid,
  Menu,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteModal from "../../../modals/DeleteModal";
import Loader from "../../../common/Loader";
import {
  StyledIconButton,
  StyledMenuItem,
  StyledTableContainer,
  StyledTableHead,
} from "../../../../styledComponents";
import { geofenceTableColumnNew } from "../../../../utils/constant";
import EmptyState from "../../../common/EmptyState";
import debounce from "lodash.debounce";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";

const GeofencesListTable = () => {
  const [errors, setErrors] = useState([]);
  const [openMenuGeofenceId, setOpenMenuGeofenceId] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);

  const navigate = useNavigate();
  const { geofences, fetchMore, isLoading, isEmpty } = useOutletContext();
  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [geofences, isLoading, handleScroll]);

  // Function to open the menu on click of 3 dots in the list cards/tables
  // Callbacks wrapped with useCallback for performance optimization
  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuGeofenceId(selectedItem.id); // Set the ID of the account whose menu should be open
    setSelectedItem(selectedItem);
  }, []);

  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuGeofenceId(null);
    event?.stopPropagation();
  }, []);

  const handleDeleteModalOpen = useCallback(
    (event, geofenceId) => {
      handleActionsMenuClose();
      setSelectedItem(geofenceId);
      setOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setOpen]
  );

  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setOpen]);

  // Function that calls the API to perform deleteAccount
  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteGeofence(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, 'Geofence deleted successfully.')
    //     handleError([])
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error)
    // } finally {
    //   setOpen(false);
    //   setSelectedItem(null);
    // }
  };

  // // Function that calls the API to perform deleteGeofence
  // const deleteGeofence = useCallback(() => {
  //   setOpen(false);
  //   // setSelectedItem(null)
  // }, [setOpen, setSelectedItem]);

  useEffect(() => {
    if (selectedItem) {
      console.log("Selected Geofence has been set:", selectedItem);
    }
  }, [selectedItem]);

  const navigateToEditScreen = useCallback(
    (geofenceId) => {
      navigate(`/monitoring/geofences/edit/${geofenceId}`);
    },
    [navigate]
  );

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
    }
  };
  const handleError = (error) => {
    window.scrollTo(0, 0);
    // if (error.name === 'ValidationError') {
    //   handleValidationErrors(error)
    // } else {
    setErrors(error.error);
    // }
  };

  return (
    <>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledTableContainer ref={containerRef}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="GEOFENCES"
              message="Oops! You have no geofences for your search criteria"
              info="Please feel free to create a new geofence by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {!isLoading && !isEmpty && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <StyledTableHead>
                <TableRow>
                  {geofenceTableColumnNew.map((column) => (
                    <TableCell key={column}>
                      <Typography variant="tableHeader">{column}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {geofences?.map((geofence) => (
                  <TableRow
                    key={geofence.id}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigateToEditScreen(geofence.id)}
                  >
                    <TableCell component="th" scope="row">
                      <Typography variant="tableValue">
                        {geofence.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="tableValue">
                        {geofence.radius || "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="tableValue">
                        {geofence?.center?.lat}, {geofence?.center?.lng}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="tableValue">
                        {geofence.description || "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <StyledIconButton
                        id={`menu-button-${geofence.id}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleActionsMenuOpen(event, geofence);
                        }}
                      >
                        <MoreVertIcon />
                      </StyledIconButton>
                      <Menu
                        anchorEl={document.getElementById(
                          `menu-button-${geofence.id}`
                        )}
                        open={openMenuGeofenceId === geofence.id} // The menu is open if the openMenuAccountId
                        onClose={(event) => handleActionsMenuClose(event)}
                      >
                        <StyledMenuItem
                          onClick={(event) => {
                            event.stopPropagation(); // Prevents the click event from reaching the TableRow
                            handleDeleteModalOpen(event, geofence);
                          }}
                        >
                          <Grid sx={{ display: "flex", alignItems: "block" }}>
                            <DeleteOutlineIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            <Typography> Delete Geofence</Typography>
                          </Grid>
                        </StyledMenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledTableContainer>

      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        // selectedAccount={selectedItem}
        // deleteAccount={deleteGeofence}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default GeofencesListTable;
