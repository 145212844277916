/* eslint-disable no-unused-vars */
// React core imports
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "../../../common/Loader";
import DriverRunSheet from "./DriverRunSheet";
import { driverRunSheetData } from "../../../../utils/constant";
import { Button } from "@mui/material";
import { fetchUnitsMonitoring } from "../../../../services/unitsMonitoring.service";


const ReportsListCard = () => {
  const [reports, setReports] = useState([]);
  const [page] = useState(1);
  const [limit] = useState(15);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const { consignees, fetchMore, isLoading, isEmpty } = useOutletContext();
  const navigate = useNavigate();
  const navigateToEditScreen = useCallback(() => {
    navigate(`/monitoring/reports/driver-run-sheet`);
  }, [navigate]);

  useEffect(() => {
    _fetchUnitsMonitoring();
  }, [page, limit]);

  // const _fetchUnitsMonitoring = async () => {
  //   if (isLoadingDevices) return; // Prevent multiple calls
  //   setIsLoadingDevices(true);

  //   try {
  //     let allReports= [];
  //     let currentPage = 1;
  //     let totalFetched = 0;
  //     const MAX_PAGE = 30; // Define the maximum page limit
  //     let data;
  //     do {
  //       if (currentPage > MAX_PAGE) break; // Stop fetching if page exceeds the maximum
  //       data = await fetchUnitsMonitoring(currentPage, limit);
  //       allReports = [...allReports, ...data.rows];
  //       totalFetched += data.rows.length;
  //       currentPage++; // Move to the next page
  //     } while (totalFetched < data.count);

  //     setReports(allReports);
  //   } catch (error) {
  //     const errorMessage = error?.message || "An unknown error occurred";
  //     console.error("Error fetching Reports:", errorMessage);
  //   } finally {
  //     setIsLoadingDevices(false);
  //   }
  // };
  const _fetchUnitsMonitoring = async () => {
    if (isLoadingDevices) return; // Prevent multiple calls
    setIsLoadingDevices(true);
  
    try {
      let allReports = [];
      let currentPage = 1;
      let totalFetched = 0;
      const MAX_PAGE = 30; // Define the maximum page limit
      let data;
  
      do {
        if (currentPage > MAX_PAGE) break; // Stop fetching if page exceeds the maximum
        data = await fetchUnitsMonitoring(currentPage, limit);
        allReports = [...allReports, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);
  
      // Filter to keep only the most recent entry per deviceId
      const uniqueReportsMap = new Map();
      allReports.forEach(report => {
        // Only keep the latest report for each deviceId
        if (!uniqueReportsMap.has(report.deviceId)) {
          uniqueReportsMap.set(report.deviceId, report);
        } else {
          const existingReport = uniqueReportsMap.get(report.deviceId);
          if (new Date(report.createdAt) > new Date(existingReport.createdAt)) {
            uniqueReportsMap.set(report.deviceId, report);
          }
        }
      });
  
      const filteredReports = Array.from(uniqueReportsMap.values());
      setReports(filteredReports);
    console.log("filteredReports",filteredReports)

    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching Reports:", errorMessage);
    } finally {
      setIsLoadingDevices(false);
    }
  };
  console.log("reports",reports)
  


  return (
    <div>
      {isLoading && <Loader />}
      <Button
        sx={{
          backgroundColor: "#1498e3",
          color: "white",
          "&:hover": {
            backgroundColor: "blue",
            color: "white",
          },
        }}
        onClick={navigateToEditScreen}
      >
        Download PDF
      </Button>


    </div>
  );
};

export default ReportsListCard;
