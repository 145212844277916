/* eslint-disable no-unused-vars */
// export default DriversListTable

import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  Grid,
  Menu,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteModal from "../../../modals/DeleteModal";
import Loader from "../../../common/Loader";
import {
  StyledIconButton,
  StyledMenuItem,
  StyledTableContainer,
  StyledTableHead,
} from "../../../../styledComponents";
import { driverTableColumnNew } from "../../../../utils/constant";
import EmptyState from "../../../common/EmptyState";
import { formatDate, getUsersStatusColor } from "../../../../utils/functions";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { updateAccount } from "../../../../services/accountNew.service";
import ActivateAccountModal from "../../../modals/ActivateAccountModal";
import DeactivateAccountModal from "../../../modals/DeactivateAccountModal";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import debounce from "lodash.debounce";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";

const DriversListTable = () => {
  const [errors, setErrors] = useState([])
  const [openMenuDriverId, setOpenMenuDriverId] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);
  const [deactivateOpen, setDeactivateOpen] = useState(false);
  const [activateOpen, setActivateOpen] = useState(false);

  const navigate = useNavigate();
  const { drivers, fetchMore, isLoading, isEmpty } = useOutletContext();
  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [drivers, isLoading, handleScroll]);

  const handleUpdateAccount = useCallback(
    async (updatedAccount) => {
      try {
        const response = await updateAccount(selectedItem.id, updatedAccount);
        if (response) {
          // Set the state to reload the page after successful update
          setShouldReload(true);
        }
      } catch (error) {
        console.error("Error updating accounts:", error);
      } finally {
        // setIsLoading(false);
      }
    },
    [setShouldReload, selectedItem]
  );

    // Function to open the menu on click of 3 dots in the list cards/tables
  // Callbacks wrapped with useCallback for performance optimization

  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuDriverId(selectedItem.id); // Set the ID of the account whose menu should be open
    setSelectedItem(selectedItem);
  }, []);

  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuDriverId(null);
    event?.stopPropagation();
  }, []);

  const handleActivateModalOpen = useCallback(
    (event, driverId) => {
      handleActionsMenuClose();
      setSelectedItem(driverId);
      setActivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setActivateOpen]
  );

  const handleDeactivateModalOpen = useCallback(
    (event, driverId) => {
      handleActionsMenuClose();
      setSelectedItem(driverId);
      setDeactivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setDeactivateOpen]
  );

  const handleActivateModalClose = useCallback(() => {
    setActivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setActivateOpen]);

  //   // Function that calls the API to perform deleteAccount
  const activateAccount = useCallback(() => {
    // Check if the subscriptionStatus exists before attempting to update it
    if (selectedItem?.subscriptions?.[0]?.subscriptionStatus) {
      const status = "ACTIVE";

      // Create a new updated subscriptions array
      const updatedSubscriptions = selectedItem.subscriptions.map(
        (subscription, index) => {
          if (index === 0) {
            // Assuming you only want to update the first subscription
            return { ...subscription, subscriptionStatus: status };
          }
          return subscription;
        }
      );

      // Update the selectedAccount with the new subscriptions array
      const updatedAccount = {
        ...selectedItem,
        subscriptions: updatedSubscriptions,
      };

      setSelectedItem(updatedAccount);
      handleUpdateAccount(updatedAccount);

      // Close the modal
      setActivateOpen(false);
      setSelectedItem(null);
    } else {
      console.error("No subscriptionStatus found to update.");
    }
  }, [selectedItem, handleUpdateAccount, setActivateOpen, setSelectedItem]);

  //   // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeactivateModalClose = useCallback(() => {
    setDeactivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setDeactivateOpen]);

  //   // Function that calls the API to perform deleteAccount
  const deactivateAccount = useCallback(() => {
    // Check if the subscriptionStatus exists before attempting to update it
    if (selectedItem?.subscriptions?.[0]?.subscriptionStatus) {
      const status = "CANCELED";

      // Create a new updated subscriptions array
      const updatedSubscriptions = selectedItem.subscriptions.map(
        (subscription, index) => {
          if (index === 0) {
            // Assuming you only want to update the first subscription
            return { ...subscription, subscriptionStatus: status };
          }
          return subscription;
        }
      );

      // Update the selectedAccount with the new subscriptions array
      const updatedAccount = {
        ...selectedItem,
        subscriptions: updatedSubscriptions,
      };

      setSelectedItem(updatedAccount);
      handleUpdateAccount(updatedAccount);

      // Close the modal
      setDeactivateOpen(false);
      setSelectedItem(null);
    } else {
      console.error("No subscriptionStatus found to update.");
    }
  }, [
    selectedItem,
    handleUpdateAccount,
    setDeactivateOpen,
    setSelectedItem,
  ]);

  const handleDeleteModalOpen = useCallback(
    (event, driverId) => {
      handleActionsMenuClose();
      setSelectedItem(driverId);
      setOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setOpen]
  );

  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setOpen]);

    // Function that calls the API to perform deleteAccount
    const deleteItem = async () => {
      setOpen(false);
      setSelectedItem(null);
      // try {
        // const response = await deleteDriver(selectedItem.id);
      //   if (response) {
      //     handleResponse(response, 'Driver deleted successfully.')
      //     handleError([])
      //     // Set the state to reload the page after successful update
      //     setShouldReload(true);
      //   }
      // } catch (error) {
      //   handleError(error)
      // } finally {
      //   setOpen(false);
      //   setSelectedItem(null);
      // }
    };

  // // Function that calls the API to perform deleteDriver
  // const deleteDriver = useCallback(() => {
  //   setOpen(false);
  //   // setSelectedItem(null)
  // }, [setOpen, setSelectedItem]);

  useEffect(() => {
    if (selectedItem) {
      console.log("Selected Driver has been set:", selectedItem);
    }
  }, [selectedItem]);

  const navigateToEditScreen = useCallback(
    (driverId) => {
      navigate(`/monitoring/drivers/edit/${driverId}`);
    },
    [navigate]
  );

  const navigateToConsignmentList = (event, driver) => {
    event.stopPropagation();
    navigate(`/monitoring/drivers/consignment/${driver.id}`);
  };
  const handleClose = () => {
    setErrors([])
    setSuccessMessage('')
  }
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0)
    if (response) {
      setSuccessMessage(successMessage)
    }
  }
  const handleError = (error) => {
    window.scrollTo(0, 0)
    // if (error.name === 'ValidationError') {
    //   handleValidationErrors(error)
    // } else {
    setErrors(error.error)
    // }
  }

  return (
    <>
    <Grid sx={{ marginTop: '50px' }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledTableContainer ref={containerRef}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="DRIVERS"
              message="Oops! You have no drivers for your search criteria"
              info="Please feel free to create a new driver by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {!isLoading && !isEmpty && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <StyledTableHead>
                <TableRow>
                  {driverTableColumnNew.map((column) => (
                    <TableCell key={column}>
                      <Typography variant="tableHeader">{column}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {drivers?.map((driver) => (
                  <TableRow
                    key={driver.id}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigateToEditScreen(driver.id)}
                  >
                    <TableCell component="th" scope="row">
                      <Typography variant="tableValue">
                        {driver.userName}
                        <Typography
                          variant="cardStatus2"
                          sx={{
                            color: getUsersStatusColor(driver.status),
                          }}
                        >
                          &bull; &nbsp;
                          {driver.status}
                        </Typography>
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="tableValue">
                        {driver.firstName || "--"}
                        {driver?.lastName &&
                          driver.lastName !== "null" &&
                          ` ${driver.lastName}`}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="tableValue">
                        {driver.email}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="tableValue">
                        {driver.phone}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="tableValue">
                        {driver.licenseNumber ? driver.licenseNumber : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="tableValue">
                        {driver?.licenseExpiryDate
                          ? formatDate(driver?.licenseExpiryDate)
                          : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="tableValue">
                        {driver.passportNumber ? driver.passportNumber : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="tableValue">
                        {driver?.passportExpiryDate
                          ? formatDate(driver?.passportExpiryDate)
                          : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <StyledIconButton
                        id={`menu-button-${driver.id}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleActionsMenuOpen(event, driver);
                        }}
                      >
                        <MoreVertIcon />
                      </StyledIconButton>
                      <Menu
                        anchorEl={document.getElementById(
                          `menu-button-${driver.id}`
                        )}
                        open={openMenuDriverId === driver.id} // The menu is open if the openMenuAccountId
                        onClose={(event) => handleActionsMenuClose(event)}
                      >
                        {driver && driver.status === "ACTIVE" ? (
                          <StyledMenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              handleDeactivateModalOpen(event, driver);
                            }}
                          >
                            <BlockOutlinedIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            Deactivate Driver
                          </StyledMenuItem>
                        ) : (
                          <StyledMenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              handleActivateModalOpen(event, driver);
                            }}
                          >
                            <VerifiedUserOutlinedIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            Activate Driver
                          </StyledMenuItem>
                        )}
                        <StyledMenuItem
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDeleteModalOpen(event, driver);
                          }}
                        >
                          <Grid sx={{ display: "flex", alignItems: "block" }}>
                            <DeleteOutlineIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            <Typography> Delete Driver</Typography>
                          </Grid>
                        </StyledMenuItem>
                        <StyledMenuItem
                          onClick={(e) => {
                            navigateToConsignmentList(e, driver);
                          }}
                        >
                          <Grid sx={{ display: "flex", alignItems: "center" }}>
                            <LocalShippingIcon // You can use an appropriate icon here
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            <Typography>Consignment List</Typography>
                          </Grid>
                        </StyledMenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledTableContainer>

      <ActivateAccountModal
        activateOpen={activateOpen}
        handleActivateModalClose={handleActivateModalClose}
        selectedAccount={selectedItem}
        activateAccount={activateAccount}
      />
      <DeactivateAccountModal
        deactivateOpen={deactivateOpen}
        handleDeactivateModalClose={handleDeactivateModalClose}
        selectedAccount={selectedItem}
        deactivateAccount={deactivateAccount}
      />
      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default DriversListTable;
