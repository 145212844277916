import React, { useRef } from 'react'
import {
  LoaderContainer,
  StyledTableContainer,
  StyledTableHead
} from '../../../../styledComponents'
import { useOutletContext } from 'react-router-dom'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material'
import EmptyState from '../../../common/EmptyState'
import { optimizedRoutesTable } from '../../../../utils/constant'

export default function RouteOptimzationListTable() {
  const containerRef = useRef(null)
  const { optimizedRoutes, isLoading, isEmpty } = useOutletContext()
  return (
    <>
      <StyledTableContainer ref={containerRef}>
        {isLoading && <LoaderContainer />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="ROUTE_OPTIMIZATION"
              message="Oops! You have no optimized route"
              info="Please feel free to create a new optimzed route by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {!isLoading && !isEmpty && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <StyledTableHead>
                <TableRow>
                  {optimizedRoutesTable.map((column) => (
                    <TableCell key={column}>
                      <Typography variant="tableHeader">{column}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {optimizedRoutes?.map((route) => (
                  <TableRow
                    key={route.id}
                    sx={{ cursor: 'pointer' }}
                    // onClick={() => navigateToEditScreen(geofence.id)}
                  >
                    <TableCell component="th" scope="row">
                      <Typography variant="tableValue">
                        {route.routeName}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="tableValue">
                        {route.pickup || '--'}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="tableValue">
                        {route?.delivery}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledTableContainer>
    </>
  )
}
