/* eslint-disable no-unused-vars */
import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
  StyledFormContainer
} from '../../../../styledComponents.js'
import FormSelect from '../../../common/FormSelect.js'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import FormOptionSelector from '../../../common/FormOptionSelector.js'
import {
  fetchAllAccounts,
  getAccountById
} from '../../../../services/accountNew.service.js'
import {
  fetchAllRoles,
  getRoleById
} from '../../../../services/roleNew.service.js'
import { measurementType } from '../../../../utils/constant.js'
import { usersSchema } from '../../../../utils/validation-schemas/usersSchema.js'
import { validateField } from '../../../../utils/yupValidations.js'

const UsersForm2 = ({
  user,
  onFormValueChange,
  onValidationErrorsChange,
  validationErrors,
  setValidationErrors
}) => {
  const [accounts, setAccounts] = useState([])
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState('')
  const [firstRoleChange, setFirstRoleChange] = useState(true)
  const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))
  const { id } = useParams()
  const userId = id

  useEffect(() => {
    const _fetchAllAccounts = async () => {
      try {
        const data = await fetchAllAccounts()
        setAccounts(data.rows)
      } catch (error) {
        console.error(error.response?.data?.message || error.message)
      }
    }

    const _fetchAllRoles = async () => {
      try {
        const data = await fetchAllRoles()
        setRoles(data.rows)
      } catch (error) {
        console.error(error.response?.data?.message || error.message)
      }
    }

    _fetchAllAccounts()
    _fetchAllRoles()
  }, [])

  const _getRoleById = async () => {
    if (selectedRole !== '') {
      try {
        const data = await getRoleById(selectedRole)
        const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))
        const updatedAccessRights = {
          accountId: accountId,
          roleId: selectedRole,
          permissions: data.accessRights
        }

        if (user.type === 'New User') {
          onFormValueChange({
            ...user,
            accessRights: [updatedAccessRights]
          })
        } else {
          if (firstRoleChange) {
            const updatedAccessRightsArray = [...user.accessRights]
            updatedAccessRightsArray[user.accessRights.length] =
              updatedAccessRights
            onFormValueChange({
              ...user,
              accessRights: updatedAccessRightsArray
            })
            setFirstRoleChange(false)
          } else {
            user.accessRights[user.accessRights.length - 1] =
              updatedAccessRights
            onFormValueChange({ ...user })
          }
        }
        // Validate the form again after role selection
        onValidationErrorsChange({})
      } catch (error) {
        console.error(error.response?.data?.message || error.message)
      }
    }
  }

  useEffect(() => {
    _getRoleById()
    console.log(selectedRole)
  }, [selectedRole])

  const handleFieldChange = async (name, value, index = null) => {
    try {
      let updatedUser = { ...user, [name]: value }
      if (name === 'role') {
        setSelectedRole(value)
      } else if (name == 'measurementSystem') {
        updatedUser = {
          ...user,
          settings: {
            ...user.settings,
            measurementSystem: value,
            timeFormat: '24 Hrs',
            dateFormat: 'dd/mm/yyyy'
          }
        }
      }
      onFormValueChange(updatedUser, name, value, 3)

      setValidationErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors }
        if (name === 'role' && updatedErrors['accessRights[0].roleId']) {
          delete updatedErrors['accessRights[0].roleId']
        }
        return updatedErrors
      })
    } catch (error) {
      // No need to update validationErrors here, as it's done in the validateField function
    }
  }
  return (
    <StyledFormContainer>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">
            Account, Role & Specifications
          </Typography>
        </StyledSectionLeft>

        <StyledSectionRight>
          <FormSelect
            label="Choose Role"
            required
            value={
              user.type !== 'New User'
                ? user.accessRights[user.accessRights.length - 1]?.roleId || ''
                : user?.accessRights.find(
                    (access) => access.accountId === accountId
                  )?.roleId || ''
            }
            onChange={(selected) => {
              handleFieldChange('role', selected)
            }}
            options={roles
              .filter(
                (item) =>
                  item.name !== 'System Administrator' && item.name !== 'Driver'
              )
              .map((item) => ({
                value: item.id,
                label: item.name
              }))}
            error={!!validationErrors['accessRights[0].roleId']}
            errorMessage={validationErrors['accessRights[0].roleId']}
            sx={{ width: '100%' }}
          />
          <FormOptionSelector
            label="Set Measurement System"
            required
            options={measurementType}
            selectedOption={user.settings.measurementSystem}
            handleOptionChange={(value) =>
              handleFieldChange('measurementSystem', value)
            }
          />
        </StyledSectionRight>
      </StyledFormSection>
    </StyledFormContainer>
  )
}

UsersForm2.propTypes = {
  user: PropTypes.object,
  onFormValueChange: PropTypes.func,
  onValidationErrorsChange: PropTypes.func,
  validationErrors: PropTypes.object,
  setValidationErrors: PropTypes.func,
  validationSchema: PropTypes.object.isRequired
}

export default UsersForm2
