/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  Grid,
  Menu,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DeleteModal from "../../../modals/DeleteModal";
import Loader from "../../../common/Loader";
import { getUsersStatusColor } from "../../../../utils/functions";
import {
  StyledIconButton,
  StyledMenuItem,
  StyledTableContainer,
  StyledTableHead,
} from "../../../../styledComponents";
import { usersTableColumnNew } from "../../../../utils/constant";
import EmptyState from "../../../common/EmptyState";
import ActivateAccountModal from "../../../modals/ActivateAccountModal";
import DeactivateAccountModal from "../../../modals/DeactivateAccountModal";
import { updateAccount } from "../../../../services/accountNew.service";
import debounce from "lodash.debounce";
import { deleteUser, updateUser } from "../../../../services/userNew.service";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import ActivateUserModal from "../../../modals/ActiveUserModel";
import InactiveUserModal from "../../../modals/InactiveUserModel";

const UsersListTable = () => {
  const [errors, setErrors] = useState([]);
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  const [openMenuAccountId, setOpenMenuAccountId] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);
  // This is for the deactivate account modal
  const [deactivateOpen, setDeactivateOpen] = useState(false);
  // This is for the deactivate account modal
  const [activateOpen, setActivateOpen] = useState(false);

  const navigate = useNavigate();
  const { users, fetchMore, isLoading, isEmpty } = useOutletContext();
  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [users, isLoading, handleScroll]);

  // Function that calls the API to perform updateAccount
  const handleUpdateUser = useCallback(
    async (updatedUser) => {
      try {
        const response = await updateUser(selectedItem.id, updatedUser);
        if (response) {
          setSuccessMessage("User updated successfully.");
          setErrorMessage("");
          // Set the state to reload the page after successful update
          setShouldReload(true);
        }
      } catch (error) {
        setErrorMessage(error.message || "An unexpected error occurred");
      } finally {
        // setIsLoading(false);
      }
    },
    [setSuccessMessage, setErrorMessage, setShouldReload, selectedItem]
  );

  // Function to open the menu on click of 3 dots in the list cards/tables
  // Callbacks wrapped with useCallback for performance optimization
  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuAccountId(selectedItem.id); // Set the ID of the account whose menu should be open
    setSelectedItem(selectedItem);
  }, []);

  // Function to close the menu on selecting any option from the menu
  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuAccountId(null); // Set the ID of the account whose menu should be open
    event?.stopPropagation();
  }, []);

  //Delete Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleDeleteModalOpen = useCallback(
    (event, user) => {
      handleActionsMenuClose();
      setSelectedItem(user);
      setOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setOpen]);

  // Function that calls the API to perform deleteItem
  // const deleteItem = useCallback(() => {
  //   setOpen(false);
  //   setSelectedItem(null);
  // }, [setOpen, setSelectedItem]);

  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteUser(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, "User deleted successfully.");
    //     handleError([]);
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error);
    // } finally {
    //   setOpen(false);
    //   setSelectedItem(null);
    // }
  };

  //Deactivate Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleDeactivateModalOpen = useCallback(
    (event, userId) => {
      console.log("Selected Account:", userId);
      handleActionsMenuClose();
      setSelectedItem(accountId);
      setDeactivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setDeactivateOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeactivateModalClose = useCallback(() => {
    setDeactivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setDeactivateOpen]);

  // Function that calls the API to perform deleteItem
  const deactivateUser = useCallback(() => {
    // Check if the status exists before attempting to update it
    if (selectedItem?.status) {
      const updatedStatus = "INACTIVE";

      // Update the selectedItem with the new status
      const updatedUser = {
        ...selectedItem,
        status: updatedStatus, // Set status to INACTIVE
      };

      // Update the selectedItem and call the update handler
      setSelectedItem(updatedUser);
      handleUpdateUser(updatedUser);

      // Close the modal and reset selected item
      setDeactivateOpen(false);
      setSelectedItem(null);
    } else {
      console.error("No status found to update.");
    }
  }, [selectedItem, handleUpdateUser, setDeactivateOpen, setSelectedItem]);

  //Activate Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleActivateModalOpen = useCallback(
    (event, accountId) => {
      handleActionsMenuClose();
      setSelectedItem(accountId);
      setActivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setActivateOpen]
  );

  const handleActivateModalClose = useCallback(() => {
    setActivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setActivateOpen]);

  const activateUser = useCallback(() => {
    // Check if the status exists before attempting to update it
    if (selectedItem?.status) {
      const updatedStatus = "ACTIVE";

      // Update the selectedItem with the new status
      const updatedUser = {
        ...selectedItem,
        status: updatedStatus, // Set status to ACTIVE
      };

      // Update the selectedItem and call the update handler
      setSelectedItem(updatedUser);
      handleUpdateUser(updatedUser);

      // Close the modal and reset selected item
      setActivateOpen(false);
      setSelectedItem(null);
    } else {
      console.error("No status found to update.");
    }
  }, [selectedItem, handleUpdateUser, setActivateOpen, setSelectedItem]);

  const navigateToEditScreen = useCallback(
    (userId) => {
      navigate(`/management/users/edit/${userId}`);
    },
    [navigate]
  );

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
    }
  };
  const handleError = (error) => {
    window.scrollTo(0, 0);
    // if (error.name === 'ValidationError') {
    //   handleValidationErrors(error)
    // } else {
    setErrors(error.error);
    // }
  };

  return (
    <>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledTableContainer ref={containerRef}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="UNITS"
              message="Oops! You have no units for your search criteria"
              info="Please feel free to create a new unit by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {!isLoading && !isEmpty && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <StyledTableHead>
                <TableRow>
                  {usersTableColumnNew.map((column) => (
                    <TableCell key={column}>
                      <Typography variant="tableHeader">{column}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {users?.map((user) => (
                  <TableRow
                    key={user.id}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigateToEditScreen(user.id)}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ verticalAlign: "top" }}
                    >
                      <Typography variant="tableValue">
                        {user.userName}
                      </Typography>
                      <Typography
                        variant="cardStatus2"
                        sx={{
                          color: getUsersStatusColor(user.status),
                        }}
                      >
                        &bull; &nbsp;
                        {user.status}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {user.firstName || "--"}
                        {user?.lastName &&
                          user.lastName !== "null" &&
                          ` ${user.lastName}`}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {user?.accessRights.find(
                          (access) => access.accountId === accountId
                        )?.accountName || ""}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {user?.accessRights.find(
                          (access) => access.accountId === accountId
                        )?.roleName || "System Administrator"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <StyledIconButton
                        id={`menu-button-${user.id}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleActionsMenuOpen(event, user);
                        }}
                      >
                        <MoreVertIcon />
                      </StyledIconButton>
                      <Menu
                        anchorEl={document.getElementById(
                          `menu-button-${user.id}`
                        )}
                        open={openMenuAccountId === user.id}
                        onClose={handleActionsMenuClose}
                      >
                        {user && user.status === "ACTIVE" ? (
                          <StyledMenuItem
                            onClick={(e) => {
                              handleDeactivateModalOpen(e, user);
                              e.stopPropagation();
                            }}
                          >
                            <BlockOutlinedIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            Inactivate User
                          </StyledMenuItem>
                        ) : (
                          <StyledMenuItem
                            onClick={(e) => {
                              handleActivateModalOpen(e, user);
                              e.stopPropagation();
                            }}
                          >
                            <VerifiedUserOutlinedIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            Activate User
                          </StyledMenuItem>
                        )}
                        <StyledMenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteModalOpen(e, user);
                          }}
                        >
                          <Grid sx={{ display: "flex", alignItems: "center" }}>
                            <DeleteOutlineIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            <Typography>Delete User</Typography>
                          </Grid>
                        </StyledMenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledTableContainer>

      <ActivateAccountModal
        activateOpen={activateOpen}
        handleActivateModalClose={handleActivateModalClose}
        selectedItem={selectedItem}
        activateItem={activateUser}
      />
      <InactiveUserModal
        deactivateOpen={deactivateOpen}
        handleDeactivateModalClose={handleDeactivateModalClose}
        selectedItem={selectedItem}
        inactivateUser={deactivateUser}
      />
      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default UsersListTable;
