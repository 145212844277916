/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Grid,
  Link,
  Checkbox,
  TextField,
  IconButton,
  Modal,
  Box,
  Button,
  Typography,
  Radio,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import logo from "../../../../../src/assets/logo/Off@3x.png";
import bgLeft from "../../../../../src/assets/bgLeftLogin.png";
import africaMap from "../../../../../src/assets/africaMap.png";
import {
  getUserById,
  forgetPassword,
  loginUser,
  resetPassword,
  updateUser,
  validateUser,
} from "../../../../services/userNew.service";
import ErrorMessage from "../../../common/ErrorMessage";
import { useNavigate } from "react-router-dom";
// import { useDispatch } from 'react-redux';
import {
  LeftSection,
  RightSection,
  Title,
  Subtitle,
  InputLabelStyled,
  StyledLink,
  FormContainer,
  LogoWrapper,
  ErrorText,
  PasswordAdornment,
  StyledFormControlLabel,
  StyledButton,
  TermsWrapper,
  ImageOverlay,
} from "../styles/LoginFormStyles";
import { setUserData } from "../../../../utils/redux/actions/userActions";
import { StyledInputControlContainer } from "../../billingPlans/styles/BillingPlansStyles";
import styled from "styled-components";
import { Close } from "@mui/icons-material";
import { fetchAllAccounts } from "../../../../services/accountNew.service";
import { getToken } from "firebase/messaging";
import { messaging } from "../../../../services/firebase/firebase";
import { iconMap } from "../../../../constants/iconsList";
import Loader from "../../../common/Loader";

const styles = {
  getStartBtn: {
    backgroundColor: "#0772B8",
    borderRadius: "8px",
    width: "125%",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    padding: "15px 25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#272560",
  },
};

const SearchBox = styled(TextField)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f8;
  height: 50px;
  gap: 12px;
  width: 100%;
  border: none;
  border-radius: 8px;
`;

const SearchButton = styled(Button)`
  margin-left: 1rem;
  height: 100%;
  border-radius: 8px;
`;

const LoginForm = () => {
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
    rememberMe: false,
  });
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [resetOpen, setResetOpen] = React.useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [resetToken, setResetToken] = React.useState(false);
  // const [openAccount, setOpenAccount] = useState(false);
  const [accountsForm, setAccountsForm] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState(accounts);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);
  const [limit] = useState(15);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filtered = accounts.filter((item) =>
      item.accountName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredAccounts(filtered);
  };

  // eslint-disable-next-line no-unused-vars
  const getAccounts = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await getUserById(userData.user.id);
      if (page === 1) {
        setAccounts(response.accessRights);
      }

      if (userData.user.accessAllAccounts === true) {
        const res = await fetchAllAccounts(page, limit);
        setFilteredAccounts((prevAccounts) => {
          if (page === 1) {
            return res.rows;
          } else {
            return [...prevAccounts, ...res.rows];
          }
        });
        console.log(res.rows);
        setCount(res.count);
      } else {
        console.log(response.accessRights);
        setFilteredAccounts(response.accessRights);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
      } else {
        console.log(error.message);
      }
      setIsLoading(false);
    }
  };

  // const handleScroll = debounce(() => {
  //   const container = containerRef.current;
  //   if (
  //     container.scrollTop + container.clientHeight >=
  //     container.scrollHeight - 10
  //   ) {
  //     // User has scrolled to the end of the container
  //     if (count > drivers?.length) {
  //       getDrivers(); // Load next page
  //     }
  //   }
  // }, 300);

  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 10
    ) {
      // User has scrolled to the end of the container
      if (count > filteredAccounts.length && !isLoading) {
        setPage((prevPage) => prevPage + 1); // Load next page
      }
    }
  };

  useEffect(() => {
    getAccounts(page);
  }, [page]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "userName") {
      setUserNameError(
        validateUserName(value)
          ? ""
          : "User name must be between 3 and 50 characters"
      );
      setFormValid(
        validateUserName(value) && validatePassword(formData.password)
      );
    }

    if (name === "password") {
      setPasswordError(
        validatePassword(value)
          ? ""
          : "Password must be between 6 and 20 characters"
      );
      setFormValid(
        validateUserName(formData.userName) && validatePassword(value)
      );
    }
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, rememberMe: e.target.checked });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateUserName = (userName) => {
    return userName?.length >= 3 && userName?.length <= 50;
  };

  const validatePassword = (password) => {
    return password.length >= 6 && password.length <= 20;
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!validateUserName(formData.userName)) {
      setUserNameError("User name must be between 3 and 50 characters");
      return;
    }

    if (formData.password.length < 6 || formData.password.length > 20) {
      setPasswordError("Password must be between 6 and 20 characters");
      return;
    }

    try {
      const response = await loginUser({
        userName: formData.userName,
        password: formData.password,
      });

      setFormValid(true);

      localStorage.setItem("userData", JSON.stringify(response));
      setErrorMessage("User successfully logged in");

      if (formData.rememberMe) {
        localStorage.setItem("storedUserData", JSON.stringify(formData));
      }

      setAccountsForm(true);
      getAccounts();
    } catch (error) {
      console.error("Login Error:", error.message);
      setIsLoading(false);
      setErrorMessage(error.message || "An error occurred");
    }
  };

  const handleOpen = () => {
    setUserData({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleForgorPassword = async () => {
    try {
      const userData1 = await forgetPassword(formData);
      if (userData1.resetToken) {
        setResetToken(userData1.resetToken);
        setOpen(false);
        setResetOpen(true);
        setUserData({});
      }
      // localStorage.setItem('userData', JSON.stringify(userData1));
    } catch (error) {
      if (error.response.data) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  const handleResetClose = () => {
    setOpen(false);
  };

  const handleResetPassword = async () => {
    try {
      const resetData = {
        resetPasswordToken: resetToken,
        password: formData.password,
      };
      const userData1 = await resetPassword(resetData);
      if (userData1.message == "Password reset successful") {
        setResetOpen(false);
        setUserData({});
        setSuccessDialogOpen(true);
      }
      // localStorage.setItem('userData', JSON.stringify(userData1));
    } catch (error) {
      if (error.response.data) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  const handleSuccessClose = async () => {
    setSuccessDialogOpen(false);
  };

  // const handleAccountClose = () => {
  //   setOpenAccount(false);
  // };

  const handleAccountChange = (id) => {
    setSelectedAccount(id);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Show loader
    setIsLoading(true);

    localStorage.setItem("defaultAccountId", JSON.stringify(selectedAccount));
    const userDetail = JSON.parse(localStorage.getItem("userData"));

    console.log("kk", userDetail);
    // Try to find the correct accessRight object for the selected account
    let accessRight = userDetail.user.accessRights.find(
      (access) => access.accountId === selectedAccount
    );

    // If accountId is null, use the first available accessRight as a fallback
    if (!accessRight) {
      accessRight = userDetail.user.accessRights[0];
    }

    const roleId = accessRight?.roleId || null;
    console.log("dd", roleId);

    // Check if roleId is still undefined or null, and handle the case
    if (!roleId) {
      console.error("Role ID not found. Please check the access rights.");
      setErrorMessage("Role ID is not available for the selected account.");
      setIsLoading(false);
      return;
    }

    console.log("roleId", roleId);

    try {
      const response = await validateUser(
        userDetail.user.id,
        selectedAccount,
        roleId
      );

      localStorage.setItem("access_token", JSON.stringify(response.token));
      const fcmToken = localStorage.getItem("fcmToken");

      const appendFormData = (formData, data, parentKey) => {
        if (data && typeof data === "object" && !(data instanceof File)) {
          Object.keys(data).forEach((key) => {
            appendFormData(
              formData,
              data[key],
              parentKey ? `${parentKey}[${key}]` : key
            );
          });
        } else {
          let value = data;
          if (typeof data === "number") {
            value = `${data}#number`; // Append number suffix
          } else if (typeof data === "boolean") {
            value = `${data}#boolean`; // Append boolean suffix
          }
          formData.append(parentKey, value);
        }
      };

      const formData = new FormData();

      if (fcmToken != null) {
        const updatedUser = { ...userDetail.user };

        // Check if fcmToken is already in webFcmToken array
        if (!updatedUser.webFcmToken.includes(fcmToken)) {
          updatedUser.webFcmToken = [...updatedUser.webFcmToken, fcmToken];
          appendFormData(formData, updatedUser);
          console.log("Adding FCM token to user data");
          await updateUser(userDetail.user.id, formData);
        }
      } else {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          const currentToken = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
          });

          if (currentToken) {
            const updatedUser1 = { ...userDetail.user };

            // Check if currentToken is already in webFcmToken array
            if (!updatedUser1.webFcmToken.includes(currentToken)) {
              updatedUser1.webFcmToken = [
                ...updatedUser1.webFcmToken,
                currentToken,
              ];
              appendFormData(formData, updatedUser1);
              console.log("Adding current FCM token to user data");
              await updateUser(userDetail.user.id, formData);
            }
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        }
      }

      // Hide loader and navigate
      setIsLoading(false);
      navigate("/home");
    } catch (error) {
      console.error("Login Error:", error.message);
      setErrorMessage(error.message);

      // Hide loader on error
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem("storedUserData")
      ? JSON.parse(localStorage.getItem("storedUserData"))
      : "";
    if (storedUserData) {
      setFormData(storedUserData);
      setFormValid(
        validateUserName(storedUserData.userName) &&
          validatePassword(storedUserData.password)
      );
    }
  }, []);

  return (
    <Container
      maxWidth="xl"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
      }}
    >
      {isLoading && <Loader />}
      <Grid container spacing={4} sx={{ padding: "0px", marginLeft: "0px" }}>
        <LeftSection item xs={12} sm={4}>
          <img
            src={bgLeft}
            alt="Static Image"
            style={{ width: "100%", height: "100vh" }}
          />
        </LeftSection>

        <RightSection item xs={12} sm={8}>
          {accountsForm === false && (
            <FormContainer>
              <LogoWrapper>
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: "50%", height: "auto" }}
                />
              </LogoWrapper>

              <Title style={{ fontWeight: 600 }}>WELCOME BACK!</Title>

              <Subtitle>Login to your account to continue</Subtitle>
              <ErrorMessage message={errorMessage} />
              <form onSubmit={handleSubmit}>
                <InputLabelStyled>User Name *</InputLabelStyled>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="userName"
                  value={formData.userName}
                  onChange={handleChange}
                  placeholder="Enter your username"
                  required
                  error={!!userNameError}
                  InputProps={{
                    classes: {
                      root: "styledTextField",
                      focused: "focused",
                    },
                    style: {
                      WebkitBoxShadow: "none",
                    },
                  }}
                  style={{ marginBottom: "20px" }}
                />
                {userNameError && <ErrorText>{userNameError}</ErrorText>}
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <InputLabelStyled>Password *</InputLabelStyled>
                  </Grid>
                  <Grid item>
                    <StyledLink onClick={handleOpen} href="#" variant="body2">
                      Forgot password?
                    </StyledLink>
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                  required
                  error={!!passwordError}
                  InputProps={{
                    classes: {
                      root: "styledTextField",
                      focused: "focused",
                    },
                    endAdornment: (
                      <PasswordAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </PasswordAdornment>
                    ),
                    style: {
                      WebkitBoxShadow: "0 0 0 1000px white inset",
                    },
                  }}
                  style={{ marginBottom: "5px" }}
                />
                {passwordError && <ErrorText>{passwordError}</ErrorText>}
                <Grid container justifyContent="flex-end">
                  <Grid item xs={12}>
                    <StyledFormControlLabel
                      control={
                        <Checkbox
                          checked={formData.rememberMe}
                          onChange={handleCheckboxChange}
                          name="rememberMe"
                          color="primary"
                        />
                      }
                      label={
                        <Typography style={{ fontSize: "14px" }}>
                          Remember me
                        </Typography>
                      }
                      style={{ marginBottom: "5px" }}
                    />
                  </Grid>
                </Grid>
                <StyledButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={!formValid}
                >
                  LET&apos;S GET STARTED
                </StyledButton>
                <TermsWrapper>By proceeding, you agree to the </TermsWrapper>
                <TermsWrapper>
                  <Link
                    href="#"
                    sx={{
                      color: "#0772B8", // Use the primary color from the theme
                      fontSize: "12px",
                      fontWeight: 400,
                      textDecoration: "none", // Remove the underline
                      "&:hover": {
                        textDecoration: "underline", // Optional: Add underline on hover
                      },
                    }}
                  >
                    Terms and Conditions
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="#"
                    sx={{
                      color: "#0772B8", // Use the primary color from the theme
                      fontSize: "12px",
                      fontWeight: 400,
                      textDecoration: "none", // Remove the underline
                      "&:hover": {
                        textDecoration: "underline", // Optional: Add underline on hover
                      },
                    }}
                  >
                    Privacy Policy
                  </Link>
                  .
                </TermsWrapper>
              </form>
            </FormContainer>
          )}
          {accountsForm === true && (
            <FormContainer>
              <LogoWrapper>
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: "50%", height: "auto" }}
                />
              </LogoWrapper>

              <Title style={{ fontWeight: 600 }}>Choose Account!</Title>

              <Subtitle>
                Please choose your default account to continue
              </Subtitle>
              <form onSubmit={handleSave}>
                <StyledInputControlContainer>
                  <SearchBox
                    variant="standard"
                    margin="normal"
                    placeholder="Find accounts ..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                      classes: {
                        root: "styledTextField",
                        focused: "focused",
                      },
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={iconMap["SearchIcon"]}
                            alt="searchIcon"
                            style={{
                              margin: "8px",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchButton
                            onClick={() => {
                              setSearchTerm("");
                              setFilteredAccounts(accounts);
                            }}
                          >
                            <Close />
                          </SearchButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      marginBottom: "1.5rem",
                      "& .styledTextField": {
                        border: "1px solid #D0D5DD",
                        borderRadius: "8px",
                        padding: "10px",
                        width: "100%",
                      },
                      "& .focused": {
                        border: "1px solid blue",
                        borderRadius: "5px",
                        padding: "10px",
                      },
                    }}
                  />
                  <Grid
                    container
                    spacing={2}
                    ref={containerRef}
                    onScroll={handleScroll}
                    sx={{
                      paddingBottom: "5rem",
                      maxHeight: "180px",
                      overflowY: "auto",
                    }}
                  >
                    {filteredAccounts?.map((item, index) => (
                      <Grid item key={index} md={12} xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            border:
                              item.accountId === selectedAccount ||
                              item.id === selectedAccount
                                ? "2px solid #0772B8"
                                : "1px solid #D8D8DF",
                            borderRadius: "8px",
                            padding: "12px 28px 12px 16px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleAccountChange(item.accountId || item.id)
                          }
                        >
                          <Box>
                            <Typography
                              sx={{
                                textTransform: "uppercase",
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#0A2C47",
                              }}
                            >
                              {item.accountName || item.name}
                            </Typography>
                            <Typography
                              sx={{
                                backgroundColor: "#eeeef0",
                                textTransform: "uppercase",
                                fontSize: "11px",
                                fontWeight: 700,
                                padding: "2px 4px",
                                borderRadius: "2px",
                                color: "#6C6C81",
                              }}
                            >
                              {item.roleName || "System Administrator"}
                            </Typography>
                          </Box>
                          <Radio
                            sx={{
                              padding: "0px",
                              color: "#D8D8DF",
                              "&.Mui-checked": {
                                color: "#0772B8",
                              },
                            }}
                            checked={
                              item.accountId || item.id === selectedAccount
                            }
                            value={item.accountId || item.id}
                            name={`radio-buttons-${index}`}
                          />
                        </Box>
                      </Grid>
                    ))}
                    {isLoading && (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "1rem",
                          }}
                        >
                          <Loader />
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </StyledInputControlContainer>
                <StyledButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={selectedAccount === "" ? true : false}
                >
                  LOG IN
                </StyledButton>
                <TermsWrapper>By proceeding, you agree to the </TermsWrapper>
                <TermsWrapper>
                  <Link
                    href="#"
                    sx={{
                      color: "#0772B8",
                      fontSize: "12px",
                      fontWeight: 400,
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    Terms and Conditions
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="#"
                    sx={{
                      color: "#0772B8",
                      fontSize: "12px",
                      fontWeight: 400,
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    Privacy Policy
                  </Link>
                  .
                </TermsWrapper>
              </form>
            </FormContainer>
          )}

          <ImageOverlay src={africaMap} alt="Image Overlay" />
        </RightSection>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={styles.modal}>
          <ErrorMessage message={errorMessage} />
          <Typography
            variant="heading"
            style={{ margin: "5px" }}
            id="child-modal-title"
          >
            Reset password
          </Typography>
          <Typography variant="subHeading" style={{ margin: "5px" }}>
            Please enter your email to request a password reset.
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            name="userName"
            value={formData.userName}
            onChange={handleChange}
            placeholder="Enter your username"
            required
            error={!!userNameError}
            InputProps={{
              classes: {
                root: "styledTextField",
                focused: "focused",
              },
              style: {
                WebkitBoxShadow: "none",
              },
            }}
            style={{ marginBottom: "20px" }}
          />
          <Button
            sx={{
              ...styles.getStartBtn,
              width: "25%",
              margin: "15px",
              color: "#fff",
              "&:hover": {
                ...styles.getStartBtn,
                width: "25%",
                margin: "15px",
                color: "#fff",
              },
            }}
            onClick={handleForgorPassword}
          >
            SUBMIT
          </Button>
        </Box>
      </Modal>
      <Modal
        open={resetOpen}
        onClose={handleResetClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={styles.modal}>
          <ErrorMessage message={errorMessage} />
          <Typography
            variant="heading"
            style={{ margin: "5px" }}
            id="child-modal-title"
          >
            Reset password
          </Typography>
          <Typography variant="subHeading" style={{ margin: "5px" }}>
            Enter your new password
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            type={showPassword ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Enter your password"
            required
            error={!!passwordError}
            InputProps={{
              classes: {
                root: "styledTextField",
                focused: "focused",
              },
              endAdornment: (
                <PasswordAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </PasswordAdornment>
              ),
              style: {
                WebkitBoxShadow: "0 0 0 1000px white inset",
              },
            }}
            style={{ marginBottom: "5px" }}
          />

          <Button
            sx={{
              ...styles.getStartBtn,
              width: "35%",
              margin: "15px",
              color: "#fff",
              "&:hover": {
                ...styles.getStartBtn,
                width: "35%",
                margin: "15px",
                color: "#fff",
              },
            }}
            onClick={handleResetPassword}
          >
            RESET PASSWORD
          </Button>
        </Box>
      </Modal>
      <Modal
        open={successDialogOpen}
        onClose={handleSuccessClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={styles.modal}>
          <Typography
            variant="heading"
            style={{ margin: "5px" }}
            id="child-modal-title"
          >
            RESET SUCCESS
          </Typography>
          <Typography variant="subHeading" style={{ margin: "5px" }}>
            Password reset successful. You can now log in with your new
            password.
          </Typography>
          <Button
            sx={{
              ...styles.getStartBtn,
              width: "25%",
              margin: "15px",
              color: "#fff",
              "&:hover": {
                ...styles.getStartBtn,
                width: "25%",
                margin: "15px",
                color: "#fff",
              },
            }}
            onClick={handleSuccessClose}
          >
            OK
          </Button>
        </Box>
      </Modal>
      {/* <Modal
        open={openAccount}
        onClose={handleAccountClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...styles.modal, alignItems: 'none' }}>
          <Typography variant="heading" style={{ margin: '5px', textAlign: 'center' }} id="child-modal-title">Choose account</Typography>
          <Typography variant="subHeading" style={{ margin: '5px 5px 15px', textAlign: 'center' }}>Please choose your default account to continue.</Typography>
          <StyledInputControlContainer>
            <Select
              labelId="demo-simple-select-label"
              name='accountId'
              id="demo-simple-select"
              sx={{ width: '100%' }}
              value={selectedAccount}
              onChange={handleAccountChange}
            >
              {accounts.map((item) => (
                <MenuItem key={item.id} value={item.accountId}>{item.accountName}</MenuItem>
              ))}
            </Select>
          </StyledInputControlContainer>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button sx={{
              ...styles.getStartBtn, textAlign: 'center', width: '25%', color: '#fff', '&:hover': {
                ...styles.getStartBtn, width: '25%', color: '#fff',
              }
            }} onClick={handleSave}>ok</Button>
          </Box>
        </Box>
      </Modal> */}
    </Container>
  );
};

export default LoginForm;
