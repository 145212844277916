/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { iconMap } from "../../../../constants/iconsList";
import {
  UnitMonitoringLable,
  UnitMonitoringStyledButton,
  UnitMonitoringStyledGridItem,
  UnitMonitoringValue,
} from "../../../../styledComponents";
import CloseIcon from "@mui/icons-material/Close";

const SelectedUnitDetails = ({
  unit,
  expanded,
  handleClose,
  handleToggleExpand,
  eLockDetails,
}) => {
  // Log eLockDetails whenever the component renders
  console.log("eLockDetails:", eLockDetails);
  const [currentAddress, setCurrentAddress] = useState("Fetching address...");

  useEffect(() => {
    // Function to fetch the address using reverse geocoding
    const fetchAddress = async (latitude, longitude) => {
      const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
        );
        const data = await response.json();
        console.log("Geocoding API Response:", data); // Log the full response
        if (data.status === "OK" && data.results.length > 0) {
          setCurrentAddress(data.results[0].formatted_address);
        } else {
          console.error("Geocoding error:", data.status); // Log error status
          setCurrentAddress("Address not found");
        }
      } catch (error) {
        console.error("Error fetching address:", error);
        setCurrentAddress("Error fetching address");
      }
    };

    const latitude = eLockDetails?.decodedResponse?.locationInfo?.latitude;
    const longitude = eLockDetails?.decodedResponse?.locationInfo?.longitude;

    if (latitude && longitude) {
      fetchAddress(latitude, longitude);
    } else {
      setCurrentAddress("Coordinates not available");
    }
  }, [eLockDetails]);

  return (
    <Card
      variant="outlined"
      sx={{
        position: "absolute",
        bottom: "1.5rem",
        right: "1.5rem",
        width: "55%",
        borderRadius: "8px",
      }}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "#0A2C47",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={
                iconMap[
                  unit.eLockDetails?.decodedResponse?.locationInfo?.status?.lockStatus === "Open"
                    ? "UNITOFFLINEICON"
                    : "UNITONLINEICON"
                ]
              }
              alt="unitStatusIcon"
              style={{ marginRight: "4px", width: "30px" }}
            />
            {unit.name}
          </Typography>
          <Typography>Last update: {unit.eLockDetails?.decodedResponse?.locationInfo?.dateTime}</Typography>
          <Box display="flex" alignItems="center">
            <UnitMonitoringStyledButton>Track</UnitMonitoringStyledButton>
            <UnitMonitoringStyledButton onClick={handleToggleExpand}>
              <img
                src={iconMap[expanded ? "EXPANDLESSICON" : "EXPANDMOREICON"]}
                alt="expandIcon"
                style={{ marginRight: "5px", width: "14px" }}
              />
              {expanded ? "Collapse" : "Expand"}
            </UnitMonitoringStyledButton>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        {expanded && (
          <Grid mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <UnitMonitoringLable>Speed</UnitMonitoringLable>
                <Typography sx={{ fontSize: "14px", color: "#272560" }}>
                  {eLockDetails?.decodedResponse?.locationInfo?.speed} km/hr
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <UnitMonitoringLable>Coordinates</UnitMonitoringLable>
                <Typography sx={{ fontSize: "14px", color: "#272560" }}>
                  {eLockDetails?.decodedResponse?.locationInfo?.latitude +
                    " " +
                    eLockDetails?.decodedResponse?.locationInfo?.longitude}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <UnitMonitoringLable>Current location</UnitMonitoringLable>
                <Typography sx={{ fontSize: "14px", color: "#272560" }}>
                  {currentAddress}
                </Typography>
              </Grid>
            </Grid>
            <hr style={{ height: "0px", color: "#D8D8DF" }} />
            <Grid container direction="row">
              <Grid item xs={12} md={9}>
                <Typography
                  sx={{
                    mt: 2,
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#0F446B",
                  }}
                >
                  Sensor Values
                </Typography>
                <Grid container spacing={0}>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <Typography
                      sx={{
                        color:
                          eLockDetails?.decodedResponse?.locationInfo?.status
                            ?.lockStatus === "Open"
                            ? "red"
                            : "#26861D",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      <img
                        src={
                          eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.lockStatus === "Open"
                            ? iconMap["LOCKOPENICON"]
                            : iconMap["LOCKICON"]
                        }
                        alt="lockIcon"
                        style={{ marginRight: "4px", width: "12px" }}
                      />
                      {
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.lockStatus
                      }
                    </Typography>
                    <UnitMonitoringLable>Lock Status</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue
                      sx={{
                        color:
                          eLockDetails?.decodedResponse?.locationInfo?.status?.accStatus === "ACC ON"
                            ? "green"
                            : "red", // Conditional color for ignition status
                      }}
                    >
                      {eLockDetails?.decodedResponse?.locationInfo?.status?.accStatus}
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Ignition</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {eLockDetails?.decodedResponse?.locationInfo?.mileage} Km
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Mileage</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {
                        eLockDetails?.decodedResponse?.peripheralData?.[
                          "Battery Power Percent"
                        ]
                      }
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Battery Power</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>hardcoded</UnitMonitoringValue>
                    <UnitMonitoringLable>RFID Card</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.lockWireCut
                      }
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Rope Wire Cut</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.stringStatus
                      }
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>String Status</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {
                        eLockDetails?.decodedResponse?.alarmData?.alarmStatus
                          ?.lowPowerAlarm
                      }
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Low Battery Alarm</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.motorStuck
                      }
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Motor Fault Alarm</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.openingBackCover
                      }
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Open Back Cover</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.VibrationStatus
                      }
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Vibration Alarm</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {" "}
                      {
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.unlockingAlarm
                      }
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Unlocking Alarm</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {" "}
                      {
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.backCoverStatus
                      }
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Back Cover Status</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid container>
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "#0F446B",
                    }}
                  >
                    Connectivity Settings
                  </Typography>
                  <Grid container spacing={0}>
                    <UnitMonitoringStyledGridItem item xs={12} md={6}>
                      <UnitMonitoringValue>
                        {unit.deviceType}
                      </UnitMonitoringValue>
                      <UnitMonitoringLable>Device Type</UnitMonitoringLable>
                    </UnitMonitoringStyledGridItem>
                    <UnitMonitoringStyledGridItem item xs={12} md={6}>
                      <UnitMonitoringValue>
                        {unit.deviceDetails.UID}
                      </UnitMonitoringValue>
                      <UnitMonitoringLable>Unique ID</UnitMonitoringLable>
                    </UnitMonitoringStyledGridItem>
                  </Grid>
                </Grid>

                <Grid container>
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "#0F446B",
                    }}
                  >
                    Custom Fields
                  </Typography>
                  <Grid container spacing={0}>
                    <UnitMonitoringStyledGridItem item xs={12} md={6}>
                      <UnitMonitoringValue>Bookings</UnitMonitoringValue>
                      <UnitMonitoringLable>hardcoded</UnitMonitoringLable>
                    </UnitMonitoringStyledGridItem>
                    <UnitMonitoringStyledGridItem item xs={12} md={6}>
                      <UnitMonitoringValue>Tracking ID</UnitMonitoringValue>
                      <UnitMonitoringLable>hardcoded</UnitMonitoringLable>
                    </UnitMonitoringStyledGridItem>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default SelectedUnitDetails;
