/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ListHeaderNew from "../../../common/ListHeaderNew";
import { Grid } from "@mui/material";
import { fetchCustomersByMultipleCriteria } from "../../../../services/customerNew.service";
import ExportButton from "../../../common/ExportButton";
import ViewModeSwitch from "../../../common/ViewModeSwitch";
import ReportsFilters from "../components/ReportsFilters";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useLogMessages } from "../../../common/WebsocketContext";
import { fetchUnitsMonitoringReportsByMultipleCriteria } from "../../../../services/unitsMonitoring.service";
dayjs.extend(utc);

export default function ReportsPage() {
  // State hooks for data storage
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [envReports, setEnvReports] = useState([]); // New state for environmental reports
  const [limit] = useState(30);
  const [filters, setFilters] = useState({
    reportTypes: "",
    accountId: "",
    deviceId: "",
    startDate: null,
    endDate: null,
  });
  const [isEmpty, setIsEmpty] = useState(false); // Add empty state
  // const [websocketMessage, setWebsocketMessage] = useState({});
  // const { logMessages } = useLogMessages();

  // Location-related logic
  const location = useLocation();
  const shouldShowHeader =
    location.pathname.endsWith("reports") ||
    location.pathname.endsWith("card") ||
    location.pathname.endsWith("list");

  const formatDateRange = (range) => {
    if (!range || range.length !== 2) return { startDate: null, endDate: null };

    const [startDate, endDate] = range;

    const formattedStartDate =
      startDate && dayjs(startDate).isValid()
        ? dayjs(startDate).startOf("day").utc().toISOString() // Start at 00:00:00
        : null;

    const formattedEndDate =
      endDate && dayjs(endDate).isValid()
        ? dayjs(endDate).endOf("day").utc().toISOString() // End at 23:59:59
        : null;

    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
  };

  useEffect(() => {
    const { startDate, endDate } = formatDateRange([
      filters.startDate,
      filters.endDate,
    ]);

    _fetchUnitsMonitoringReportsByMultipleCriteria(
      filters.reportTypes,
      filters.accountId,
      filters.deviceId,
      startDate,
      endDate,
      page,
      limit
    );
  }, [page, filters]);

  // useEffect(() => {
  //   setWebsocketMessage(logMessages);
  // }, [logMessages]);

  const _fetchUnitsMonitoringReportsByMultipleCriteria = async (
    reportTypes,
    accountId,
    deviceId,
    startDate,
    endDate,
    page,
    limit
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      let allReports = [];
      let currentPage = 1;
      let totalFetched = 0;
      const MAX_PAGE = 30; // Define the maximum page limit
      let data;

      // Ensure deviceId is treated as a string
      const formattedDeviceId = deviceId ? String(deviceId) : "";

      do {
        if (currentPage > MAX_PAGE) break; // Stop fetching if page exceeds the maximum
        data = await fetchUnitsMonitoringReportsByMultipleCriteria(
          reportTypes,
          accountId,
          formattedDeviceId, // Pass the formatted deviceId as a string
          startDate,
          endDate,
          currentPage,
          limit
        );
        allReports = [...allReports, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      // Filter to keep only the most recent entry per deviceId
      const uniqueReportsMap = new Map();
      allReports.forEach((report) => {
        // Only keep the latest report for each deviceId
        if (!uniqueReportsMap.has(report.deviceId)) {
          uniqueReportsMap.set(report.deviceId, report);
        } else {
          const existingReport = uniqueReportsMap.get(report.deviceId);
          if (new Date(report.createdAt) > new Date(existingReport.createdAt)) {
            uniqueReportsMap.set(report.deviceId, report);
          }
        }
      });

      const filteredReports = Array.from(uniqueReportsMap.values());
      setReports(filteredReports);
      console.log("filteredReports", filteredReports);


       // Create a map to keep only the most recent non-zero entries per deviceId
    const uniqueEnvReportsMap = new Map();
      allReports.forEach((report) => {
        const existingReport = uniqueEnvReportsMap.get(report.deviceId);
  
        // Check if the current report has non-zero values and is more recent
        const hasValidDistance = report.totalDistance && report.totalDistance !== "0";
        const hasValidFuel = report.totalFuelConsumption && report.totalFuelConsumption !== "0";
  
        if (hasValidDistance || hasValidFuel) {
          if (!existingReport || new Date(report.createdAt) > new Date(existingReport.createdAt)) {
            uniqueEnvReportsMap.set(report.deviceId, report);
          }
        }
      });
      // Convert the map values to an array and format for `envReportArray`
      const envReportArray = Array.from(uniqueEnvReportsMap.values()).map((report) => ({
        deviceId: report.deviceId,
        totalDistance: report.totalDistance,
        totalFuelConsumption: report.totalFuelConsumption,
        createdAt: report.createdAt,
      }));

      setEnvReports(envReportArray); // Set the new environmental reports state

      console.log("Environmental Reports:", envReportArray);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching Reports:", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  console.log("reports", reports);

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
    setReports([]);
    setPage(1);
  };

  const fetchMore = () => {
    if (reports.length < count) {
      setPage((prevPage) => prevPage + 1); // Increment page number for search results
    } else {
      console.log("All reports have been fetched.");
    }
  };

  return (
    <div className="container">
      {shouldShowHeader && (
        <Grid container flexDirection="column">
          <Grid item xs={12}>
            <ListHeaderNew name="Report" mode="Create" />
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{
              marginTop: "30px",
              marginBottom: "30px",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12}>
              <ReportsFilters
                filters={filters}
                onFiltersChange={handleFiltersChange}
              />{" "}
            </Grid>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <ViewModeSwitch name="Report" />
              <ExportButton name="Reports" accounts={reports}></ExportButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Outlet context={{ reports, envReports, fetchMore, isLoading, isEmpty }} />
    </div>
  );
}
