/* eslint-disable no-unused-vars */
// React core imports
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

// Material-UI imports
import {
  Grid,
  Menu,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import SimCardIcon from '@mui/icons-material/SimCard'
// import SimCardDownloadIcon from '@mui/icons-material/SimCardDownloadOutlined'
import AccountCircleIcon from "@mui/icons-material/AccountCircleOutlined";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import debounce from "lodash.debounce";

// Styled components and utility functions
import {
  StyledTableHead,
  StyledMenuItem,
  StyledIconButton,
  StyledTableContainer,
} from "../../../../styledComponents";
import {
  getAccountsSubscriptionColor,
  formatDate,
} from "../../../../utils/functions";

// Modal components
// import DeleteModal from '../../../modals/DeleteModal'
// import SimCardAllocationModal from '../../../modals/SimCardAllocationModal'
// import SimCardDeAllocationModal from '../../../modals/SimCardDeAllocationModal'
import DeactivateAccountModal from "../../../modals/DeactivateAccountModal";
import ActivateAccountModal from "../../../modals/ActivateAccountModal";

// Service and common components
import {
  deleteAccount,
  updateAccount,
} from "../../../../services/accountNew.service";
import Loader from "../../../common/Loader";
import { accountColumn } from "../../../../utils/constant";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EmptyState from "../../../common/EmptyState";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";

const AccountsListTable = () => {
  const [errors, setErrors] = useState([]);
  const [openMenuAccountId, setOpenMenuAccountId] = useState(null);
  const [open, setOpen] = useState(false);
  // const [simCardOpen, setSimCardOpen] = useState(false)
  // const [simCardDeallocationOpen, setSimCardDeallocationOpen] = useState(false)
  const [deactivateOpen, setDeactivateOpen] = useState(false);
  const [activateOpen, setActivateOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);

  const navigate = useNavigate();
  const { accounts, getAccounts, fetchMore, isLoading, isEmpty } =
    useOutletContext();

  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload, getAccounts]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [accounts, isLoading, handleScroll]);

  const handleUpdateAccount = useCallback(
    async (updatedAccount) => {
      try {
        const response = await updateAccount(selectedItem.id, updatedAccount);
        if (response) {
          setSuccessMessage("Account updated successfully.");
          setErrorMessage("");
          setShouldReload(true);
        }
      } catch (error) {
        setErrorMessage(error.message || "An unexpected error occurred");
      }
    },
    [selectedItem]
  );

  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuAccountId(selectedItem.id);
    setSelectedItem(selectedItem);
  }, []);

  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuAccountId(null);
    event?.stopPropagation();
  }, []);

  // const handleSimCardModalOpen = useCallback(
  //   (event, accountId) => {
  //     handleActionsMenuClose()
  //     setSelectedItem(accountId)
  //     setSimCardOpen(true)
  //   },
  //   [handleActionsMenuClose]
  // )

  // const handleSimCardModalClose = useCallback(() => {
  //   setSimCardOpen(false)
  //   setSelectedItem(null)
  // }, [])

  // const allocateSimCard = useCallback(
  //   async (simCard) => {
  //     const updatedAccount = {
  //       ...selectedItem,
  //       simCardId: simCard.id,
  //       simCardDetails: {}
  //     }

  //     delete updatedAccount.simCardDetails._id
  //     setSelectedItem(updatedAccount)
  //     handleUpdateAccount(updatedAccount)

  //     setSimCardOpen(false)
  //     setSelectedItem(null)
  //   },
  //   [selectedItem, handleUpdateAccount]
  // )

  // const handleSimCardDeallocationModalOpen = useCallback(
  //   (event, account) => {
  //     handleActionsMenuClose()
  //     setSelectedItem(account)
  //     setSimCardDeallocationOpen(true)
  //   },
  //   [handleActionsMenuClose]
  // )

  // const handleSimCardDeallocationModalClose = useCallback(() => {
  //   setSimCardDeallocationOpen(false)
  //   setSelectedItem(null)
  // }, [])

  // const deallocateSimCard = useCallback(async () => {
  //   const updatedAccount = {
  //     ...selectedItem,
  //     simCardId: null,
  //     simCardDetails: {}
  //   }

  //   delete updatedAccount.simCardDetails._id

  //   setSelectedItem(updatedAccount)
  //   handleUpdateAccount(updatedAccount)

  //   setSimCardDeallocationOpen(false)
  //   setSelectedItem(null)
  // }, [selectedItem, handleUpdateAccount])

  // const handleDeleteModalOpen = useCallback(
  //   (event, accountId) => {
  //     handleActionsMenuClose()
  //     setSelectedItem(accountId)
  //     setOpen(true)
  //   },
  //   [handleActionsMenuClose]
  // )

  // const handleDeleteModalClose = useCallback(() => {
  //   setOpen(false)
  //   setSelectedItem(null)
  // }, [])

  // const deleteAccount = useCallback(() => {
  //   setOpen(false)
  //   setSelectedItem(null)
  // }, [])

  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteAccount(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, "Account deleted successfully.");
    //     handleError([]);
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error);
    // } finally {
    //   setOpen(false);
    //   setSelectedItem(null);
    // }
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
    }
  };
  const handleError = (error) => {
    window.scrollTo(0, 0);
    // if (error.name === 'ValidationError') {
    //   handleValidationErrors(error)
    // } else {
    setErrors(error.error);
    // }
  };

  const handleDeactivateModalOpen = useCallback(
    (event, accountId) => {
      handleActionsMenuClose();
      setSelectedItem(accountId);
      setDeactivateOpen(true);
    },
    [handleActionsMenuClose]
  );

  const handleDeactivateModalClose = useCallback(() => {
    setDeactivateOpen(false);
    setSelectedItem(null);
  }, []);

  const deactivateAccount = useCallback(() => {
    if (selectedItem?.subscriptions?.[0]?.subscriptionStatus) {
      const status = "CANCELED";

      const updatedSubscriptions = selectedItem.subscriptions.map(
        (subscription, index) => {
          if (index === 0) {
            return { ...subscription, subscriptionStatus: status };
          }
          return subscription;
        }
      );

      const updatedAccount = {
        ...selectedItem,
        subscriptions: updatedSubscriptions,
      };

      setSelectedItem(updatedAccount);
      handleUpdateAccount(updatedAccount);

      setDeactivateOpen(false);
      setSelectedItem(null);
    } else {
      console.error("No subscriptionStatus found to update.");
    }
  }, [selectedItem, handleUpdateAccount]);

  const handleActivateModalOpen = useCallback(
    (event, accountId) => {
      handleActionsMenuClose();
      setSelectedItem(accountId);
      setActivateOpen(true);
    },
    [handleActionsMenuClose]
  );

  const handleActivateModalClose = useCallback(() => {
    setActivateOpen(false);
    setSelectedItem(null);
  }, []);

  const activateAccount = useCallback(() => {
    if (selectedItem?.subscriptions?.[0]?.subscriptionStatus) {
      const status = "ACTIVE";

      const updatedSubscriptions = selectedItem.subscriptions.map(
        (subscription, index) => {
          if (index === 0) {
            return { ...subscription, subscriptionStatus: status };
          }
          return subscription;
        }
      );

      const updatedAccount = {
        ...selectedItem,
        subscriptions: updatedSubscriptions,
      };

      setSelectedItem(updatedAccount);
      handleUpdateAccount(updatedAccount);

      setActivateOpen(false);
      setSelectedItem(null);
    } else {
      console.error("No subscriptionStatus found to update.");
    }
  }, [selectedItem, handleUpdateAccount]);

  const navigateToEditScreen = useCallback(
    (accountId) => {
      const tab = "details";
      navigate(`/management/accounts/edit/${accountId}/${tab}`);
    },
    [navigate]
  );

  return (
    <>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledTableContainer ref={containerRef}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="CUSTOMERS"
              message="Oops! You have no customers for your search criteria"
              info="Please feel free to create a new customer by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {!isLoading && !isEmpty && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <StyledTableHead>
                <TableRow>
                  {accountColumn.map((column) => (
                    <TableCell key={column}>
                      <Typography variant="tableHeader">{column}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {accounts?.map((account) => (
                  <TableRow
                    key={account.id}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigateToEditScreen(account.id)}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ verticalAlign: "top" }}
                    >
                      <Typography variant="tableValue">
                        {account.name}
                      </Typography>
                      <Typography
                        variant="cardStatus2"
                        sx={{
                          color: getAccountsSubscriptionColor(
                            account.subscriptions[0]?.subscriptionStatus
                          ),
                        }}
                      >
                        &bull; &nbsp;
                        {account.subscriptions[0]?.subscriptionStatus}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        Easy Track Solutions
                        {/* {account.parentAccountName || '--'} */}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {account.billingPlanName}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {account?.subscriptions[0].pricingCycle}
                      </Typography>
                    </TableCell>
                    {/* <TableCell align="left" sx={{ verticalAlign: 'top' }}>
                      <Typography variant="tableValue">
                        {account?.simCardDetails?.simCardNumber || '--'}
                      </Typography>
                    </TableCell> */}
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {formatDate(account?.subscriptions[0].startDate)}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {formatDate(account?.subscriptions[0].renewalDate)}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <StyledIconButton
                        id={`menu-button-${account.id}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleActionsMenuOpen(event, account);
                        }}
                      >
                        <MoreVertIcon />
                      </StyledIconButton>
                      <Menu
                        anchorEl={document.getElementById(
                          `menu-button-${account.id}`
                        )}
                        open={openMenuAccountId === account.id}
                        onClose={(event) => handleActionsMenuClose(event)}
                      >
                        {/* {account?.simCardId !== null &&
                        account?.simCardId !== '' ? (
                          <StyledMenuItem
                            onClick={(e) => {
                              e.stopPropagation()
                              handleSimCardDeallocationModalOpen(e, account)
                            }}
                          >
                            <SimCardDownloadIcon
                              sx={{ marginRight: '10px', color: '#0772B8' }}
                            />{' '}
                            Deallocate Sim Card
                          </StyledMenuItem>
                        ) : (
                          <StyledMenuItem
                            onClick={(e) => {
                              e.stopPropagation()
                              handleSimCardModalOpen(e, account)
                            }}
                          >
                            <SimCardIcon
                              sx={{ marginRight: '10px', color: '#0772B8' }}
                            />{' '}
                            Allocate Sim Card
                          </StyledMenuItem>
                        )} */}
                        {account &&
                        account.subscriptions &&
                        account.subscriptions[0]?.subscriptionStatus ===
                          "ACTIVE" ? (
                          <StyledMenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeactivateModalOpen(e, account);
                            }}
                          >
                            <CancelIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            Deactivate Account
                          </StyledMenuItem>
                        ) : (
                          <StyledMenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              handleActivateModalOpen(e, account);
                            }}
                          >
                            <AccountCircleIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            Activate Account
                          </StyledMenuItem>
                        )}
                        {/* <StyledMenuItem
                          onClick={(e) => {
                            e.stopPropagation()
                            handleDeleteModalOpen(e, account)
                          }}
                        >
                          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                            <DeleteOutlineIcon
                              sx={{ marginRight: '10px', color: '#0772B8' }}
                            />
                            <Typography>Delete Account</Typography>
                          </Grid>
                        </StyledMenuItem> */}
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledTableContainer>

      <ActivateAccountModal
        activateOpen={activateOpen}
        handleActivateModalClose={handleActivateModalClose}
        selectedItem={selectedItem}
        activateAccount={activateAccount}
      />
      <DeactivateAccountModal
        deactivateOpen={deactivateOpen}
        handleDeactivateModalClose={handleDeactivateModalClose}
        selectedItem={selectedItem}
        deactivateAccount={deactivateAccount}
      />
      {/* <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      /> */}
      {/* <SimCardAllocationModal
        simCardOpen={simCardOpen}
        handleSimCardModalClose={handleSimCardModalClose}
        selectedItem={selectedItem}
        allocateSimCard={allocateSimCard}
      />
      <SimCardDeAllocationModal
        simCardDeallocationOpen={simCardDeallocationOpen}
        handleSimCardDeallocationModalClose={
          handleSimCardDeallocationModalClose
        }
        selectedItem={selectedItem}
        deallocateSimCard={deallocateSimCard}
      /> */}
    </>
  );
};

export default AccountsListTable;
