/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useCallback, useEffect, useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import PropTypes from 'prop-types'
import { Typography, Grid, Modal, Button } from '@mui/material'
import {
  StyledModal,
  StyledModalContent,
  StyledModalHr,
  StyledCancelButton,
  StyledAllocateButton,
  FlexContainer,
  StyledModalListCard,
  StyledImage24,
  ModalButton
} from '../../styledComponents'
import { DriversModel } from '../../model/drivers.model'
import SearchField from '../common/SearchField'
import { iconMap } from '../../constants/iconsList'
import {
  fetchUsersByMultipleCriteria,
  fetchUsersByRoleNameAndAccountId
} from '../../services/userNew.service'
import SearchFormSelect from '../common/SearchFormSelect'

const DriverAllocationModal = ({
  driverOpen,
  handleDriverAllocationModalClose,
  allocateDriver,
  handleDriverAllocationModalBack
}) => {
  const [drivers, setDrivers] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(1)
  const [limit] = useState(15)
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [filters, setFilters] = useState({
    id: '',
    userName: ''
  })

  // Handle scroll event
  const handleScroll = useCallback(
    (e) => {
      if (page * limit <= count) {
        const { scrollTop, scrollHeight, clientHeight } = e.target
        if (
          scrollTop + clientHeight >= scrollHeight &&
          !isLoading &&
          !isEmpty
        ) {
          setPage((prevPage) => prevPage + 1) // Load the next page
        }
      }
    },
    [isLoading, isEmpty]
  )

  useEffect(() => {
    if (driverOpen) {
      _fetchUsersByMultipleCriteria(
        filters.id,
        filters.userName,
        'Driver',
        page,
        limit
      )
    }
  }, [driverOpen, page, filters])

  const _fetchUsersByMultipleCriteria = async (
    id,
    userName,
    roleName,
    page,
    limit
  ) => {
    if (isLoading) return // Prevent multiple calls
    setIsLoading(true)

    try {
      const response = await fetchUsersByMultipleCriteria(
        id,
        userName,
        roleName,
        page,
        limit
      )
      setCount(response.count)
      setDrivers((prevDrivers) => [...prevDrivers, ...response.rows])
      setIsEmpty(response.rows.length === 0) // Set empty state
    } catch (error) {
      console.log(error)
      // const errorMessage = error?.error.message || 'An unknown error occurred'
      // console.error('Error fetching consignments:', errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearchChange = async (value) => {
    console.log()
    setFilters({ ...filters, id: value })
    setDrivers([])
  }

  return (
    <Modal
      open={driverOpen}
      onClose={handleDriverAllocationModalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <StyledModal>
        <Typography variant="modalTitle" id="child-modal-title">
          Allocate Driver
        </Typography>
        <Typography variant="modalSubTitle" id="child-modal-subtitle">
          Choose a Driver that can be allocated to your consignment.
        </Typography>
        <Grid item xs={12} sm={2.5} md={12}>
          <SearchFormSelect
            placeholder="Search Driver"
            value={filters.id || ''}
            onChange={(e) => handleSearchChange(e)}
            options={drivers.map((item) => ({
              value: item.id,
              label: item.firstName + ' ' + item.lastName
            }))}
          />
        </Grid>
        {/* <SearchField name="Driver" onSearchChange={handleSearchChange} /> */}
        {errorMessage && <p>{errorMessage}</p>}
        <StyledModalContent
          onScroll={handleScroll}
          style={{ maxHeight: '200px', overflowX: 'auto' }}
        >
          {drivers.map((driver) => (
            <StyledModalListCard key={driver}>
              <FlexContainer item>
                <Grid>
                  <StyledImage24 src={iconMap['DeliveryTruck2']} alt="icon" />
                </Grid>
                <Grid>{driver.userName}</Grid>
              </FlexContainer>
              <Grid item>
                <StyledAllocateButton
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => allocateDriver(driver)}
                >
                  ALLOCATE
                </StyledAllocateButton>
              </Grid>
            </StyledModalListCard>
          ))}
        </StyledModalContent>
        <StyledModalHr />
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <ModalButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleDriverAllocationModalBack}
            >
              BACK
            </ModalButton>
          </Grid>
          <Grid item>
            <StyledCancelButton
              fullWidth
              onClick={handleDriverAllocationModalClose}
            >
              CANCEL
            </StyledCancelButton>
          </Grid>
        </Grid>
      </StyledModal>
    </Modal>
  )
}

DriverAllocationModal.propTypes = {
  driverOpen: PropTypes.bool.isRequired,
  handleDriverAllocationModalClose: PropTypes.func.isRequired,
  selectedAccount: PropTypes.object,
  allocateDriver: PropTypes.func.isRequired,
  handleDriverAllocationModalBack: PropTypes.func.isRequired
}

export default DriverAllocationModal
