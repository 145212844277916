/* eslint-disable no-unused-vars */
// React core imports
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "../../../common/Loader";
import DriverRunSheet from "./DriverRunSheet";
import {
  driverRunSheetData,
  envReportTableColumns,
  reportTableColumns,
  reportTypes,
} from "../../../../utils/constant";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import debounce from "lodash.debounce";
import EmptyState from "../../../common/EmptyState";
import {
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
} from "../../../../styledComponents";
import FormSelect from "../../../common/FormSelect";


const ReportsListTable = () => {
  // const [reports, setReports] = useState([]);
  const [reportType, setReportType] = useState('Monitoring Report'); // State for report type
  const [page] = useState(1);
  const [limit] = useState(15);
  const [shouldReload, setShouldReload] = useState(false);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const { reports, envReports, fetchMore, isLoading, isEmpty } = useOutletContext();
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const navigateToEditScreen = useCallback(() => {
    navigate(`/monitoring/reports/driver-run-sheet`);
  }, [navigate]);

  // Emissions calculation logic for envReports
  const emissionFactors = {
    CO2: 2.68, // kg of CO₂ per liter of diesel
    CO: 0.01,  // kg of CO per liter of diesel
    NOX: 0.007, // kg of NOx per liter of diesel
    PM: 0.0004, // kg of PM per liter of diesel
    HC: 0.0005, // kg of HC per liter of diesel
  };

  const calculateEmissions = (totalFuelConsumption) => {
    const fuelConsumption = parseFloat(totalFuelConsumption.replace(/[^\d.-]/g, '')) || 0;
    return {
      CO2: (fuelConsumption * emissionFactors.CO2).toFixed(2),
      CO: (fuelConsumption * emissionFactors.CO).toFixed(2),
      NOX: (fuelConsumption * emissionFactors.NOX).toFixed(2),
      PM: (fuelConsumption * emissionFactors.PM).toFixed(2),
      HC: (fuelConsumption * emissionFactors.HC).toFixed(2),
    };
  };

  // Process the envReports for emissions calculation
  const processedEnvReports = envReports?.map((report) => {
    const emissions = calculateEmissions(report.totalFuelConsumption);
    return {
      ...report,
      carbonDioxide: emissions.CO2,
      carbonMonoxide: emissions.CO,
      nitrogenOxides: emissions.NOX,
      particulateMatter: emissions.PM,
      hydrocarbons: emissions.HC,
    };
  });


  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [reports, isLoading, handleScroll]);

  return (
    <>
    <div style={{ maxWidth: "15rem"}}>
    <FormSelect 
            label="Choose Report Type"
            required
            value={reportType}
            onChange={(e) => setReportType(e)} // Update state with selected value
            options={reportTypes.map((item) => ({
              value: item.value,
              label: `${item.name}`,
            }))}
          />
    </div>
      <StyledTableContainer ref={containerRef}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="DEVICE REPORTS"
              message="No reports available for your devices"
            />
          </Grid>
        )}
        {!isLoading && !isEmpty && reportType === 'Monitoring Report' && (
          <TableContainer
            component={Paper}
            sx={{ overflow: "auto", maxWidth: "75rem" }}
          >
            <Table aria-label="device reports table">
              <StyledTableHead>
                <TableRow>
                  {reportTableColumns.map((column) => (
                    <StyledTableCell key={column}>
                      <Typography variant="tableHeader">{column}</Typography>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {reports?.map((report) => (
                  <TableRow key={report.id} sx={{ cursor: "pointer" }}>
                    <StyledTableCell component="th" scope="row">
                      <Typography variant="tableValue">
                        {report.deviceId}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.dateTime || "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.direction || "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.gpsStatus || "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.ignitionStatus || "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.lockStatus || "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.motorStatus || "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.lockWireStatus || "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.simStatus || "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.mileage || "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.satellite || "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.lowBatteryAlarm || "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.FuelStolenAlarm !== null
                          ? report.FuelStolenAlarm
                          : "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.fuelSensorDisconnectAlarm !== null
                          ? report.fuelSensorDisconnectAlarm
                          : "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.harshAcceleration !== null
                          ? report.harshAcceleration
                          : "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.harshTurning !== null
                          ? report.harshTurning
                          : "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.idleAlarm !== null ? report.idleAlarm : "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.lockWireCutAlarm !== null
                          ? report.lockWireCutAlarm
                          : "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.lowSpeedAlarm !== null
                          ? report.lowSpeedAlarm
                          : "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.motorStuckAlarm !== null
                          ? report.motorStuckAlarm
                          : "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.overSpeedAlarm !== null
                          ? report.overSpeedAlarm
                          : "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.overSpeedStatus || "--"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="tableValue">
                        {report.shakeVibrateAlarm !== null
                          ? report.shakeVibrateAlarm
                          : "--"}
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

  {!isLoading && !isEmpty &&  reportType === 'Environmental Report' && (
          <TableContainer
            component={Paper}
            sx={{ overflow: "auto", maxWidth: "75rem" }}
          >
            <Table aria-label="env reports table">
              <StyledTableHead>
                <TableRow>
                  {envReportTableColumns.map((column) => (
                    <StyledTableCell key={column}>
                      <Typography variant="tableHeader">{column}</Typography>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
        {!isEmpty && processedEnvReports?.map((report) => (
          <TableRow key={report.deviceId} sx={{ cursor: "pointer" }}>
            <StyledTableCell component="th" scope="row">
              <Typography variant="tableValue">{report.deviceId}</Typography>
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography variant="tableValue">{report.totalDistance || "--"}</Typography>
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography variant="tableValue">{report.totalFuelConsumption || "--"}</Typography>
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography variant="tableValue">{report.carbonDioxide} kg</Typography>
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography variant="tableValue">{report.carbonMonoxide} kg</Typography>
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography variant="tableValue">{report.nitrogenOxides} kg</Typography>
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography variant="tableValue">{report.particulateMatter} kg</Typography>
            </StyledTableCell>
            <StyledTableCell align="left">
              <Typography variant="tableValue">{report.hydrocarbons} kg</Typography>
            </StyledTableCell>
          </TableRow>
        ))}
        </TableBody>
        </Table>
          </TableContainer>
        )}

        {!isLoading && !isEmpty && reportType === 'Driver Run Sheets' && (
                    <DriverRunSheet data={driverRunSheetData} />
                )}
  
      </StyledTableContainer>
    </>
  );
};

export default ReportsListTable;
