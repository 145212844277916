/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Country } from "country-state-city";
import {
  StyledInputControlContainer,
  StyledSelect2,
  StyledStartAdornmentTextfield2,
} from "../../styledComponents";
import { Grid, InputAdornment, MenuItem, Typography } from "@mui/material";
import PropTypes from "prop-types";
import FormError from "./FormError";

const FormCurrency = ({
  label,
  placeholder,
  value,
  onChange,
  type = "number",
  error,
  errorMessage,
  required = false,
}) => {
  const [countries, setCountries] = useState([]);
  const [enhancedCountries, setEnhancedCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});

  useEffect(() => {
    const fetchCountries = async () => {
      const allCountries = Country.getAllCountries();
      const enhancedData = allCountries.map((country) => {
        const countryDetails = Country.getCountryByCode(country.isoCode);
        return {
          ...country,
          ...countryDetails,
        };
      });
      setCountries(allCountries);
      setEnhancedCountries(enhancedData);
      setSelectedCountry(Country.getCountryByCode("AU"))
    };

    fetchCountries();
  }, []);

  // useEffect(() => {
  //   alert(selectedCountry.isoCode)
  //   onChange('billingInformation.currency', selectedCountry.currency);
  // },[]);

  const handleCountryChange = (event) => {
    const country = event.target.value;
    console.log("blahhh")
    console.log(Country.getCountryByCode("AU"));
    setSelectedCountry(country);
    console.log("wah")
    console.log(country);
    onChange("billingInformation.currency", country.currency);
  };

  const handleAmountChange = (event) => {
    onChange("billingInformation.amount", event.target.value);
  };

  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && " *"}
      </Typography>
      <StyledStartAdornmentTextfield2
        variant="outlined"
        margin="normal"
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={handleAmountChange}
        type={type}
        error={!!error}
        inputProps={{ step: "any" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#f7f7f8",
                }}
              >
                <Typography variant="startAdornment">
                  <StyledSelect2
                    variant="standard"
                    disableUnderline
                    // defaultValue="TZ"
                    placeholder="Filter"
                    value={selectedCountry.isoCode}
                    onChange={handleCountryChange}
                  >
                    {enhancedCountries.map((country, i) => (
                      <MenuItem key={i} value={country}>
                        <Grid
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingLeft: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "5B5B6E",
                              fontSize: "26px",
                              fontWeight: "500",
                              marginRight: "8px",
                            }}
                          >
                            {country.flag}
                          </Typography>
                          <Typography
                            sx={{
                              color: "5B5B6E",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {country.currency}
                          </Typography>
                        </Grid>
                      </MenuItem>
                    ))}
                  </StyledSelect2>
                </Typography>
              </Grid>
            </InputAdornment>
          ),
        }}
      />
      {error && <FormError errorMessage={errorMessage} />}
    </StyledInputControlContainer>
  );
};

FormCurrency.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
};

export default FormCurrency;
