import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  FlexContainerSB,
  StyledCargoRadio,
  StyledFormContainer1,
  StyledFormSection1,
  StyledInputControlContainer,
  StyledPaymentTermContainer,
  StyledSectionHeader,
} from "../../../../styledComponents.js";
import FormOptionSelector from "../../../common/FormOptionSelector.js";
import FormFieldMultiline from "../../../common/FormFieldMultiline.js";
import FormFieldWithEndAdornment from "../../../common/FormFieldWithEndAdornment.js";
import { iconMap } from "../../../../constants/iconsList.js";
import { DoubleArrowIconContainer } from "../../drivers/styles/DriversStyles.js";
import {
  paymentTerms,
  transportationModeType,
  transportationRateFinalize,
} from "../../../../utils/constant.js";
import PropTypes from "prop-types";
import FormCurrency from "../../../common/FormCurrency.js";

const ConsignmentsForm4 = ({
  consignment,
  onFormValueChange,
  validationErrors,
}) => {
  const [selectedTerm, setSelectedTerm] = useState("");
  useEffect(() => {
    setSelectedTerm(consignment?.billingInformation?.paymentTerm || "");
  }, [consignment]);

  const handleFieldChange = async (name, value) => {
    try {
      let updatedConsignment = { ...consignment };

      if (name.startsWith("billingInformation.")) {
        const field = name.split(".")[1];
        updatedConsignment.billingInformation = {
          ...updatedConsignment.billingInformation,
          [field]: value,
        };
      } else if (name.startsWith("additionalInformation.")) {
        const field = name.split(".")[1];
        updatedConsignment.additionalInformation = {
          ...updatedConsignment.additionalInformation,
          [field]: value,
        };
      } else {
        updatedConsignment[name] = value;
      }

      onFormValueChange(updatedConsignment, name, value, 3); // Pass step index here
    } catch (error) {
      console.error("Error in handleFieldChange:", error);
    }
  };

  return (
    <StyledFormContainer1>
      <StyledFormSection1>
        <StyledSectionHeader>
          <Typography variant="heading">Additional Details</Typography>
        </StyledSectionHeader>
        <Grid>
          <FormOptionSelector
            label="Transportation Mode"
            required
            options={transportationModeType}
            selectedOption={
              consignment?.additionalInformation?.transportationMode
            }
            handleOptionChange={(value) =>
              handleFieldChange(
                "additionalInformation.transportationMode",
                value
              )
            }
            error={
              !!validationErrors["additionalInformation.transportationMode"]
            }
            errorMessage={
              validationErrors["additionalInformation.transportationMode"]
            }
          />
          <FormFieldMultiline
            label="Special Instructions"
            placeholder="Enter Special Instructions"
            value={consignment?.additionalInformation?.specialInstructions}
            onChange={(e) =>
              handleFieldChange(
                "additionalInformation.specialInstructions",
                e.target.value
              )
            }
            error={
              !!validationErrors["additionalInformation.specialInstructions"]
            }
            errorMessage={
              validationErrors["additionalInformation.specialInstructions"]
            }
            multiline
            rows={4}
          />
        </Grid>
      </StyledFormSection1>

      <StyledFormSection1>
        <StyledSectionHeader>
          <Typography variant="heading">Billing Information</Typography>
        </StyledSectionHeader>
        <Grid>
          <FormOptionSelector
            label="Is the Transport Rate Finalized?"
            required
            options={transportationRateFinalize}
            selectedOption={consignment?.billingInformation?.rateFinalized}
            handleOptionChange={(value) =>
              handleFieldChange("billingInformation.rateFinalized", value)
            }
            error={!!validationErrors.billingInformation?.rateFinalized}
            errorMessage={validationErrors.billingInformation?.rateFinalized}
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormCurrency
                label="Cost"
                type="number"
                // required
                placeholder="Enter Cost"
                value={consignment.billingInformation.amount || ""}
                onChange={(field, event) => {
                  const value = event === "" ? null : parseFloat(event);
                  handleFieldChange(field, value);
                }}
                error={!!validationErrors["billingInformation.amount"]}
                errorMessage={validationErrors["billingInformation.amount"]}
              ></FormCurrency>
            </Grid>
            <Grid item xs={6}>
              <FormFieldWithEndAdornment
                label="VAT %"
                placeholder="Enter VAT"
                value={consignment?.billingInformation?.VAT || ""}
                onChange={(e) => {
                  const value =
                    e.target.value === "" ? null : parseFloat(e.target.value);
                  handleFieldChange("billingInformation.VAT", value);
                }}
                type="number"
                // required
                endAdornmentText="%"
                error={!!validationErrors["billingInformation.VAT"]}
                errorMessage={validationErrors["billingInformation.VAT"]}
              />
            </Grid>
          </Grid>
          <FormFieldMultiline
            label="Additional Notes"
            placeholder="Enter Additional Notes"
            value={consignment.billingInformation.notes || ""}
            onChange={(e) =>
              handleFieldChange("billingInformation.notes", e.target.value)
            }
            multiline
            error={!!validationErrors["billingInformation.notes"]}
            errorMessage={validationErrors["billingInformation.notes"]}
          />
          <StyledInputControlContainer>
            <Typography variant="label">Choose a Payment Term *</Typography>
            {validationErrors["billingInformation.paymentTerm"] && (
              <Box display="flex" alignItems="center" mt={1}>
                <img
                  src={iconMap["ALERTICON"]}
                  alt="alertIcon"
                  style={{ marginRight: "5px", width: "25px", height: "25px" }}
                />
                <Typography sx={{ fontSize: "16px", color: "#e71111" }}>
                  {validationErrors["billingInformation.paymentTerm"]}
                </Typography>
              </Box>
            )}
            <Grid container alignItems="center" spacing={1}>
              {paymentTerms.map((term, index) => (
                <FlexContainerSB item xs={12} sm={3} key={index}>
                  <StyledPaymentTermContainer
                    item
                    xs={12}
                    selected={selectedTerm === term.value}
                    onClick={() =>
                      handleFieldChange(
                        "billingInformation.paymentTerm",
                        term.value
                      )
                    }
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      {term.name}
                    </Typography>
                    <StyledCargoRadio
                      value={term.value}
                      checked={selectedTerm === term.value}
                      onChange={() =>
                        handleFieldChange(
                          "billingInformation.paymentTerm",
                          term.value
                        )
                      }
                    />
                  </StyledPaymentTermContainer>
                  {selectedTerm === "Credit point" &&
                    term.value === "Credit point" && (
                      <>
                        <Grid item xs={2}>
                          <DoubleArrowIconContainer>
                            <img src={iconMap["Arrow"]} alt="arrow" />
                          </DoubleArrowIconContainer>
                        </Grid>
                        <Grid item xs={4}>
                          <FormFieldWithEndAdornment
                            sx={{ marginBottom: "0px" }}
                            type="number"
                            value={
                              consignment.billingInformation.creditDays || ""
                            }
                            onChange={(event) => {
                              const value =
                                event.target.value === ""
                                  ? null
                                  : parseInt(event.target.value);
                              handleFieldChange(
                                "billingInformation.creditDays",
                                value
                              );
                            }}
                            error={
                              !!validationErrors[
                              "billingInformation.creditDays"
                              ]
                            }
                            errorMessage={
                              validationErrors["billingInformation.creditDays"]
                            }
                            endAdornmentText="days"
                          />
                        </Grid>
                      </>
                    )}
                </FlexContainerSB>
              ))}
            </Grid>
          </StyledInputControlContainer>
        </Grid>
      </StyledFormSection1>

      {/* Billing Details Section */}
      {/* <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Billing Details</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    '&.Mui-checked': {
                      color: '#0772B8',
                    },
                  }}
                />
              }
              label="Yes, same as customer details"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontWeight: 400,
                  fontSize: '16px',
                  color: '#0F446B',
                },
              }}
            />
            <CardContent
              sx={{
                backgroundColor: '#EEEEF0',
                width: '75%',
                borderRadius: '8px',
              }}
            >
              <Grid sx={{ borderBottom: '1px solid #E0E0E0' }}>
                <CardLabel variant="cardLabel">CUSTOMER DETAILS</CardLabel>
                <Grid container>
                  <Grid item container justifyContent="space-between" sx={{ marginBottom: '4px', marginTop: '8px' }}>
                    <CardLabel variant="cardLabel">Customer Name:</CardLabel>
                    <Typography
                      variant="cardValue"
                      sx={{ fontWeight: 600, fontSize: '14px', lineHeight: '18.9px', color: '#0F446B' }}
                    >
                      {customerDetails?.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item container justifyContent="space-between" sx={{ marginBottom: '4px' }}>
                    <CardLabel variant="cardLabel">Tax Payer&lsquo;s Number:</CardLabel>
                    <Typography
                      variant="cardValue"
                      sx={{ fontWeight: 600, fontSize: '14px', lineHeight: '18.9px', color: '#0F446B' }}
                    >
                      {customerDetails?.taxId}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginBottom: '6px' }}>
                  <Grid item container justifyContent="space-between" sx={{ marginBottom: '4px' }}>
                    <CardLabel variant="cardLabel">Payment Terms:</CardLabel>
                    <Typography
                      variant="cardValue"
                      sx={{ fontWeight: 600, fontSize: '14px', lineHeight: '18.9px', color: '#0F446B' }}
                    >
                      {consignment?.billingInformation?.paymentTerm}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={{ borderBottom: '1px solid #E0E0E0', marginTop: '6px' }}>
                <Grid container>
                  <Grid item container justifyContent="space-between" sx={{ marginBottom: '4px' }}>
                    <CardLabel variant="cardLabel">Department:</CardLabel>
                    <Typography variant="cardValue" sx={{ color: '#0F446B', fontWeight: 400 }}>
                      {consignment?.pickupDetails?.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item container justifyContent="space-between" sx={{ marginBottom: '4px' }}>
                    <CardLabel variant="cardLabel">Customer Email:</CardLabel>
                    <Typography variant="cardValue" sx={{ color: '#0F446B', fontWeight: 400 }}>
                      {customerDetails?.email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginBottom: '6px' }}>
                  <Grid item container justifyContent="space-between" sx={{ marginBottom: '4px' }}>
                    <CardLabel variant="cardLabel">Phone No.</CardLabel>
                    <Typography variant="cardValue" sx={{ color: '#0F446B', fontWeight: 400 }}>
                      {customerDetails?.areaCode} {customerDetails?.phone}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: '6px' }}>
                <Grid item container justifyContent="space-between" sx={{ marginBottom: '4px' }}>
                  <CardLabel variant="cardLabel">Address:</CardLabel>
                  <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="cardValue" sx={{ color: '#0F446B', fontWeight: 400 }}>
                      {customerDetails?.address?.streetOne}
                      {customerDetails?.address?.streetTwo && ', ' + customerDetails?.address?.streetTwo}
                    </Typography>
                    <Typography variant="cardValue" sx={{ color: '#0F446B', fontWeight: 400 }}>
                      {!(
                        customerDetails?.address?.streetOne ||
                        customerDetails?.address?.streetTwo
                      ) && customerDetails.address.geofenceId}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
        </StyledSectionRight>
      </StyledFormSection> */}
    </StyledFormContainer1>
  );
};

ConsignmentsForm4.propTypes = {
  consignment: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default ConsignmentsForm4;
