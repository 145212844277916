import React, { useState } from 'react';
import { StyledInputControlContainer, StyledTextField } from '../../styledComponents';
import { Typography, InputAdornment, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';
import FormError from './FormError';

const FormPassword = ({
  label,
  value,
  onChange,
  error,
  errorMessage,
  required = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && ' *'}
      </Typography>
      <StyledTextField
        variant="outlined"
        placeholder="Enter password"
        required={required}
        value={value}
        onChange={onChange}
        type={showPassword ? 'text' : 'password'}
        error={!!error}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {error && (
        <FormError errorMessage={errorMessage} />
      )}
    </StyledInputControlContainer>
  );
};

FormPassword.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
};

export default FormPassword;